import React from 'react'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { Wrapper } from './style'

const Made = () => {
  const device = useWindowDimensions()

  return (
    <Wrapper device={device}>
      <p>Copyright ©2019 Seguros online. Todos los derechos reservados</p>
      <p>Powered by: T1 Insurance</p>
    </Wrapper>
  )
}

export default Made
