import React from 'react'

import soa from 'Assets/faq.jpg'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'

import Information from 'Components/Information'

import Faq from './Components/Faq'
import FAQS from './faqs'

const info = [
// eslint-disable-next-line
  'El Seguro Obligatorio de Automotores (SOA) es un seguro creado por la Ley No 18.412 que entró en vigencia el 19 de agosto de 2009 y está reglamentado por el Decreto E/2267.',
  'Es obligatorio para todos los vehículos automotores y remolcados.',
  'Las lesiones personales o muerte causada a un tercero en un accidente, por el vehículo asegurado.',
  'Límites del seguro: Cuenta con una cobertura máxima de 150.000 UI por vehículo asegurado y por accidente.',
  'Dicho límite aumentará a 200.000 UI desde el 20/08/10 al 20/08/11 y a 250.000 UI desde el 21/08/11 en adelante.',
  'Los planes del producto SOA no incluyen el servicio de Auxilio Mecánico ni cubren daños causados a la propiedad, vehículos, etc.'
]

const Soa = () => {
  const device = useWindowDimensions()

  return (
    <Page>
      {device !== BREAKPOINTS.MOBILE && (
        <HeroImage
          src={soa}
          color='primary'
          title='SOA'
        />
      )}

      <Information
        title='SOA'
        subtitle='¿Qué es el SOA?'
        info={info}
      />

      {FAQS.map((f, i) => (
        <Faq
          key={i}
          question={f.question}
          items={f.items}
        />
      ))}

      <Advice />
      <Associated />
      <Contact />

    </Page>
  )
}
export default Soa
