import React from 'react'

import Info from './Components/Info'
import Made from './Components/Made'
import MobileFooter from './Components/MobileFooter'
import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'
const Footer = () => {
  const device = useWindowDimensions()
  return (
    <>
      {
        device !== BREAKPOINTS.MOBILE && device !== BREAKPOINTS.TABLET && (<><Info /><Made /></>)
      }
      {
        (device === BREAKPOINTS.MOBILE || device === BREAKPOINTS.TABLET) && (<MobileFooter />)
      }
    </>
  )
}

export default Footer
