import styled, { css } from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'

export const TabOption = styled.div `
  background-color: ${ props => props.selected ? '#01826d' : 'grey'};
  color: white;
  font-size: 18px;
  padding: 3px;
  border-radius: 10px 10px 0 0;
  width: 145px;
  text-align: center;
  vertical-align: middle;
  margin-right: 1px;
  font-weight: ${ props => props.selected ? 'bold' : 'normal'};
`
export const Wrapper = styled.div `
  width: 100%;
  max-height: ${({ expanded }) => expanded ? 'none' : '0px'};

  overflow: hidden;
  transition: max-height 1s;
`

export const Sections = styled.div `
  width: 100%;
  height: 100%;

  padding: ${({ device }) => {
    if (device !== BREAKPOINTS.MOBILE) {
      return `
0 $ { spacing.eleven }
$ { spacing.five }
`
    } else {
      return `
0 $ { spacing.oneAndAHalf }
$ { spacing.five }
`
    }
  }};
`

const mobile = css`
  justify-content: flex-start;
  padding-left: 36px;
`
export const Selector = styled.div `
  height: ${spacing.three};
  width: 100%;
  display: flex;
  justify-content: center;
  ${ props => props.device !== BREAKPOINTS.MOBILE ? mobile : null};
`

export const Tab = styled.div `
  cursor: pointer;
  //border-bottom: solid 2px ${({ active }) => active ? colors.primary.regular : colors.primary.dark};
  margin-bottom: -2px;
  align-self: center;
  > p {
    margin: 0 ${spacing.four} 0 ${spacing.one};
    color: ${({ active }) => active ? colors.primary.regular : colors.primary.dark};
    font-size: 14pt;
  }
`