import styled, { css } from 'styled-components'
import { colors, spacing } from 'Global/theme'

import { getSize } from 'Utils/conversions'

const Line = css`
  height: 2px;
  width: ${spacing.nine};

  background-color: ${colors.grays[10]};
`

const Circle = css`
  width: ${({ width }) => getSize(width)};
  height: ${({ height }) => getSize(height)};

  border-radius: 50%;
`

const Text = css`
  width: ${({ width }) => getSize(width)};
  height: ${({ height }) => getSize(height)};
  border-radius: 2px;
`

export const Loading = styled.div`
  ${({ type }) => {
    switch (type) {
      case 'circle': return Circle
      case 'line': return Line
      default: return Text
    }
  }};

  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading;
  animation-timing-function: linear;
  background: #f6f7f8;

  background: linear-gradient(
    to right,
    #fafafa 10%,
    #eeeeee 50%,
    #fafafa 90%
  );

  background-size: 3000% 500%;

  position: relative;

  @keyframes loading {
    0% {
      background-position: -50%;
    }

    100% {
      background-position: 50%;
    }
  }
`
