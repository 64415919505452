import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { spacing, colors } from 'Global/theme'
export const Disclaimer = styled.div`
  width: 70%;
  max-width: 650px;
  margin: auto;
  font-size: 12pt;

  border-radius: 8px;
  border: solid thin #e6e6e6;
  box-shadow: 0 0 2px 0 #cccccc;
  padding: 0 20px 0 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .info-icon {
    font-size: 20pt;
    color: ${colors.primary.regular};
    margin-right: 20px;
  }

`;
export const Container = styled.div`
  width: ${({ device }) => {
  switch (device) {
    case BREAKPOINTS.MOBILE: return '100%'
    case BREAKPOINTS.TABLET: return '80%'
    default: return '50%'
  }
  }};

  margin-top: ${spacing.four};


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-bottom: ${spacing.five};
  }
`

export const Image = styled.img`
  width: 468px;
  height: auto;
`
