import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useWindowDimensions } from 'Components/WindowDimensions'

import IconDetails from './Components/IconDetails'
import Payment from './Components/Payment'

import { Wrapper, Sections, Selector, Tab, TabOption } from './style'

const Data = ({ benefits, expanded, payments, symbol }) => {
  const device = useWindowDimensions()

  const [active, setActive] = useState('one')

  const orderPayments = [...payments].sort((a, b) => Number(a.shareQuantity) < Number(b.shareQuantity))

  return (
    <Wrapper expanded={expanded}>
      <Sections device={device}>
        <Selector>
          <Tab
            active={active === 'one'}
            onClick={() => setActive('one')}
          >
            <TabOption selected={active === 'one'}>Beneficios</TabOption>
          </Tab>

          <Tab
            active={active === 'two'}
            onClick={() => setActive('two')}
          >
            <TabOption selected={active === 'two'}>Formas de pago</TabOption>
          </Tab>
        </Selector>

        {active === 'one' ? (
          <IconDetails benefits={benefits} />
        ) : (
          <Payment payments={orderPayments} symbol={symbol} />
        )}
      </Sections>

    </Wrapper>
  )
}

Data.propTypes = {
  expanded: PropTypes.bool,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      type: PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      }),
      isIncluded: PropTypes.bool
    })
  ),
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      shareAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      shareQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  symbol: PropTypes.string
}

export default Data
