import { FIELDS, CONTACT } from './types'

/**
 *
 * FIELDS ACTIONS
 */
export const changeField = payload => ({
  type: FIELDS.SAVE,
  payload
})

export const clearFields = () => ({
  type: FIELDS.CLEAR
})

export const FIELD_ACTIONS = {
  CHANGE: changeField,
  CLEAR: clearFields
}

/**
 *
 * CONTACT ACTIONS
 */
export const sendContact = payload => ({
  type: CONTACT.SEND,
  payload
})

export const sendContactSuccess = () => ({
  type: CONTACT.SUCCESS
})

export const sendContactError = payload => ({
  type: CONTACT.ERROR,
  payload
})

export const CONTACT_ACTIONS = {
  SEND: sendContact,
  SUCCESS: sendContactSuccess,
  ERROR: sendContactError
}
