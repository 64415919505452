import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useWindowDimensions } from 'Components/WindowDimensions'

import Title from 'Components/Title'
import Loading from 'Components/Loading'

import useRedux from './Redux'
import SELECT from './Redux/selectors'
import {
  GENERAL_ACTIONS,
  FLOW_ACTIONS,
  FIELD_ACTIONS
} from './Redux/actions'

import Ci from './ci'
import Info from './info'
import Address from './address'
import Check from './check'

import { STEPS } from './constants'
import { Wrapper, FormContainer, Overlay } from './style'
import ReCAPTCHA from 'react-google-recaptcha'
const UserRegistry = ({ providerId, done }) => {
  const device = useWindowDimensions()

  useRedux()
  const dispatch = useDispatch()
  const complete = useSelector(SELECT.COMPLETE)
  const user = useSelector(SELECT.USER)
  const showing = useSelector(SELECT.SHOWING)
  const loading = useSelector(SELECT.LOADING)
  const [robot, setRobot] = useState(null)
  useEffect(() => {
    dispatch(GENERAL_ACTIONS.SET_PROVIDERID(providerId))
    return () => dispatch(FIELD_ACTIONS.CLEAR())
  }, [])

  useEffect(() => {
    if (complete === true) {
      dispatch(FLOW_ACTIONS.CLEAR())
      done(user)
    }
  }, [complete])

  const next = () => {
    if (!loading) {
      dispatch(FLOW_ACTIONS.NEXT())
    }
  }
  const previous = () => {
    if (!loading) {
      dispatch(FLOW_ACTIONS.STEP(STEPS.INFO))
    }
  }

  const handleRecaptcha = result => {
    setRobot(result)
  }
  const captcha = <ReCAPTCHA
    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
    onChange={handleRecaptcha}
  />
  const renderStep = () => {
    switch (showing) {
      case STEPS.CI: return <Ci next={next} />
      case STEPS.INFO: return <Info robot={robot} captcha={captcha} next={next} />
      case STEPS.ADDRESS: return <Address next={next} />
      case STEPS.CHECK: return <Check next={next} previous={previous} />
      default: return null
    }
  }
  const title = showing === STEPS.CHECK ? 'Atención' : 'Titular de la póliza'

  return (
    <Wrapper device={device}>
      <Title title={title} />

      {loading && (
        <Overlay>
          <Loading />
        </Overlay>
      )}

      <FormContainer device={device}>
        {renderStep()}
      </FormContainer>

    </Wrapper>
  )
}

UserRegistry.propTypes = {
  providerId: PropTypes.number,
  done: PropTypes.func
}

export default UserRegistry
