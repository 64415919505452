import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { COVERAGE } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import SELECTOR from '../../Redux/selectors'

import Info from './Components/info'
import Coverage from './Components/coverage'
import Dropdown from './Components/CoverageDropdown'

import Tag from 'Components/Tag'

import { TagWrapper, Card, Line, TopContainer, ButtonContainer } from './style'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const InfoCard = ({ type, includes, changeType, handleEdit }) => {
  const device = useWindowDimensions()

  const highlights = useSelector(SELECTOR.HIGHLIGHTS)

  return (
    <Card device={device}>
      <TopContainer device={device}>
        <Coverage type={type} changeType={changeType} />
        <Line device={device} />

        <Info device={device} title='Incluye:' information={includes} />

        <Line device={device} />

        <Info device={device} title='Vehículo:' information={highlights} />
      </TopContainer>
      <ButtonContainer>
        <Dropdown onClick={changeType} margin='0 30px 0 30px' />

        <TagWrapper margin='0 30px 0 30px'>
          <Tag handleClick={handleEdit}>
            <p>Editar</p>
            <FontAwesomeIcon icon={faEdit} />
          </Tag>
        </TagWrapper>
      </ButtonContainer>
    </Card>
  )
}

InfoCard.propTypes = {
  type: PropTypes.oneOf(Object.values(COVERAGE)),
  includes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  changeType: PropTypes.func,
  handleEdit: PropTypes.func
}

export default InfoCard
