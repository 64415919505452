import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from 'Global/constants'

export const WindowDimensionCtx = createContext(null)

const getDevice = () => {
  const width = window.innerWidth
  return width <= BREAKPOINTS.MOBILE ? BREAKPOINTS.MOBILE
    : width < BREAKPOINTS.TABLET ? BREAKPOINTS.TABLET : BREAKPOINTS.DESKTOP
}

const WinodwDimensionsProvider = ({ children }) => {
  const [device, setDevice] = useState(getDevice())

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDevice())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }
  , [])

  return (
    <WindowDimensionCtx.Provider value={device}>
      {children}
    </WindowDimensionCtx.Provider>
  )
}

WinodwDimensionsProvider.propTypes = {
  children: PropTypes.any
}

export default WinodwDimensionsProvider

export const useWindowDimensions = () => {
  return useContext(WindowDimensionCtx)
}
