import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import image from 'Assets/quoteInsurance.jpg'

import { WAITING_MESSAGES } from 'Global/messages'

import useRedux from './Redux'
import { FORM_ACTIONS } from './Redux/actions'
import SELECTOR from './Redux/selectors'

import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'
import Form from 'Components/Form'
import Loading from 'Components/Loading'
import HeroImage from 'Components/HeroImage'

import { Container } from './style'

const RatesForm = () => {
  useRedux()
  const dispatch = useDispatch()

  const device = useWindowDimensions()

  const loading = useSelector(SELECTOR.LOADING)
  const form = useSelector(SELECTOR.FORM)
  const values = useSelector(SELECTOR.VALUES)
  const done = useSelector(SELECTOR.DONE)

  useEffect(() => {
    dispatch(FORM_ACTIONS.GET())
  }, [])

  const handleSubmit = values => {
    dispatch(FORM_ACTIONS.SAVE(values))
  }

  const getForm = () => {
    if (Object.keys(form).length === 0) {
      return
    }
    const text = ['Siguiente', 'Cotizar']

    return (
      <Form
        fromWhere='ratesForm'
        rawForms={form}
        oldValues={values}
        submit={handleSubmit}
        buttonBlock
        buttonText={text}
        recaptcha
      />
    )
  }

  return (
    <Page>
      {done && <Loading overlay message={WAITING_MESSAGES.RATES_FORM} />}

      <HeroImage src={image} />
      <p>Se recomienda tener la libreta de conducir a mano para extraer datos</p>

      <Container device={device}>
        {loading ? <Loading size='large' /> : getForm()}
      </Container>
    </Page>
  )
}

export default RatesForm
