import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { spacing, colors } from 'Global/theme'
import { weight } from 'Global/font'

export const Card = styled.div`
  width: 100%;
  height: auto;


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: ${colors.background.white};
  border-radius: 8px;
  box-shadow: 0 0 10px ${colors.grays[20]};

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `${spacing.one} ${spacing.one};`
      case BREAKPOINTS.TABLET: return `${spacing.three} ${spacing.four};`
      default: return `${spacing.four} ${spacing.four};`
    }
  }};

  > svg {
    color: ${colors.danger.regular};
  }

  p {
    margin: 0;
    text-align: center;
  }

  > p.title {
    color: ${colors.danger.regular};
    font-size: 20pt;
    ${weight.bold};
    margin-bottom: ${spacing.three};
  }

  > p.subtitle {
    font-size: 14pt;
    ${weight.semiBold};
    margin-bottom: ${spacing.one};
  }

  > p.text {
    font-size: 14pt;
  }
`
