import React from 'react'
import PropTypes from 'prop-types'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { Wrapper } from './style'

const Tag = ({ color, children, handleClick }) => {
  const device = useWindowDimensions()

  return (
    <Wrapper color={color} device={device} onClick={handleClick}>
      {children}
    </Wrapper>
  )
}

Tag.propTypes = {
  handleClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node
}

export default Tag
