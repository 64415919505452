import styled from 'styled-components'

import { spacing } from 'Global/theme'
import { weight } from 'Global/font'

export const Block = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin: ${spacing.one} 0;
`
export const BlockContainer = styled.div`
  margin-bottom: ${spacing.fiveAndAHalf};
`
export const Separation = styled.div`
  padding-right: 16px;
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.two};
`

export const Title = styled.h1`
  margin: 0 0 ${spacing.one};
`

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: ${spacing.half} ${spacing.one};

  svg {
    margin-right: ${spacing.two};
  }

  p {
    margin: 0;
    ${weight.bold};
  }
`

export const Info = styled.p`
  margin: 0;
`

export const Icon = styled.div`
`

export const Description = styled.div`
`