import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from 'Global/constants'

import Button from 'Components/Button'

import IconList from './Components/IconList'

import { Sector, Image, Block, Line, Spacing } from './style'

const DCard = ({ image, name, deductible, preview, fee, metadata, handleContract, device }) => (
  <>
    <Sector device={device}>
      <Image src={image} />
    </Sector>

    <Line />

    <Sector justify='start' align='start' device={device}>
      <Block mb className='text-align-left'>
        <p className='name text-align-left'>{name}</p>
      </Block>

      <Block mb>
        <p className='h3 align-start'>Deducible</p>
        <p className='money align-start'>{deductible}</p>
      </Block>

      <Block mb>
        <p className='h3'>Beneficios</p>
      </Block>

      {metadata.length ? <IconList device={device} benefits={metadata} /> : null}
      <Spacing />
    </Sector>

    <Line />

    <Sector justify='between' device={device}>
      <Block ph spaced cover>
        <p className='h1'>Precio contado</p>
        <p className='big-money'>{fee}</p>
        <p className='h2'>seguro por año</p>
      </Block>

      {!preview && (
        <Button
          size='large'
          color='primary'
          onClick={handleContract}
        >
          Contratar
        </Button>
      )}
    </Sector>
  </>
)

DCard.propTypes = {
  fee: PropTypes.string,
  name: PropTypes.string,
  preview: PropTypes.bool,
  image: PropTypes.string,
  metadata: PropTypes.array,
  deductible: PropTypes.string,
  handleContract: PropTypes.func,
  device: PropTypes.oneOf(Object.values(BREAKPOINTS))
}

export default DCard
