import React from 'react'

import Top from './Components/Top'
import Info from './Components/Info'
const Header = () => (
  <>
    <Info />
    <Top />
  </>
)

export default Header
