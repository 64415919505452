import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Container, ButtonContainer } from './style'
import Button from 'Components/Button'
import { useWindowDimensions } from 'Components/WindowDimensions'

const Check = ({ next, previous }) => {
  const device = useWindowDimensions()
  const dispatch = useDispatch()
  const handleNext = () => {
    next()
  }
  const handlePrevious = () => {
    previous()
  }
  return (
    <Container>
      <p>Este documento de identidad ya se encuentra en la base de datos.</p>
      <p><b>¿Desea volver a ingresar sus datos?</b></p>
      <p>De lo contrario la póliza será emitida con la información con la que ya contamos.</p>
      <ButtonContainer device={device}>
        <Button
          size='large'
          onClick={handleNext}
          margin='0px 0px 10px 0px'
        >
          No ingresar nuevos datos
        </Button>
        <Button
          size='large'
          onClick={handlePrevious}
        >
          Ingresar nuevos datos
        </Button>
      </ButtonContainer>

    </Container>
  )
}

Check.propTypes = {
  next: PropTypes.func
}

export default Check
