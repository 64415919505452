export const key = 'policyForm'

export const FORM = {
  LOAD: 'POLICY_FORM/FORM/LOAD',
  LOAD_SUCCESS: 'POLICY_FORM/FORM/LOAD_SUCCESS',
  LOAD_ERROR: 'POLICY_FORM/FORM/LOAD_ERROR',
  SUBMIT: 'POLICY_FORM/FORM/SUBMIT',
  TO_START: 'POLICY_FORM/FORM/TO_START'
}

export const RATE = {
  GET: 'POLICY_FORM/RATE/GET',
  SUCCESS: 'POLICY_FORM/RATE/SUCCESS',
  ERROR: 'POLICY_FORM/RATE/ERROR'
}

export default key
