import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Wrapper, StyledLabel, StyledTextArea } from '../style'

const TextArea = ({ name, value, label, required, disabled, onChange, formValid }) => {
  const [onTop, setOnTop] = useState(!!value)

  const handleChange = ({ target }) => onChange(target)

  const handleFocus = () => {
    setOnTop(true)
  }

  const handleBlur = () => {
    setOnTop(!!value)
  }

  return (
    <Wrapper type='textarea'>
      <StyledLabel
        valid={formValid}
        isOnTop={onTop}
        htmlFor={name}
        disabled={disabled}
      >
        {label}
        {required && <span> *</span>}
      </StyledLabel>

      <StyledTextArea
        valid={formValid}
        name={name}
        value={value}
        required={required}
        onChange={handleChange}

        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Wrapper>
  )
}

TextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  formValid: PropTypes.bool,
  onChange: PropTypes.func
}

TextArea.defaultProps = {
  name: 'message',
  label: 'Mensaje'
}

export default TextArea
