import React from 'react'
import PropTypes from 'prop-types'

import { Select } from './style'

const Dropdown = ({ title, options, onSelect }) => {
  const handleChange = ({ target }) => {
    onSelect(target.value)
  }

  return (
    <Select onChange={handleChange} defaultValue={title}>
      {title && <option disabled>{title}</option>}
      {options.map(({ value, label }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </Select>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func
}

export default Dropdown
