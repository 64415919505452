import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import logo from 'Assets/Seguros-online.png'
import { HOME } from 'Global/paths'

import Menu from './Components/Menu'

import { Container, Bar, Image, Hamburger } from './style'

const Header = () => {
  const history = useHistory()
  const [showing, setShowing] = useState(false)

  const goToHome = () => history.push(HOME)

  const openMenu = () => setShowing(true)
  const closeMenu = () => setShowing(false)

  const menuGoHome = () => {
    goToHome()
    closeMenu()
  }

  return (
    <>
      <Container showing={showing}>
        <Bar menu>
          <Image src={logo} onClick={menuGoHome} />

          <Hamburger onClick={closeMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </Hamburger>
        </Bar>

        <Menu handleClose={closeMenu} />
      </Container>

      <Bar>
        <Image src={logo} onClick={goToHome} />
        <Hamburger onClick={openMenu}>
          <FontAwesomeIcon icon={faBars} />
        </Hamburger>
      </Bar>
    </>
  )
}

export default Header
