import React, { useState, useEffect } from 'react'
import { Wrapper, LoaderContainer } from './style'
import { useDispatch, useSelector } from 'react-redux'

import useRedux from './Redux'
import { sendEmail, clearFields } from './Redux/actions'

import Form from './Form'
import Loading from 'Components/Loading'
import { selectSending, selectSent } from './Redux/selectors'
export const EmailSender = ({ quickRateId, onSuccess }) => {
  useRedux()
  const dispatch = useDispatch()
  const loading = useSelector(selectSending)
  const success = useSelector(selectSent)
  useEffect(() => {
    if (success) {
      onSuccess()
    }
  }, [success])
  const handleSubmit = (values) => {
    const o = { ...values }
    o.email_quickrate.push({
      id: 'quickRateId',
      value: quickRateId
    })
    dispatch(sendEmail(o))
  }
  useEffect(() => {
    return () => {
      dispatch(clearFields())
    }
  }, [])
  return (
    <Wrapper>
      <h4>Ingrese su correo electrónico para poder enviarle su cotización</h4>

      {!loading && <Form handleSubmit={handleSubmit} />}
      {loading && (
        <LoaderContainer>
          <Loading size='large' />
          <h3>Por favor espere</h3>
        </LoaderContainer>
      )}
    </Wrapper>
  )
}

export default EmailSender
