import React from 'react'

import LoadingGradient from 'Components/LoadingGradient'

import {
  Card,
  Left,
  Middle,
  Right
} from './style'

const Loading = () => (
  <Card>
    <Left>
      <LoadingGradient
        type='circle'
        width={150}
        height={150}
      />
    </Left>

    <Middle>
      <LoadingGradient
        width={160}
        height={17}
      />
      <LoadingGradient
        width={160}
        height={22}
      />

      <LoadingGradient
        width={160}
        height={17}
      />

      <LoadingGradient type='line' />

      <LoadingGradient
        width={160}
        height={22}
      />

    </Middle>

    <Right>
      <LoadingGradient
        width={160}
        height={32}
      />
      <LoadingGradient
        width={160}
        height={54}
      />

      <LoadingGradient
        width={140}
        height={27}
      />
      <LoadingGradient
        width={190}
        height={43}
      />
    </Right>
  </Card>
)

export default Loading
