import { put, takeLatest, select, call, delay } from 'redux-saga/effects'

import axios from 'Axios'

import { message } from 'Messages/factory'

import { DELAY, MAX_WAIT_TIME, POLICY_STATUS } from 'Global/constants'
import { ERROR_MESSAGES } from 'Global/messages'

import { selectQuery } from 'Routes/Redux/selectors'

import { CERTS, POLICY } from './types'
import { POLICY_ACTIONS, CERT_ACTIONS } from './actions'

const apiCalls = {
  getPolicy: id => axios.get(`/get_insurance_policy?policy_id=${id}`),
  getCerts: id => axios.get(`/get_policy_certificates?policy_id=${id}`)
}

export function * getPolicy () {
  try {
    const { policyId } = yield select(selectQuery)
    let done = false

    let referenceCode
    let providerNumber

    let stopWaiting = false
    setTimeout(() => {
      stopWaiting = true
    }, MAX_WAIT_TIME)

    do {
      if (stopWaiting) break

      const result = yield call(apiCalls.getPolicy, policyId)

      const { status, policy } = result

      if (!referenceCode && policy.referenceCode) {
        referenceCode = policy.referenceCode
        yield put(POLICY_ACTIONS.CODE(referenceCode))
      }

      if (!providerNumber && policy.providerNumber) {
        providerNumber = policy.providerNumber
        yield put(POLICY_ACTIONS.REFERENCE(providerNumber))
      }

      done = status === POLICY_STATUS.VALID && policy.completeCertificates

      if (!done) {
        yield delay(DELAY)
      }
    } while (!done)

    if (stopWaiting) {
      message.error(ERROR_MESSAGES.TIMEOUT)
      yield put(POLICY_ACTIONS.ERROR(ERROR_MESSAGES.CERT))
    } else {
      yield put(CERT_ACTIONS.GET())
    }
  } catch (err) {
    yield put(POLICY_ACTIONS.ERROR(err))
  }
}

export function * getCerts () {
  try {
    const { policyId } = yield select(selectQuery)
    const result = yield call(apiCalls.getCerts, policyId)

    yield put(CERT_ACTIONS.SUCCESS(result))
  } catch (err) {
    yield put(CERT_ACTIONS.ERROR(err))
  }
}

export default function * saga () {
  yield takeLatest(POLICY.GET, getPolicy)
  yield takeLatest(CERTS.GET, getCerts)
}
