import React from 'react'

import image from 'Assets/glossary.svg'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'

import Information from 'Components/Information'

import Info from './info'

import {
  Block,
  Term,
  Text
} from './style'

const { info, terms } = Info

const Glossary = () => {
  const device = useWindowDimensions()

  return (
    <Page>
      {device !== BREAKPOINTS.MOBILE && (
        <HeroImage
          src={image}
          color='primary'
          title='GLOSARIO'
        />
      )}

      <Information
        title='GLOSARIO DE SEGUROS'
        subtitle='Términos y conceptos básicos'
        info={info}
      />

      {terms.map(({ term, definition }, i) => (
        <Block key={i}>
          <Term>{term}</Term>
          <Text>{definition}</Text>
        </Block>
      ))}

      <Advice />
      <Associated />
      <Contact />

    </Page>
  )
}

export default Glossary
