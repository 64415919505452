/* eslint-disable max-len */
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import aboutUs from 'Assets/about-us-1.jpg'
import aboutUs2 from 'Assets/about-us-2.jpg'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'

import Information from 'Components/Information'

import { Container, Block, Check } from './styles'

const AboutUs = () => (
  <Page>

    <Information
      title='Nuestra experiencia garantiza tu seguridad'
      info='Conoce un poco más sobre nosotros...'
    />

    <Container>
      <Block>
        <h2>Más de 60 años brindando seguros en Uruguay.</h2>
        <p>Trabajamos con las mejores compañías, abarcando seguros de Vehículos, Hogares, Salud y Vida.</p>
      </Block>

      <Block>
        <h2>Somos la primera empresa que incursionó en la cotización y emisión de pólizas de seguros online.</h2>
        <p>Y siendo el medio digital el más elegido en estos tiempos, seguimos avanzando agregando más y mejores opciones, para facilitarles la gestión de su seguro sin necesidad de traslados.</p>
        <p>Comenzamos con los Seguros vehiculares esperando seguir adelante con nuevos productos y servicios para ofrecerles más practicidad en la gestión de pólizas en Uruguay.</p>
      </Block>

      <Block>
        <h2>Lo virtual no quita lo personal,</h2>
        <p>porque también te seguimos asesorando en forma personalizada, mediante nuestras líneas de teléfono y whatsapp, con el equipo de profesionales y asesores, estamos para ayudarte en el proceso siempre que lo necesites.</p>
      </Block>
    </Container>

    <HeroImage src={aboutUs2} />

    <Information
      title='¿Porqué nuestro sistema es más rápido y seguro?'
    />

    <Container>
      <Block>
        <Check>
          <FontAwesomeIcon icon={faCheck} />
          <h2>Sin necesidad de Registrate,</h2>
        </Check>
        <p>sólo completa un breve formulario y resuelve el seguro de tu auto en pocos minutos, para tener tranquilidad por mucho tiempo.</p>
      </Block>

      <Block>
        <Check>
          <FontAwesomeIcon icon={faCheck} />
          <h2>Obtendrás tu cotización en 1 minuto,</h2>
        </Check>
        <p>sin necesidad de contactos o envíos por otros medios.</p>
      </Block>

      <Block>
        <Check>
          <FontAwesomeIcon icon={faCheck} />
          <h2>Si necesitas más tiempo para decidir,</h2>
        </Check>
        <p>te enviamos una copia por mail y Whatsapp para que tengas todos los datos a tu alcance para analizarlo mejor.</p>
      </Block>

      <Block>
        <Check>
          <FontAwesomeIcon icon={faCheck} />
          <h2>Variedad:</h2>
        </Check>
        <p>Trabajamos con diferentes empresas aseguradoras para que siempre encuentrés la opción que mejor se adapta a tus necesidades y tu economía.</p>
      </Block>

      <Block>
        <Check>
          <FontAwesomeIcon icon={faCheck} />
          <h2>Seguridad:</h2>
        </Check>
        <p>Nuestro sitio y sistema de protección de datos, asegura la confidencialidad y reserva de tus datos.</p>
      </Block>
    </Container>

    <Advice />
    <Associated />
    <Contact />

  </Page>
)

export default AboutUs
