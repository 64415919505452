import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Dot } from './style'

const StepIndicator = ({ steps, step, onClick }) => {
  const getDots = () => {
    const dots = []
    for (let i = 0; i < steps; i++) {
      dots.push(
        <Dot
          key={i}
          selected={i === step}
          onClick={onClick ? () => onClick(i) : undefined}
        />
      )
    }
    return dots
  }

  return (
    <Wrapper>
      {getDots()}
    </Wrapper>
  )
}

StepIndicator.propTypes = {
  steps: PropTypes.number,
  step: PropTypes.number,
  onClick: PropTypes.func
}

export default StepIndicator
