import produce from 'immer'

import { CANCEL_POLICY, FORM_FIELD } from './types'

export const initialState = {
  productType: '',
  policyId: '',
  documentId: '',
  tel: '',
  email: '',
  error: '',
  sending: false
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // FIELDS
      case FORM_FIELD.CLEAR:
        draft.productType = ''
        draft.documentId = ''
        draft.tel = ''
        draft.email = ''
        draft.error = ''
        draft.sending = false
        break

      case FORM_FIELD.CHANGE:
        draft[payload.name] = payload.value
        break

      // CANCEL
      case CANCEL_POLICY.SEND:
        draft.sending = true
        draft.error = false
        break

      case CANCEL_POLICY.SUCCESS:
        return initialState

      case CANCEL_POLICY.ERROR:
        draft.sending = false
        draft.error = payload
        break

      default:
        break
    }
  })

export default reducer
