import styled from 'styled-components'
import { page } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'

export const Page = styled.div`
    max-width: ${page.width};
    margin: 0 auto;
`
export const VerticalSeparation = styled.div`
    width: 100%;
    height: ${props => props.device === BREAKPOINTS.DESKTOP ? '0' : '40px'};
`
