import React from 'react'

import image from 'Assets/faq.jpg'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'
import Information from 'Components/Information'

import data from './info'

import { Block, Question, Answer } from './style'

const Faq = () => {
  const device = useWindowDimensions()

  return (
    <Page>
      <Information
        title='PREGUNTAS FRECUENTES'
        subtitle='FAQ'
      />
      {data.map(({ question, answer }, i) => (
        <Block key={i}>
          <Question>{question}</Question>
          <Answer>{answer}</Answer>
        </Block>
      ))}
      <Advice />
      <Associated />
      <Contact />
    </Page>
  )
}

export default Faq
