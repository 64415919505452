import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import TagManager from 'react-gtm-module'

import WindowDimensionsProvider from 'Components/WindowDimensions'

import configureStore from 'Redux/configureStore'
import history from 'Redux/Utils/history'

import App from './Routes/App'
import './style.css'

// Google tag manager
const tagManagerArgs = {
  gtmId: 'GTM-TN87342'
}

TagManager.initialize(tagManagerArgs)

// Create redux store
const initialState = {}
export const store = configureStore(initialState, history)

ReactDOM.render(
  <WindowDimensionsProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </WindowDimensionsProvider>,
  document.getElementById('root')
)
