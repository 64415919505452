import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: ${spacing.fourAndAHalf} 0;
`

export const TitleContainer = styled.div`
  margin-bottom: ${spacing.fourAndAHalf};
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.primary.dark};
  ${family.barlow};

  margin: -${spacing.sevenAndAHalf} 0 ${spacing.three} 0;

  > p {
    margin: 0;
  }
  > p.top {
    font-size: 14pt;
  }
  > p.bot {

    font-size: 10pt;
  }
`
