import React from 'react'

import {
  FAQ,
  HOME,
  CONTACT,
  ABOUT_US,
  INSURANCES
} from 'Global/paths'
import Social from '../Social'
import { useLocation } from 'react-router-dom'

import { Nav, StyledLink } from './style'

const Navigation = () => {
  const location = useLocation()

  return (
    <Nav>
      <StyledLink to={HOME} selected={location.pathname === HOME}>
        INICIO
      </StyledLink>
      <StyledLink to={INSURANCES} selected={location.pathname === INSURANCES}>
        SEGUROS
      </StyledLink>
      <StyledLink to={ABOUT_US} selected={location.pathname === ABOUT_US}>
        NOSOTROS
      </StyledLink>
      <StyledLink to={FAQ} selected={location.pathname === FAQ}>
        FAQ
      </StyledLink>
      <StyledLink to={CONTACT} selected={location.pathname === CONTACT}>
        CONTACTO
      </StyledLink>
      <StyledLink to='' notSelectable justifyContent='flex-end' marginRight='13px'>
        <Social />
      </StyledLink>
    </Nav>
  )
}

export default Navigation
