import styled from "styled-components";

import { BREAKPOINTS } from "Global/constants";

import { size } from "Global/font";
import { spacing, colors } from "Global/theme";
export const Disclaimer = styled.div`
  width: 70%;
  max-width: 650px;
  margin: auto;
  font-size: 12pt;

  border-radius: 8px;
  border: solid thin #e6e6e6;
  box-shadow: 0 0 2px 0 #cccccc;
  padding: 0 20px 0 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .info-icon {
    font-size: 20pt;
    color: ${colors.primary.regular};
    margin-right: 20px;
  }

`;
export const EmailText = styled.h3`
  cursor: pointer;
  text-align: center;
  align-self: center;
`;
export const ProvidersWrapper = styled.div`
  width: 100%;
  max-width: 928px;

  display: flex;
  flex-direction: ${(props) =>
    props.device === BREAKPOINTS.DESKTOP ? "row" : "column"};
  justify-content: space-evenly;
  align-items: center;

  flex-wrap: wrap;

  margin-top: ${spacing.four};

  > * {
    margin: 0 ${spacing.two} 0;
  }
`;

export const Menu = styled.div`
  width: 100%;
  max-width: 928px;

  margin-top: ${({ device }) =>
    device === BREAKPOINTS.DESKTOP ? spacing.three : spacing.seven};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > .order {
    span {
      margin-right: ${spacing.two};
    }
  }

  > button {
    ${({ device }) => (device === BREAKPOINTS.MOBILE ? "min-width: auto" : "")};
  }
`;

export const CreationDate = styled.p`
  font-size: ${size.twelve};
  margin: 0;
`;
