import React from 'react'

import soa from 'Assets/soa.svg'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'
import Information from 'Components/Information'

import Card from './Components/Card'

import items from './items'

import { Container, Group } from './style'

const Hire = () => {
  const device = useWindowDimensions()

  const evenItems = items.filter((_, i) => i % 2 === 0)
  const oddItems = items.filter((_, i) => i % 2 !== 0)

  const createItems = arr => arr.map(({ icon, title, description }, i) => (
    <Card key={i} icon={icon} title={title} description={description} />
  ))

  return (
    <Page>
      {device !== BREAKPOINTS.MOBILE && (
        <HeroImage
          src={soa}
          color='primary'
          title='CONTRATAR AQUÍ'
        />
      )}

      <Information
        title='10 RAZONES PARA'
        subtitle='Contratar aquí'
      />

      <Container device={device}>
        <Group device={device}>
          {createItems(evenItems)}
        </Group>

        <Group device={device}>
          {createItems(oddItems)}
        </Group>
      </Container>

      <Advice />
      <Associated />
      <Contact />
    </Page>
  )
}

export default Hire
