import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const ItemContainer = styled.div`
  font-size: 30px;
  color: white;
  display: flex;
  margin-bottom: 4px;
`
export const Item = styled.div`
  margin: ${({ margin }) => margin || 0};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {

    color: grey;
  }
`
export const Text = styled.div`
  font-size: 15px;
  color: white;
  font-weight: 200;
`
