import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { spacing } from 'Global/theme'

export const Body = styled.div`
  width: 100%;
  max-width: 1000px;
  height: auto;

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `${spacing.three} 0`
      case BREAKPOINTS.TABLET: return `${spacing.four} ${spacing.one}`
      default: return `${spacing.fourAndAHalf} ${spacing.two}`
    }
  }};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  > .button {
    margin-top: ${spacing.two};
  }
`

export const Column = styled.div`
  width: 100%;
  max-width: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '400px' : '100%'};
  display: flex;
  flex-direction: column;
`

export const Inputs = styled.div`
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
