import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: ${({ device }) => {
    switch (device) {
        case BREAKPOINTS.MOBILE: return `${spacing.seven} 0 0`
        case BREAKPOINTS.TABLET: return `${spacing.three}`
        default: return `${spacing.seven} 184px`
      }
  }};

  > :first-child {
    margin-bottom: ${({ info }) => {
      return info ? spacing.five : '0'
  }};
  }

  > p {
    ${family.barlow};
    color: ${colors.primary.dark};
    font-size: 14pt;
    text-align: justify;
  }

`
