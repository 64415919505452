import React from 'react'
import PropTypes from 'prop-types'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { Card } from './style'

export const NoRates = ({ img, name }) => {
  const device = useWindowDimensions()

  return (
    <Card device={device}>
      <p>No contamos con cotizaciones de  <strong>{name}</strong>  para esta cobertura.</p>
      <img src={img} />
    </Card>
  )
}

NoRates.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string
}

export default NoRates
