import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ItemContainer, Container, Item, Text } from './styles'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
export const Social = () => {
  const onWhatsappClick = () => {
    window.open('https://api.whatsapp.com/send?phone=59899072440')
  }
  const onMailClick = () => {
    window.open('mailto:info@segurosonline.com.uy')
  }
  return (
    <Container>
      <ItemContainer>
        <Item margin='0 7px 0 0' onClick={onWhatsappClick}><FontAwesomeIcon icon={faWhatsapp} /></Item>
        <Item onClick={onMailClick}><FontAwesomeIcon icon={faEnvelopeOpen} /></Item>
      </ItemContainer>
      <Text>CONSULTAS?</Text>
    </Container>
  )
}

export default Social
