import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { insuranceCarddPT } from 'Global/propTypes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faStar } from '@fortawesome/free-solid-svg-icons'

import Tag from 'Components/Tag'

import Info from './Components/Info'
import Data from './Components/Data'

import { Wrapper, Card, Star } from './style'

const Insurance = ({ data, handleContract, preview }) => {
  const [expanded, setExpanded] = useState(false)
  const handleClick = () => setExpanded(!expanded)
  const onContract = () => {
    const { rateId } = data
    const providerId = data.provider.id
    handleContract({ providerId, rateId })
  }

  const {
    stared,
    payments,
    metadata,
    currencyType: { symbol }
  } = data
  return (
    <Wrapper>
      {stared && (
        <Star>
          <FontAwesomeIcon icon={faStar} />
        </Star>
      )}

      <Card stared={stared}>
        <Info data={data} handleContract={onContract} preview={preview} />
        <Data expanded={expanded} benefits={metadata} payments={payments} symbol={symbol} />
      </Card>

      <Tag handleClick={handleClick}>
        <p>Beneficios y Formas de Pago</p>
        <FontAwesomeIcon icon={expanded ? faSortUp : faSortDown} />
      </Tag>
    </Wrapper>
  )
}

Insurance.propTypes = {
  data: insuranceCarddPT,
  handleContract: PropTypes.func,
  preview: PropTypes.bool
}

export default Insurance
