import PropTypes from 'prop-types'

import { DYNAMIC_FIELD_TYPES } from 'Global/constants'

export const productsPT = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.number,
  name: PropTypes.string
})

// TODO: CAMBIAR LOS PROPTYPES DE INSURANCE CARD PT
export const insuranceCarddPT = PropTypes.shape({
  carrier: PropTypes.oneOf(['bse', 'sura', 'porto', 'hdi']),
  deductible: PropTypes.string,
  thirds: PropTypes.string,
  benefits: PropTypes.arrayOf(
    PropTypes.oneOf(['fire', 'oil', 'crash', 'death'])
  ),
  fee: PropTypes.string,
  stared: PropTypes.bool
})

export const formFieldPT = PropTypes.shape({
  id: PropTypes.number,
  fieldType: PropTypes.oneOf(Object.values(DYNAMIC_FIELD_TYPES)),
  formId: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any
    })
  ),
  label: PropTypes.string,
  fieldName: PropTypes.string,
  order: PropTypes.number,
  required: PropTypes.bool,
  optionsUrl: PropTypes.string,
  dependsOn: PropTypes.arrayOf(PropTypes.number),
  parent: PropTypes.number,
  value: PropTypes.any,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
})

export const formOrderPT = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
  fieldType: PropTypes.oneOf(Object.values(DYNAMIC_FIELD_TYPES)),
  children: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      fieldType: PropTypes.oneOf(Object.values(DYNAMIC_FIELD_TYPES)),
      order: PropTypes.number,
      parent: PropTypes.number
    })
  )
})
