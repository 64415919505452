/* eslint-disable max-len */
import styled from 'styled-components'

import { spacing, colors } from 'Global/theme'
import { family } from 'Global/font'

export const Select = styled.select`
  padding: ${spacing.one} ${spacing.oneAndAHalf};

  background-color: ${colors.background.white};
  border: 2px solid ${colors.grays[50]};
  border-radius: 14px;

  color: ${colors.primary.dark};
  font-family: ${family.openSans};

  cursor: pointer;

  appearance: none;

  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><polygon points="0,0 100,0 50,50" style="fill:%23005442;"/></svg>');
  background-position: right ${spacing.oneAndAHalf} top 50%;
  background-repeat: no-repeat;
  background-size: ${spacing.oneAndAHalf};

  padding-right: ${spacing.fourAndAHalf};

  &:focus, &:hover {
    outline: none;
    border: 2px solid ${colors.primary.dark};
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><polygon points="0,50 100,50 50,0" style="fill:%23005442;"/></svg>');
  }

`
