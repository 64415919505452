import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { useClickOutside } from './hooks'
import { Overlay, Wrapper, Area, Close } from './style'

const Modal = ({ isShowing, hide, children }) => {
  const device = useWindowDimensions()

  const ref = useRef()
  useClickOutside(ref, () => hide())

  if (!isShowing) return null

  return (
    ReactDOM.createPortal(
      <>
        <Overlay
          tag='aside'
          role='dialog'
          tabIndex='-1'
          aria-modal='true'
        >
          <Wrapper>
            <Area ref={ref} device={device}>
              <Close icon={faTimes} size='2x' onClick={hide} />
              {children}
            </Area>
          </Wrapper>
        </Overlay>
      </>, document.body
    )
  )
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired
}

export default Modal
