import produce from 'immer'

import { FORM, PRODUCT } from './types'

export const initialState = {
  loading: false,
  error: '',
  forms: undefined,
  values: undefined,
  formId: -1,
  productId: 0,
  done: false
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // DYNAMIC FORM
      case FORM.LOAD:
        draft.loading = true
        draft.error = ''
        draft.forms = {}
        draft.done = false
        break

      case FORM.LOAD_SUCCESS:
        draft.loading = false
        draft.error = ''
        draft.forms = payload.forms
        draft.formId = payload.formId
        break

      case FORM.LOAD_ERROR:
        draft.loading = false
        draft.error = payload
        draft.forms = {}
        break

      case FORM.SAVE:
        draft.done = true
        break

      case FORM.SAVE_SUCCESS:
        draft.values = payload
        break

      case FORM.SAVE_ERROR:
        draft.values = {}
        draft.done = false
        break

      case PRODUCT.SET:
        draft.productId = payload
        break
    }
  })

export default reducer
