import styled from 'styled-components'
import { colors } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'
export const Icon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  margin-left: 40px;
  color: ${colors.primary.light};
  padding: 0;
  margin: 0;
  margin-top: 20px;

`
export const Text = styled.p`
  margin: 0px;
  text-align: left;
`
export const Container = styled.div`
  width: 100%;
  height: ${props => props.device === BREAKPOINTS.MOBILE ? '500px' : '250px'};
  font-size: 20px;
  display: flex;
  flex-direction: ${props => props.device === BREAKPOINTS.MOBILE ? 'column' : 'row'};
  border-radius: 8px;
  padding-bottom: 10px;
  border: solid thin #005442;
  align-content: center;
  align-items: center;
  justify-content: center;

`
export const Col = styled.div`
  width: ${props => props.width};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 12pt;
`
export const InputContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-right: ${props => props.device === BREAKPOINTS.MOBILE ? '0' : '0'};
  div {
    align-self: center;
    align-self: flex-start;
  }
  input {
    border: solid thin #005442;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    max-width: ${props => props.device === BREAKPOINTS.MOBILE ? '100%' : '100%'};

  }
  img {
    margin-right: 30px;
  }
  flex-direction: ${props => props.device === BREAKPOINTS.MOBILE ? 'column' : 'row'};
`
