import styled from 'styled-components'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const StyledSelect = styled(Select)`
  width: 100%;
  height: ${spacing.four};

  opacity: ${({ isDisabled }) => isDisabled ? '0.5' : '1'} !important;
`

export const StyledAsyncSelect = styled(AsyncSelect)`
  width: 100%;
  height: ${spacing.four};

  opacity: ${({ isDisabled }) => isDisabled ? '0.5' : '1'} !important;
`

export const Placeholder = styled(components.Placeholder)`
  display: none;
`

export const ValueContainer = styled(components.ValueContainer)`
  background-color: none !important;

   :disabled {
    background-color: blue !important;
  }
`

export const Option = styled(components.Option)`
  ${family.openSans};
  font-size: 12pt;

  ${({ isSelected }) => {
    if (isSelected) return weight.regular
    else return weight.light
  }}

  color: ${({ isSelected }) => {
    if (isSelected) return colors.secondary.regular
    else return colors.primary.dark
  }} !important;

  background-color: ${({ isSelected }) => {
    if (isSelected) return colors.grays[30]
    else return colors.background.white
  }} !important;

  :hover {
    background-color: ${({ isSelected }) => {
      if (isSelected) return colors.grays[30]
      else return colors.background.select
    }} !important;
  }
`

export const Menu = styled(components.Menu)`
  z-index: 3 !important;
`

export const SingleValue = styled(components.SingleValue)`
  color: ${colors.primary.dark} !important;
  ${family.openSans};
  ${weight.bold};
  font-size: 12pt;
`

export const Control = styled(components.Control)`
  box-shadow: none !important;

  border-radius: 8px !important;

  cursor: pointer !important;

  border: none !important;
  border-radius: 0px !important;

  border-bottom: solid thin ${({ valid }) => valid ? colors.grays[50] : colors.danger.regular} !important;

  &:hover, &:focus {
    outline: none;
  }

  > :disabled {
    opacity: 0.5 !important;
  }
`

export const Indicator = styled(components.DropdownIndicator)`
  color: ${colors.primary.dark} !important;
`

export const Container = styled.div`
  width: 100%;
  background-color: purple;
`
