import { put, takeLatest, select, call, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import axios from 'Axios'

import { ERROR_MESSAGES } from 'Global/messages'
import { HOME, POLICY_FORM } from 'Global/paths'
import { RATE_APP_JOBS_STATUS, DELAY, MAX_WAIT_TIME } from 'Global/constants'

import { selectQuery } from 'Routes/Redux/selectors'
import GLOBAL_SELECTOR from 'Redux/Global/selectors'

import { message } from 'Messages/factory'

import {
  RATES,
  CATEGORY,
  PROVIDER_RATE
} from './types'
import {
  RATE_ACTIONS,
  CATEGORY_ACTIONS,
  PROVIDER_RATE_ACTIONS
} from './actions'
import SELECTORS from './selectors'

const apiCalls = {
  getRates: id => axios.get(`/rate/?quick_rate_id=${id}`),
  getRateAppJob: id => axios.get(`/rate_app_job/?rate_id=${id}`),
  takeOnAutomaticRate: id => axios.post(`/take_on_automatic_rate?automatic_rate_id=${id}`),
  createApplication: data => axios.post('/create_application/', data),
  getCoverageCategory: id => axios.get(`/coverage_category?quick_rate_job_header_id=${id}`),
  waitForTakeOn: id => axios.get(`/get_insurance_policy?application_id=${id}`)
}

export function * getCoverageCategory () {
  try {
    const id = yield select(SELECTORS.QUICK_RATE_ID)
    const category = yield call(apiCalls.getCoverageCategory, id)
    yield put(CATEGORY_ACTIONS.SUCCESS(category))
  } catch (err) {
    yield put(CATEGORY_ACTIONS.ERROR(err))
  }
}

export function * getRates () {
  try {
    const { quickRateId } = yield select(selectQuery)

    const providers = yield select(GLOBAL_SELECTOR.PROVIDERS)
    const loading = providers.map(({ id, company }) => ({
      id: id,
      name: company.name,
      img: company.publicLogoUrl
    }))

    const res = yield call(apiCalls.getRates, quickRateId)

    const pendingProviders = res.preRateApplicationJobs.filter(({ automaticallyTranslated }) => !!automaticallyTranslated)
    const loadingToKeep = pendingProviders.map(el => el.provider)

    const highlights = []
    const { form, values, creationDate } = res
    Object.keys(form).forEach(key => {
      const section = form[key]

      if (!Array.isArray(section)) return null

      const m = []
      section.forEach(el => m.push(...getHighlights(el)))

      const sect = m.map(id => {
        const val = values[key].find(({ fieldId }) => fieldId === id)
        if (!val) return null
        return val.value.description
      })

      highlights.push(...sect)
    })

    const rates = {
      highlights,
      creationDate,
      pendingProviders,
      quickRateId: res.id,
      loading: loading.filter(({ id }) => loadingToKeep.includes(id))
    }

    yield put(RATE_ACTIONS.SUCCESS(rates))
    yield put(PROVIDER_RATE_ACTIONS.LOAD())
    yield put(CATEGORY_ACTIONS.LOAD())
  } catch (err) {
    yield put(RATE_ACTIONS.ERROR(err))
  }
}

const getHighlights = field => {
  const { childs, highlightedField, id } = field
  const ret = []

  if (highlightedField) ret.push(id)

  childs.forEach(child => {
    ret.push(...getHighlights(child))
  })

  return ret
}

export function * getProviderRates ({ payload }) {
  try {
    let pendingProviders = yield select(SELECTORS.PENDING_PROVIDERS)

    while (pendingProviders.length > 0) {
      const canGetRates = yield select(SELECTORS.CANT_GET_RATES)
      if (!canGetRates) return

      for (let i = 0; i < pendingProviders.length; i++) {
        const provider = pendingProviders[i]
        const { rateJob } = provider
        if (!rateJob) {
          yield put(PROVIDER_RATE_ACTIONS.NO_RATES({
            provider: provider.provider
          }))
          continue
        }

        const { rateJob: { status }, rates } = yield call(apiCalls.getRateAppJob, rateJob.id)
        if (status === RATE_APP_JOBS_STATUS.PENDING || status === RATE_APP_JOBS_STATUS.PROCESSED) {
          continue
        }

        if (status === RATE_APP_JOBS_STATUS.ERROR) {
          yield put(PROVIDER_RATE_ACTIONS.NO_RATES({
            provider: provider.provider
          }))
        }

        if (status === RATE_APP_JOBS_STATUS.COMPLETED) {
          rates.forEach(rate =>
            rate.payments.sort((a, b) => Number(a.shareQuantity) - Number(b.shareQuantity))
          )

          if (rates.length) {
            yield put(PROVIDER_RATE_ACTIONS.SUCCESS({
              index: i,
              quotes: rates,
              provider: provider.provider
            }))
          } else {
            yield put(PROVIDER_RATE_ACTIONS.NO_RATES({
              index: i,
              provider: provider.provider
            }))
          }
        }
      }

      pendingProviders = yield select(SELECTORS.PENDING_PROVIDERS)

      if (pendingProviders.length > 0) {
        yield delay(DELAY)
      }
    }
  } catch (err) {
    yield put(PROVIDER_RATE_ACTIONS.ERROR(err))
  }
}

export function * createRate ({ payload }) {
  const { insuranceClientId, rateId, providerId } = payload

  try {
    const quickRateId = yield select(SELECTORS.QUICK_RATE_ID)

    yield call(apiCalls.takeOnAutomaticRate, rateId)

    const created = yield call(apiCalls.createApplication, {
      quickRateId,
      insuranceClientId,
      takerId: insuranceClientId
    })

    const productId = created.product.id
    const applicationId = created.id
    yield put(RATE_ACTIONS.WAIT({ applicationId, productId, providerId }))
  } catch (err) {
    yield put(RATE_ACTIONS.ERROR(ERROR_MESSAGES.RATE))
  }
}

export function * waitForTakeOn ({ payload }) {
  const { applicationId, productId, providerId } = payload

  try {
    let stopWaiting = false
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        stopWaiting = true
      }, MAX_WAIT_TIME)
    }

    let hasTakeOn = false
    do {
      if (stopWaiting) break

      const result = yield call(apiCalls.waitForTakeOn, applicationId)
      hasTakeOn = result.application.hasTakeOn

      if (!hasTakeOn) {
        yield delay(DELAY)
      }
    } while (!hasTakeOn)

    if (stopWaiting) {
      message.error(ERROR_MESSAGES.TIMEOUT)
      yield put(RATE_ACTIONS.ERROR(ERROR_MESSAGES.RATE))
    } else {
      yield put(push({
        pathname: POLICY_FORM,
        search: `?productId=${productId}&providerId=${providerId}&applicationId=${applicationId}`
      }))
    }
  } catch (err) {
    yield put(push(HOME))
  }
}

export default function * saga () {
  yield takeLatest(RATES.LOAD, getRates)
  yield takeLatest(PROVIDER_RATE.LOAD, getProviderRates)
  yield takeLatest(RATES.CREATE, createRate)
  yield takeLatest(CATEGORY.LOAD, getCoverageCategory)
  yield takeLatest(RATES.WAIT, waitForTakeOn)
}
