import { all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'

import axios from 'Axios'

import { ERROR_MESSAGES } from 'Global/messages'
import { DYNAMIC_FIELD_TYPES } from 'Global/constants'

import { replaceParams } from 'Utils/conversions'

import SELECTOR from './selector'
import { FORM, FIELD } from './types'
import { FORM_ACTIONS, FIELD_ACTIONS } from './actions'

const apicCalls = {
  getOptions: ({ url, hasTranslation }) => axios.get(`/options?opt_url=${url}&has_translation=${hasTranslation}`),
  smsSend: ({ cellPhone }) => axios.post('/phone_verification_request', { cellphone: cellPhone }),
  verificationSend: ({ verificationId, verificationCode }) => axios.post('/phone_verification_submit', { verificationId, verificationCode })
}
export function * sendVerification ({ payload }) {
  try {
    const data = yield call(apicCalls.verificationSend, payload)
    return yield put(FIELD_ACTIONS.VERIFICATION_SUCCESS(data))
  } catch (err) {
    console.error(err)
    return yield put(FIELD_ACTIONS.VERIFICATION_FAIL(FIELD.SMS_FAIL))
  }
}
export function * sendSms ({ payload }) {
  try {
    const data = yield call(apicCalls.smsSend, payload)
    return yield put(FIELD_ACTIONS.SMS_SUCCESS(data))
  } catch (err) {
    console.error(err)
    return yield put(FIELD_ACTIONS.SMS_FAIL(FIELD.SMS_FAIL))
  }
}
/**
 * SETUPS THE FORM AT THE BEGINNING IF IT'S DYNAMIC, IF NOT IT'S NOT CALLED
 * THEN LOAD ALL THE BASE OPTION FOR THE FIELDS WITHOUT DEPENDANTS
 */
export function * setupForm ({ payload }) {
  const { rawForms, oldValues } = payload
  try {
    const sections = rawForms.actives
    const forms = sections.reduce((obj, key) => { obj[key] = rawForms[key]; return obj }, {})

    const realForms = JSON.parse(JSON.stringify(forms))

    const toLoad = []
    sections.forEach(section => {
      const { data } = realForms[section]

      if (data.static) {
        return
      }

      if (oldValues) {
        Object.keys(data).forEach(id => {
          const field = data[id]
          const { fieldType, options } = field
          const vals = oldValues[section]
          const v = vals.find(({ fieldId }) => `${fieldId}` === id)

          if (v) {
            const { value } = v

            switch (fieldType) {
              case DYNAMIC_FIELD_TYPES.SELECT:
                if (options.length) {
                  field.value = options.find(o => o.id === value.id)
                } else {
                  field.value = {
                    ...value,
                    label: value.description,
                    value: value.description
                  }
                }
                break

              case DYNAMIC_FIELD_TYPES.BOOLEAN:
                switch (value.id) {
                  case 'True':
                    field.value = { id: 1, value: true, label: 'SI' }
                    break
                  case 'False':
                    field.value = { id: 0, value: false, label: 'NO' }
                    break
                  default:
                    field.value = ''
                }
                break
              default: field.value = value.id
            }
          }
        })
      }

      Object.keys(data).forEach(id => {
        const field = data[id]
        const { dependsOn, fieldType, optionsUrl } = field

        if (dependsOn && dependsOn.every(pId => !!data[pId].value)) {
          if (fieldType === DYNAMIC_FIELD_TYPES.SELECT && optionsUrl) {
            toLoad.push({ section, id })
          } else {
            field.disabled = false
          }
        }
      })
    })

    yield put(FORM_ACTIONS.INIT({ forms: realForms, sections }))

    yield all(toLoad.map(f => put(FIELD_ACTIONS.LOAD_OPTIONS(f))))
    yield put(FORM_ACTIONS.SUCCESS())
  } catch (err) {
    yield put(FORM_ACTIONS.ERROR(ERROR_MESSAGES.GET_FORM))
  }
}

/**
 * CALLED WHEN A FIELD VALUE IS CHANGED ON THE FORM
 * IT CLEARS THE VALUE OF ALL FIELDS THAT DEPEND ON THIS FIELD
 * THEN UPDATE THE VALUE OF THIS FIELD
 *
 * @param {string} section Name of the section this field is in
 * @param {number} id Id of the field
 * @param {any} value New value of the field changed on the form
 */
export function * changeFieldValue ({ payload }) {
  const { section, id, value } = payload

  const data = yield select(SELECTOR.FORM_DATA(section))

  if (data[id].value === value) {
    return
  }

  yield call(clearField, payload)
  yield put(FIELD_ACTIONS.UPDATE(payload))
}

/**
 * CLEARS THE VALUE OF THE GIVEN FIELD AND ALL FIELDS DEPENDANT OF IT
 *
 * @param {string} section Name of the section this field is in
 * @param {number} id Id of the field
 */
export function * clearField ({ section, id }) {
  const dependants = yield select(SELECTOR.DEPENDANTS(section, id))

  for (let x = 0; x < dependants.length; x++) {
    const { id } = dependants[x]

    const payload = { section, id }

    yield call(clearField, payload)
    yield put(FIELD_ACTIONS.CLEAR(payload))
  }
}

/**
 * CHECKS IF THER IS ANY FIELD THAT DEPENDS ON THE CHANGED ONE AND
 * LOAD IT'S OPTIONS
 *
 * @param {string} section Name of the section this field is in
 */
export function * updateFieldsOptions ({ payload }) {
  const { section } = payload

  try {
    const data = yield select(SELECTOR.FORM_DATA(section))

    const toLoad = []
    Object.keys(data).forEach(id => {
      const { disabled, options, dependsOn } = data[id]

      if (disabled && !options.length && dependsOn.every(i => !!data[i].value)) {
        toLoad.push({ section, id })
      }
    })

    yield all(toLoad.map(el => put(FIELD_ACTIONS.LOAD_OPTIONS(el))))
  } catch (err) {
    yield put(FORM_ACTIONS.ERROR(ERROR_MESSAGES.GET_FORM))
  }
}

/**
 * LOADS THE SELECT OPTIONS FOR THE GIVEN FIELD
 *
 * @param {string} section Name of the section this field is in
 * @param {number} id Id of the field
 */
export function * loadOptions ({ payload }) {
  const { section, id } = payload

  try {
    const data = yield select(SELECTOR.FORM_DATA(section))

    const params = {}
    const { dependsOn, optionsUrl, translationAvailable } = data[id]

    dependsOn.forEach(i => {
      params[i] = data[i].value.id
    })

    const result = yield call(apicCalls.getOptions, ({
      url: replaceParams(optionsUrl, params),
      hasTranslation: translationAvailable ? 1 : 0
    }))

    const options = result.map(o => ({
      ...o,
      value: o.description,
      label: o.description
    }))

    yield put(FIELD_ACTIONS.LOAD_OPTIONS_SUCCESS({
      section,
      id,
      value: options
    }))
  } catch (err) {
    yield put(FIELD_ACTIONS.LOAD_OPTIONS_ERROR({
      section,
      id,
      err: ERROR_MESSAGES.GET_FORM_FIELD_OPTION
    }))
  }
}

export default function * saga () {
  yield takeLatest(FORM.SETUP, setupForm)
  yield takeLatest(FIELD.CHANGE, changeFieldValue)
  yield takeEvery(FIELD.LOAD, loadOptions)
  yield takeLatest(FIELD.SMS_SEND, sendSms)
  yield takeLatest(FIELD.VERIFICATION_SEND, sendVerification)
  yield takeLatest(FIELD.UPDATE, updateFieldsOptions)
}
