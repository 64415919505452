import styled, { css } from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { coverBackground } from 'Utils/style'
import { colors, spacing } from 'Global/theme'

export const mobileStyles = css`
  padding: ${spacing.two} ${spacing.one};
  font-size: 8pt;
`

export const tabletStyle = css`
  padding: ${spacing.two} ${spacing.eight};
  font-size: 12pt;
`

export const desktopStyle = css`
  padding: ${spacing.two} ${spacing.ten};
  font-size: 12pt;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${colors.primary.dark};
  ${coverBackground(colors.primary.dark)};

  color: ${colors.primary.regular};

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;

  > p {
    margin: 0;
  }

  ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return mobileStyles
      case BREAKPOINTS.TABLET: return tabletStyle
      default: return desktopStyle
    }
  }};
`
