import React from 'react'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from 'Global/constants'

import Form from 'Components/Form'

import { Wrapper } from './style'

import { SECTION } from '../constants'
const data = {

  email: {
    id: 'email',
    name: 'email',
    label: 'E-mail',
    type: INPUT_TYPES.EMAIL,
    required: true,
    static: true,
    value: ''
  }
}

const order = [
  { id: 'email', order: 1 }
]

const form = {
  actives: [SECTION],
  [SECTION]: { data, order }
}

const ContactForm = ({ handleSubmit }) => (
  <Wrapper>
    <Form
      submit={handleSubmit}
      buttonText='Enviar'
      rawForms={form}
      recaptcha
    />
  </Wrapper>
)

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

export default ContactForm
