import React from 'react'
import { useSelector } from 'react-redux'

import cancel from 'Assets/cancel.svg'

import { WAITING_MESSAGES } from 'Global/messages'
import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'
import Loading from 'Components/Loading'
import Information from 'Components/Information'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'
import Form from './Components/Form'

import useRedux from './Redux'
import SELECTORS from './Redux/selectors'

import { Container, Description } from './style'

const Cancel = () => {
  useRedux()

  const sending = useSelector(SELECTORS.SENDING)

  const device = useWindowDimensions()

  return (
    <Page>
      {sending && <Loading overlay message={WAITING_MESSAGES.CANCEL_POLICY} />}

      {device !== BREAKPOINTS.MOBILE && (
        <HeroImage
          src={cancel}
          color='primary'
          title='CANCELAR PÓLIZA'
        />
      )}

      <Container>
        <Information
          title='CANCELAR PÓLIZA'
          subtitle='Cancele fácilmente'
        />

        <Description>
          <p className='top'>
            Ingrese los datos de la póliza que desea cancelar:
          </p>
          <p className='bot'>
            *campos obligatorios
          </p>
        </Description>

        <Form />
      </Container>

      <Advice />
      <Associated />
      <Contact />

    </Page>
  )
}

export default Cancel
