import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectDynamicForm = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectDynamicForm, state => get(state, 'loading', true)
)

const selectError = createSelector(
  selectDynamicForm, state => get(state, 'error', false)
)

const selectForm = createSelector(
  selectDynamicForm, state => get(state, 'forms', {})
)

const selectSections = createSelector(
  selectDynamicForm, state => get(state, 'forms.actives', [])
)

const selectFormId = createSelector(
  selectDynamicForm, state => get(state, 'formId', -1)
)

const selectValues = createSelector(
  selectDynamicForm, state => get(state, 'values', undefined)
)

const selectProductId = createSelector(
  selectDynamicForm, state => get(state, 'productId', 0)
)

const selectDone = createSelector(
  selectDynamicForm, state => get(state, 'done', false)
)

export default {
  LOADING: selectLoading,
  ERROR: selectError,
  FORM: selectForm,
  SECTIONS: selectSections,
  FORM_ID: selectFormId,
  VALUES: selectValues,
  PRODUCT_ID: selectProductId,
  DONE: selectDone
}
