import React from 'react'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import DHeader from './Desktop'
import MHeader from './Mobile'

const Header = () => {
  const device = useWindowDimensions()

  return (
    <>
      {device === BREAKPOINTS.DESKTOP ? <DHeader /> : <MHeader />}
    </>
  )
}

export default Header
