/* eslint-disable max-len */

import {
  faCar,
  faBomb,
  faSkull,
  faCarCrash,
  faInfoCircle,
  faHouseDamage,
  faCloudShowersHeavy,
  faCogs,
  faBalanceScale,
  faFileSignature,
  faUserShield,
  faUserTie,
  faMoneyCheckAlt,
  faShieldAlt,
  faKey,
  faTaxi,
  faNotesMedical,
  faAdjust,
  faUserMd,
  faCertificate,
  faFileInvoice,
  faUserInjured,
  faCarSide
} from '@fortawesome/free-solid-svg-icons'

import { COVERAGE } from 'Global/constants'

import cardInsuranceCar from 'Assets/card_insurance_car.jpg'
import cardInsuranceLife from 'Assets/card_insurance_life.png'
import cardInsuranceHome from 'Assets/card_insurance_home.png'
import cardInsuranceBuilding from 'Assets/card_insurance_building.png'

export const PRODUCTS = {
  1: {
    img: cardInsuranceCar,
    icon: faCarCrash,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto.'
  },
  2: {
    img: cardInsuranceHome,
    icon: faHouseDamage,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  3: {
    img: cardInsuranceCar,
    icon: faCarCrash,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  4: {
    img: cardInsuranceCar,
    icon: faCarCrash,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  5: {
    img: cardInsuranceCar,
    icon: faCarCrash,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  6: {
    img: cardInsuranceBuilding,
    icon: faBomb,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  7: {
    img: cardInsuranceCar,
    icon: faCarCrash,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  8: {
    img: cardInsuranceCar,
    icon: faCarCrash,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  },
  9: {
    img: cardInsuranceLife,
    icon: faSkull,
    description: 'Cotizá aquí el seguro para tu vehículo en 1 minuto. Compare, contrate y obtenga su póliza en menos de 8 minutos.'
  }
}

export const METADATAICONS = [
  {
    id: 5,
    description: 'Auxilio vehicular',
    icon: faCar
  },
  {
    id: 6,
    description: 'Daños por granizo',
    icon: faCloudShowersHeavy
  },
  {
    id: 7,
    description: 'Choferes a disposición',
    icon: faUserShield
  },
  {
    id: 8,
    description: 'Abogado en accidentes',
    icon: faUserTie
  },
  {
    id: 9,
    description: 'Responsabilidad registrada',
    icon: faMoneyCheckAlt
  },
  {
    id: 10,
    description: 'Reparacion de cristales',
    icon: faShieldAlt
  },
  {
    id: 11,
    description: 'Reparación de cerrajería',
    icon: faKey
  },
  {
    id: 12,
    description: 'Remise en accidente',
    icon: faTaxi
  },
  {
    id: 13,
    description: 'Gastos médicos en accidente',
    icon: faNotesMedical
  },
  {
    id: 14,
    description: 'Ajuste de luces',
    icon: faAdjust
  },
  {
    id: 15,
    description: 'Repuestos Originales',
    icon: faCogs
  },
  {
    id: 16,
    description: 'Asistencia Legal',
    icon: faBalanceScale
  },
  {
    id: 17,
    description: 'Gastos Notariales por Pérdida Total',
    icon: faFileSignature
  },
  {
    id: 18,
    description: 'Accidentes Personales',
    icon: faUserMd
  },
  {
    id: 19,
    description: 'Tarjeta Verde',
    icon: faCertificate
  },
  {
    id: 20,
    description: 'SOA',
    icon: faFileInvoice
  },
  {
    id: 21,
    description: 'Vehículo de cortesía',
    icon: faCar
  },
  {
    id: 22,
    description: 'Vehículo de cortesía por hurto',
    icon: faCarSide
  },
  {
    id: 23,
    description: 'Reposición 0 Km.',
    icon: faInfoCircle
  },
  {
    id: 24,
    description: 'Monto de Cobertura de Responsabiliad Civil',
    icon: faUserInjured
  }
]

export const COVERAGE_MAPPING = {
  [COVERAGE.BASIC.label]: 'Responsabilidad Civil',
  [COVERAGE.MEDIUM.label]: 'Cobertura Triple',
  [COVERAGE.TOTAL.label]: 'Cobertura Todo Riesgo'
}
