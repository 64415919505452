import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectFormComponent = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectFormComponent, state => get(state, 'loading', false)
)

const selectError = createSelector(
  selectFormComponent, state => get(state, 'error', '')
)

const selectSections = createSelector(
  selectFormComponent, state => get(state, 'sections', [])
)

const selectForms = createSelector(
  selectFormComponent, state => get(state, 'forms', {})
)

const selectFormData = section => createSelector(
  selectFormComponent, state => get(state, `forms[${section}].data`, {})
)

const selectFormOrder = section => createSelector(
  selectFormData, state => get(state, `form[${section}].order`, [])
)
const selectSms = createSelector(
  selectFormComponent, state => get(state, 'sms', -1)
)
const selectVerification = createSelector(
  selectFormComponent, state => get(state, 'verification', -1)
)
const selectDependants = (section, id) => createSelector(
  selectFormComponent,
  state => {
    const data = get(state, `forms[${section}].data`, {})

    const ret = []
    Object.keys(data).forEach(key => {
      const field = data[key]
      const { dependsOn } = field

      if (dependsOn && dependsOn.includes(Number(id))) {
        ret.push(field)
      }
    })

    return ret
  }
)

export default {
  LOADING: selectLoading,
  ERROR: selectError,
  SECTIONS: selectSections,
  FORMS: selectForms,
  FORM_DATA: selectFormData,
  FORM_ORDER: selectFormOrder,
  SMS_SUCCESS: selectSms,
  VERIFICATION_SUCCESS: selectVerification,
  DEPENDANTS: selectDependants
}
