import styled, { css } from 'styled-components'

import { DIR, BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'
export const Paragraph = styled.p`
  font-weight: bold;
  color: #00725d;
`
export const Wrapper = styled.div`
  width: 100%;

  padding: ${spacing.five} 0;

  > :first-child {
    margin-bottom: ${spacing.five};
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const DisplayMobile = css`
  flex-direction: column;
  padding: 0 ${spacing.two};
`
const DisplayTablet = css`
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 ${spacing.two};
`

const DisplayDesktop = css`
  flex-direction: row;
  flex-wrap: wrap;
`

export const Display = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE:
        return DisplayMobile
      case BREAKPOINTS.TABLET:
        return DisplayTablet
      default:
        return DisplayDesktop
    }
  }}
`

const CardMobile = css`
  width: 100%;
  height: 250px;

  > :first-child {
    padding: ${spacing.oneAndAHalf};
    justify-content: center;
  }
`
const CardTablet = css`
  width: 270px;
  height: 250px;

  > :first-child {
    padding: ${spacing.oneAndAHalf};
    justify-content: center;
  }
`
const CardDesktop = css`
  width: 242px;
  height: 384px;
`

export const CardContainer = styled.div`
  min-width: 270px;
  min-height: 280px;
  > :first-child {
    margin-bottom: ${spacing.two};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Card = styled.div`
  ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE:
        return CardMobile
      case BREAKPOINTS.TABLET:
        return CardTablet
      default:
        return CardDesktop
    }
  }};

  margin-top: ${spacing.two};

  position: relative;

  ${family.barlow};

  box-shadow: 0 3px 6px ${colors.grays[20]};
  border-radius: 20px;

  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;
  cursor: pointer;
`

const Top = css`
  top: ${({ showing }) => (showing === DIR.TOP ? 0 : '-100%')};
  transition: top 0.2s, opacity 0.2s;
`

const Right = css`
  right: ${({ showing }) => (showing === DIR.RIGHT ? 0 : '-100%')};
  transition: right 0.2s, opacity 0.2s;
`

const Bot = css`
  bottom: ${({ showing }) => (showing === DIR.BOT ? 0 : '-100%')};
  transition: bottom 0.2s, opacity 0.2s;
`

const Left = css`
  left: ${({ showing }) => (showing === DIR.LEFT ? 0 : '-100%')};
  transition: left 0.2s, opacity 0.2s;
`

export const Explanation = styled.div`
  position: absolute;
  padding: ${spacing.one};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Info = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: ${spacing.sixAndAHalf} ${spacing.two} ${spacing.five};

  background-color: ${colors.primary.dark80};
  color: ${colors.secondary.regular};

  opacity: ${({ showing }) => (showing === 'no' ? 0 : 1)};

  ${({ dir }) => {
    switch (dir) {
      case DIR.TOP:
        return Top
      case DIR.RIGHT:
        return Right
      case DIR.BOT:
        return Bot
      case DIR.LEFT:
        return Left
      default:
        return ''
    }
  }};
  transition-timing-function: ease-in;
`

export const Name = styled.p`
  ${weight.semiBold};
  font-size: 20pt;
  text-align: center;
  line-height: 1;
  margin: ${spacing.twoAndAHalf} 0;
`

export const Text = styled.p`
  ${weight.regular};
  font-size: 12pt;
  text-align: center;
  margin: 0;
`

export const Detector = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  overflow: hidden;

  z-index: 7;
`

export const Svg = styled.svg`
  width: 100%;
  height: 100%;

  > polygon {
    stroke: none;
    fill: red;
    opacity: 0;
  }

  > polygon:hover {
    fill: blue;
    opacity: 0;
  }
`
