import React from 'react'
import PropTypes from 'prop-types'

import covImg1 from 'Assets/Icono-SEGURO-RESP-CIVIL-2.svg'
import covImg2 from 'Assets/Icono-SEGURO-TRIPLE-2.svg'
import covImg3 from 'Assets/Icono-SEGURO-TODORIESGO-2.svg'

import { COVERAGE } from 'Global/constants'
import { COVERAGE_MAPPING } from 'Global/mapping'

import { useWindowDimensions } from 'Components/WindowDimensions'

import Dropdown from './CoverageDropdown'

import { CoverageBlock, CoverageImg, Title } from '../style'

const Coverage = ({ type }) => {
  const device = useWindowDimensions()

  const image = type === COVERAGE.TOTAL ? covImg3
    : type === COVERAGE.MEDIUM ? covImg2 : covImg1

  const text = COVERAGE_MAPPING[type]

  return (
    <CoverageBlock device={device}>
      <CoverageImg src={image} />
      <Title>{text}</Title>

      
    </CoverageBlock>
  )
}

Coverage.propTypes = {
  type: PropTypes.oneOf(Object.values(COVERAGE)),
  changeType: PropTypes.func
}

export default Coverage
