import { FIELDS, EMAIL } from './types'

/**
 *
 * FIELDS ACTIONS
 */
export const changeField = payload => ({
  type: FIELDS.SAVE,
  payload
})

export const clearFields = () => ({
  type: FIELDS.CLEAR
})

export const FIELD_ACTIONS = {
  CHANGE: changeField,
  CLEAR: clearFields
}

/**
 *
 * EMAIL ACTIONS
 */
export const sendEmail = payload => ({
  type: EMAIL.SEND,
  payload
})

export const sendEmailSuccess = () => ({
  type: EMAIL.SUCCESS
})

export const sendEmailError = payload => ({
  type: EMAIL.ERROR,
  payload
})

export const EMAIL_ACTIONS = {
  SEND: sendEmail,
  SUCCESS: sendEmailSuccess,
  ERROR: sendEmailError
}
