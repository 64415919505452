import styled from 'styled-components'
import { spacing, colors } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'
export const Bar = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.grays[0]};
  padding: ${props => props.device < BREAKPOINTS.DESKTOP ? '0' : spacing.one};
  width: 100%;
  p {
    text-align: center;
    margin: 0;
    font-weight: normal;
    color: ${colors.primary.dark};
    font-size: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE:
        return '14px'
      case BREAKPOINTS.TABLET:
        return '12px'
      default:
        return '12px'
    }
  }};
  }

  > a {
    text-decoration: none;
  }
  flex-direction: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE:
        return 'column'
      case BREAKPOINTS.TABLET:
        return 'column'
      default:
        return 'row'
    }
  }};
`
