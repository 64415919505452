import produce from 'immer'

import { FORM, FIELD } from './types'

export const initialState = {
  loading: false,
  error: '',
  sections: [],
  forms: {},
  sms: null,
  smsError: false,
  verification: null,
  verificationError: false
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const { section, id, value, err } = payload || {}
    switch (type) {
      // FORM
      case FORM.SETUP:
        draft.loading = true
        break

      case FORM.INIT:
        draft.sections = payload.sections
        draft.forms = payload.forms
        break

      case FORM.SUCCESS:
        draft.loading = false
        draft.error = ''
        break

      case FORM.ERROR:
        draft.loading = false
        draft.error = payload
        draft.sections = []
        draft.forms = {}
        break

      case FORM.CLEAR:
        draft.loading = false
        draft.section = []
        draft.forms = {}
        break

      // FIELDS
      case FIELD.LOAD:
        draft.forms[section].data[id].loading = true
        break

      case FIELD.SUCCESS:
        draft.forms[section].data[id].loading = false
        draft.forms[section].data[id].disabled = false
        draft.forms[section].data[id].options = value
        break

      case FIELD.ERROR:
        draft.forms[section].data[id].loading = false
        draft.forms[section].data[id].disabled = true
        draft.forms[section].data[id].options = []
        draft.forms[section].data[id].error = err
        break

      case FIELD.UPDATE:
        draft.forms[section].data[id].value = value
        break

      case FIELD.CLEAR:
        draft.forms[section].data[id].loading = false
        draft.forms[section].data[id].disabled = true
        draft.forms[section].data[id].value = null
        draft.forms[section].data[id].options = []
        draft.sms = null
        draft.smsError = null
        draft.verificationError = null
        draft.verification = null
        draft.verificationSuccess = null
        break
      case FIELD.SMS_SEND:
        draft.sms = null
        draft.smsError = false
        break
      case FIELD.SMS_FAIL:
        draft.sms = null
        draft.smsError = true
        break
      case FIELD.SMS_SUCCESS:
        draft.sms = payload
        draft.smsError = false
        break

      case FIELD.VERIFICATION_SEND:
        draft.verificationError = false
        draft.verification = null
        break
      case FIELD.VERIFICATION_FAIL:
        draft.verificationError = true
        draft.verification = null
        break
      case FIELD.VERIFICATION_SUCCESS:
        draft.verificationError = false
        draft.verificationSuccess = true
        draft.verification = payload
        draft.verificationId = payload.verificationId
        break
    }
  })

export default reducer
