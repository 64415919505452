import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { get } from 'lodash'

import { DYNAMIC_FIELD_TYPES } from 'Global/constants'
import { message } from 'Messages/factory'
import { checkField } from 'Utils/checks'

import Button from 'Components/Button'
import Loading from 'Components/Loading'
import StepIndicator from 'Components/StepIndicator'

import useRedux from './Redux'
import { FORM_ACTIONS, FIELD_ACTIONS } from './Redux/actions'
import SELECTOR from './Redux/selector'

import RenderInput from './input'
import { formatValues } from './formatter'
import { Container, GroupContainer, GroupLabel } from './style'
import Sms from './Sms'

const Form = props => {
  const {
    rawForms,
    submit,
    buttonText,
    buttonBlock,
    recaptcha,
    oldValues,
    startStep,
    sms,
    handleValidation,
    onSuccess
  } = props

  useRedux()
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [sentRequest, setSentRequest] = useState(false)
  const [sentVerification, setSentVerification] = useState(false)
  const [validator, setValidator] = useState('')
  const [step, setStep] = useState(0)
  const [section, setSection] = useState('')
  const sections = useSelector(SELECTOR.SECTIONS)
  const forms = useSelector(SELECTOR.FORMS)
  const loading = useSelector(SELECTOR.LOADING)
  const smsData = useSelector(SELECTOR.SMS_SUCCESS)
  const verificationData = useSelector(SELECTOR.VERIFICATION_SUCCESS)

  const [formState, setFormState] = useState({
    isFirstRun: true,
    isLoading: false
  })
  const [verificationId, setVerificationId] = useState(null)
  const [valids, setValids] = useState({})
  const [robot, setRobot] = useState(false)
  const [verified, setVerifiedSms] = useState(false)
  const myRef = useRef(null)

  useEffect(() => {
    dispatch(FORM_ACTIONS.SETUP({ rawForms, oldValues }))
    return () => dispatch(FORM_ACTIONS.CLEAR())
  }, [])

  useEffect(() => {
    setSection(sections[step])
  }, [sections])

  // Creates an object that determins if the input should be with error or not. At first none has error
  useEffect(() => {
    const data = get(forms, `${section}.data`, {})
    const newValids = {}
    Object.keys(data).forEach(id => {
      newValids[id] = true
    })

    setValids(newValids)
  }, [forms, section])

  useEffect(() => {
    if (startStep === undefined) return
    if (startStep < 0 || startStep >= sections.length) return

    setStep(startStep)
    setSection(sections[startStep])
  }, [startStep])

  const handleRecaptcha = (recatpchaCode) => {
    if(recatpchaCode){
      setRobot(true)
    } else {
      setRobot(false)
    }
  }

  const handleClick = () => {
    const valid = validateSection(section)

    if (valid) {
      if (step < sections.length - 1) {
        setStep(step + 1)
        setSection(sections[step + 1])
        window.scrollTo(0, 132)
      } else {
        handleSubmit()
      }
    }
  }

  const validateSection = sect => {
    const data = get(forms, `${sect}.data`, {})
    const newValids = {}

    Object.keys(data).forEach(key => {
      const field = data[key]
      const { type, value, required } = field
      newValids[key] = checkField({ type, value, required })
    })

    let error = ''
    const invalidOnes = Object.keys(newValids).filter(k => !newValids[k])

    invalidOnes.forEach((key, i) => {
      const field = data[key]

      if (!field) return
      const { label } = field

      if (i === 0) {
        error = label
        return
      }

      const separator = i < invalidOnes.length - 1 ? ', ' : ' y '
      error += separator + label
    })

    if (!error) {
      return true
    } else {
      setValids(newValids)
      message.warning({
        message: `Los siguientes campos son inválidos: ${error}`,
        time: 8000
      })
      return false
    }
  }

  const handleSubmit = () => {
    const values = formatValues(forms)
    submit(values)
  }

  const handleChange = ({ name, value }) => {
    const newValids = {
      ...valids,
      [name]: true
    }

    setValids(newValids)

    dispatch(FIELD_ACTIONS.CHANGE({
      section,
      id: name,
      value
    }))
  }

  const goTo = i => {
    if (i < step) {
      setStep(i)
      setSection(sections[i])
    } else if (i > step) {
      let s = step
      while (s < i && validateSection(sections[s])) {
        s++
      }
      if (s !== step) {
        setStep(i)
        setSection(sections[i])
      }
    }
  }

  const renderGroup = field => {
    const data = get(forms, `${section}.data`, {})

    const { label, id } = data[field.id]

    const children = field.children.map(({ id }) => data[id])

    return (
      <GroupContainer key={id}>
        <GroupLabel>{label}</GroupLabel>
        {children.map((child) => (
          <RenderInput
            key={child.id}
            field={child}
            valid={valids[child.id]}
            onChange={handleChange}
          />
        ))}
      </GroupContainer>
    )
  }

  const renderForm = () => {
    const data = get(forms, `${section}.data`, {})
    const order = get(forms, `${section}.order`, [])

    return (
      order.map(field => {
        const { fieldType, id } = field
        switch (fieldType) {
          case DYNAMIC_FIELD_TYPES.GROUP:
            return renderGroup(field)
          default:
            return (
              <RenderInput
                key={id}
                field={data[id]}
                valid={valids[id]}
                onChange={handleChange}
              />
            )
        }
      })
    )
  }

  let text = 'Siguiente'

  if (Array.isArray(buttonText)) {
    text = step < sections.length - 1 ? buttonText[0] : buttonText[1]
  } else {
    text = buttonText
  }

  const onChangeValidator = event => {
    setValidator(event.value)
  }

  const onChangePhone = event => {
    setValue(event.value)
  }

  const isNumberValid = value => {
    try {
      const n = (value[0] + value[1]) === '09'
      return n && value.length === 9
    } catch (ex) {
      return false
    }
  }

  const onRequestCode = () => {
    if (!isNumberValid(value)) {
      message.error('El número no es válido')
      return
    }
    dispatch(FIELD_ACTIONS.SMS_SEND({
      cellPhone: value
    }))
    setSentRequest(true)
    setFormState({ ...formState, isFirstRun: false, isLoading: true })
    message.success('El mensaje ha sido enviado a su celular')
  }

  const onVerifyCode = () => {
    setSentVerification(false)
    dispatch(FIELD_ACTIONS.VERIFICATION_SEND({
      verificationId,
      verificationCode: validator
    }))
    setFormState({ ...formState, isFirstRun: false, isLoading: true })
  }
  useEffect(() => {
    if (smsData && smsData.success) {
      setSentRequest(false)
      setFormState({ ...formState, isFirstRun: false, isLoading: false })
      setVerificationId(smsData.verificationId)
      if (handleValidation) {
        try {
          handleValidation(smsData.verificationId)
        } catch (e) {
          console.error(encodeURIComponent)
        }
      }
    }
  }, [smsData])
  useEffect(() => {
    if (verificationData) {
      if (verificationData.success) {
        if (!verified) {
          setVerifiedSms(true)
          setFormState({ ...formState, isFirstRun: false, isLoading: false, verified: true })
          if (onSuccess) {
            onSuccess(verificationData.message)
          }
        }
      } else {
        message.error('Ha ocurrido un error.')
        setFormState({ ...formState, isFirstRun: false, isLoading: false })
      }
    }
  }, [verificationData])
  return (
    <Container ref={myRef}>
      {loading ? (
        <Loading size='large' />
      ) : (
        <>
          {renderForm()}
          {recaptcha && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={handleRecaptcha}
            />
          )}
          {sms && !verified && (
            <Sms
              requestCode={onRequestCode}
              verifyCode={onVerifyCode}
              sentRequest={sentRequest}
              sentVerification={sentVerification}
              value={value} validator={validator}
              onChangePhone={onChangePhone}
              onChangeValidator={onChangeValidator}
              formState={formState}
              validatedCaptcha={robot}
              isLoading={formState.isLoading}
            />
          )}
          <Button
            size='large'
            color='primary'
            block={buttonBlock}
            onClick={handleClick}
            disabled={(recaptcha && !robot) || (sms && !verified)}
          >
            {text}
          </Button>

          {sections.length > 1 && (
            <StepIndicator
              onClick={goTo}
              steps={sections.length}
              step={step}
            />
          )}
        </>
      )}
    </Container>
  )
}

Form.propTypes = {
  rawForms: PropTypes.object,
  submit: PropTypes.func,
  buttonText: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  buttonBlock: PropTypes.bool,
  recaptcha: PropTypes.bool,
  oldValues: PropTypes.object,
  startStep: PropTypes.number,
  sms: PropTypes.bool,
  handleValidation: PropTypes.func,
  onSuccess: PropTypes.func
}

export default Form
