import styled from 'styled-components'
import { colors, spacing } from 'Global/theme'

export const Card = styled.div`
  width: 832px;
  height: 240px;

  margin: ${spacing.fourAndAHalf} 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  background-color: ${colors.background.white};

  border-radius: 8px;
  box-shadow: 0 0 10px ${colors.grays[20]};
  border: solid thing ${colors.grays[30]};
`

export const Sector = styled.div`
  height: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Left = styled(Sector)`
  justify-content: center;
  align-items: center;
`

export const Middle = styled(Sector)`
  justify-content: space-between;
  align-items: flex-start;

  padding: ${spacing.three} 0;

  > * {
    margin-left: ${spacing.seven};
  }
`

export const Right = styled(Sector)`
  justify-content: space-between;
  align-items: flex-start;


  > :nth-child(1) {
    margin-left: ${spacing.twoAndAHalf};
  }
  > :nth-child(2) {
    margin-left: ${spacing.twoAndAHalf};
  }
  > :nth-child(3) {
    margin-left: ${spacing.threeAndAHalf};
  }

  padding: ${spacing.three} 0;
`
