import React from 'react'
import PropTypes from 'prop-types'

import { InfoBlock } from '../style'
import './custom-text-styles.css'
const Info = ({ title, information, device }) => (
  <InfoBlock device={device}>
    <p className='title'>{title}</p>

    {Array.isArray(information) ? (
      information.map((text, i) => (
        <p key={i} className='text custom-text'>{text}</p>
      ))
    ) : (
      <p className='text text-bold custom-text'>{information || '-'}</p>
    )}
  </InfoBlock>
)

Info.propTypes = {
  title: PropTypes.string,
  information: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  device: PropTypes.number
}

export default Info
