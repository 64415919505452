import { MESSAGES } from './types'

export const createMessage = payload => ({
  type: MESSAGES.CREATE,
  payload
})

export const addMessage = payload => ({
  type: MESSAGES.ADD,
  payload
})

export const showMessage = payload => ({
  type: MESSAGES.SHOW,
  payload
})

export const hideMessage = payload => ({
  type: MESSAGES.HIDE,
  payload
})

export const removeMessage = payload => ({
  type: MESSAGES.REMOVE,
  payload
})

export const clearMessages = () => ({
  type: MESSAGES.CLEAR
})

export const messageError = payload => ({
  type: MESSAGES.ERROR,
  payload
})

export const removeByUser = payload => ({
  type: MESSAGES.REMOVE_BY_USER,
  payload
})

export const MESSAGE_ACTIONS = {
  CREATE: createMessage,
  ADD: addMessage,
  SHOW: showMessage,
  HIDE: hideMessage,
  REMOVE: removeMessage,
  CLEAR: clearMessages,
  ERROR: messageError,
  REMOVE_BY_USER: removeByUser
}
