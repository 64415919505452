import React from 'react'
import { useHistory } from 'react-router-dom'
import Nav from '../Nav'
import { HOME } from 'Global/paths'
import logo from 'Assets/Seguros-online.png'

import { Bar, Image, Back } from './style'

const Top = () => {
  const history = useHistory()
  const goToHome = () => history.push(HOME)

  return (
    <>
      <Back />
      <Bar>
        <Image src={logo} onClick={goToHome} />
        <Nav />
      </Bar>
    </>
  )
}

export default Top
