import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const cancelPolicy = state => get(state, key, initialState)

const selectProductType = createSelector(
  cancelPolicy, state => get(state, 'productType', '')
)

const selectEmail = createSelector(
  cancelPolicy, state => get(state, 'email', '')
)

const selectTel = createSelector(
  cancelPolicy, state => get(state, 'tel', '')
)

const selectDocumentId = createSelector(
  cancelPolicy, state => get(state, 'documentId', '')
)

const selectSending = createSelector(
  cancelPolicy, state => get(state, 'sending', false)
)

const selectError = createSelector(
  cancelPolicy, state => get(state, 'error', false)
)

const selectPolicyId = createSelector(
  cancelPolicy, state => get(state, 'policyId', '')
)

export {
  selectEmail,
  selectTel,
  selectSending,
  selectError,
  selectProductType,
  selectDocumentId,
  selectPolicyId
}

export default {
  EMAIL: selectEmail,
  TEL: selectTel,
  SENDING: selectSending,
  ERROR: selectError,
  PRODUCT_TYPE: selectProductType,
  DOCUMENT_ID: selectDocumentId,
  POLICY_ID: selectPolicyId
}
