import React from 'react'
import PropTypes from 'prop-types'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { MyPage } from './styles'

const Page = ({ children }) => {
  const device = useWindowDimensions()

  return (
    <MyPage device={device}>{children}</MyPage>
  )
}

Page.propTypes = {
  children: PropTypes.any
}

export default Page
