import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Title from 'Components/Title'

import {
  Img,
  Content,
  Text,
  Button
} from './style'

const HeroImage = ({ src, gradient, colors, direction, title, lines, action, actionText, color }) => {
  const device = useWindowDimensions()

  return (
    <Img
      src={src}
      device={device}
    >
      <Content
        device={device}
        gradient={gradient}
        direction={direction}
        colors={colors}
        color={color}
      >
        {title && (
          <Title title={title} color={color} line />
        )}

        {device === BREAKPOINTS.DESKTOP && (
          Array.isArray(lines) ? (
            lines.map((t, i) => <Text key={i}>{t}</Text>)
          ) : (
            <Text>{lines}</Text>
          )
        )}

        {device !== BREAKPOINTS.MOBILE && action && (
          <Button
            shape='round'
            size='medium'
            color={color || 'dark'}
            onClick={action}
          >
            {actionText}
          </Button>
        )}

      </Content>

    </Img>
  )
}

HeroImage.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  lines: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  action: PropTypes.func,
  actionText: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  gradient: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
  direction: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'top right',
    'bottom right',
    'bottom left',
    'top left'
  ])
}

HeroImage.defaultProps = {
  colors: [],
  direction: 'right',
  lines: []
}

export default HeroImage
