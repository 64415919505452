import styled from 'styled-components'
import { colors } from 'Global/theme'

import { BREAKPOINTS } from 'Global/constants'

export const Wrapper = styled.div`
  width: 180px;
  width: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '180px' : '110px'};

  display: flex;
  margin-top: 8px;
  align-items: center;
  align-self: center;
  justify-content: center;
`
export const Arrow = styled.div`
  color: ${colors.primary.light} !important;
  margin-right: 5px;
  font-weight: 500;
  font-size: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '20px' : '12px'};
`
export const Text = styled.div`
  font-weight: 600;
  font-size: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '15px' : '12px'};
  text-align: center;
  margin-left: 3px;
`
