export const key = 'contact'

export const FIELDS = {
  SAVE: 'CONTACT/FIELDS/SAVE',
  CLEAR: 'CONTACT/FIELDS/CLEAR'
}

export const CONTACT = {
  SEND: 'CONTACT/SEND',
  SUCCESS: 'CONTACT/SUCCESS',
  ERROR: 'CONTACT/ERROR'
}

export default key
