export const key = 'formComponent'

export const FORM = {
  INIT: 'COMPONENT/FORM/FORM/INIT',
  SETUP: 'COMPONENT/FORM/FORM/SETUP',
  SUCCESS: 'COMPONENT/FORM/FORM/SUCCESS',
  ERROR: 'COMPONENT/FORM/FORM/ERROR',
  CLEAR: 'COMPONENT/FORM/FORM/CLEAR'
}

export const FIELD = {
  LOAD: 'COMPONENT/FORM/FIELD/LOAD',
  SUCCESS: 'COMPONENT/FORM/FIELD/SUCCESS',
  ERROR: 'COMPONENT/FORM/FIELD/ERROR',
  CHANGE: 'COMPONENT/FORM/FIELD/CHANGE',
  UPDATE: 'COMPONENT/FORM/FIELD/UPDATE',
  CLEAR: 'COMPONENT/FORM/FIELD/CLEAR',
  SMS_SEND: 'COMPONENT/FORM/FIELD/SMS_SEND',
  SMS_FAIL: 'COMPONENT/FORM/FIELD/SMS_FAIL',
  SMS_SUCCESS: 'COMPONENT/FORM/FIELD/SMS_SUCCESS',
  VERIFICATION_SEND: 'COMPONENT/FORM/FIELD/VERIFICATION_SEND',
  VERIFICATION_FAIL: 'COMPONENT/FORM/FIELD/VERIFICATION_FAIL',
  VERIFICATION_SUCCESS: 'COMPONENT/FORM/FIELD/VERIFICATION_SUCCESS'
}

export default key
