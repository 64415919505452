import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import homeImage from 'Assets/home.svg'
import success from 'Assets/policy-success.svg'

import { axiosDownload } from 'Axios'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'
import { ERROR_MESSAGES } from 'Global/messages'
import { message } from 'Messages/factory'

import Page from 'Components/Page'

import HeroImage from 'Components/HeroImage'
import Contact from 'Components/Contact'
import Advice from 'Components/Advice'
import Associated from 'Components/Associated'

import Loading from 'Components/Loading'
import Information from 'Components/Information'

import useRedux from './Redux'
import { POLICY_ACTIONS } from './Redux/actions'
import SELECTOR from './Redux/selector'

import ErrorCard from './Components/ErrorCard'

import Disclaimer from 'Components/Disclaimer'

import { Container, Section, Block } from './style'

const Certs = () => {
  useRedux()
  const dispatch = useDispatch()

  const device = useWindowDimensions()

  const loading = useSelector(SELECTOR.LOADING)
  const error = useSelector(SELECTOR.ERROR)
  const reference = useSelector(SELECTOR.REFERENCE)
  const code = useSelector(SELECTOR.CODE)
  const certs = useSelector(SELECTOR.CERTS)

  useEffect(() => {
    dispatch(POLICY_ACTIONS.GET())
    return () => {
      dispatch(POLICY_ACTIONS.CLEAR())
    }
  }, [])

  const downloadPDF = id => {
    axiosDownload.get(`get_file?file_type=insurance_policy_certificate&file_id=${id}`)
      .then(response => {
        // eslint-disable-next-line
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' }
        )

        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      })
      .catch(() => {
        message.error(ERROR_MESSAGES.CERT)
      })
  }

  return (
    <Page>
      {device !== BREAKPOINTS.MOBILE && (
        <HeroImage
          src={homeImage}
          title='PÓLIZA'
        />
      )}

      <Information
        title='PÓLIZA'
        subtitle='Vea los datos y certificados de su póliza'
      />
      <Disclaimer textNotice='Estamos trabajando en la generación de tus certificados de póliza. 
      Por favor anota el código de transacción y el número de póliza como referencia. 
      Cuando tus certificados estén disponibles podrás descargarlos de esta página. 
      ¡También te los enviaremos a tu casilla de correo!'/>
      <Container device={device}>
        <Section className='info'>
          <Block device={device}>
            <p>Código de la transacción:</p>

            {code ? (
              <p>{code}</p>
            ) : (
              <>
                {error && !loading && <FontAwesomeIcon icon={faTimes} size='2x' />}
                {loading && !error && (
                  <div className='loading'>
                    <Loading type='dots' />
                  </div>
                )}
              </>
            )}
          </Block>

          <Block device={device}>
            <p>Número de póliza:</p>

            {reference ? (
              // eslint-disable-next-line
              <p>{reference != '-1' ? reference : 'Su número de póliza le será enviado por correo electrónico a la brevedad.'}</p>
            ) : (
              <>
                {error && !loading && <FontAwesomeIcon icon={faTimes} size='2x' />}
                {loading && !error && (
                  <div className='loading'>
                    <Loading type='dots' />
                  </div>
                )}
              </>
            )}
          </Block>

          <Block className='certs' device={device}>
            <p>Certificados:</p>
            {certs.length ? (
              <div className='download'>
                {certs.map(({ id, description }) => (
                  <p key={id} onClick={() => downloadPDF(id)}>
                    {description}
                  </p>
                ))}
              </div>
            ) : (
              <>
                {error && !loading && <FontAwesomeIcon icon={faTimes} size='2x' />}
                {loading && !error && (
                  <div className='loading'>
                    <Loading type='dots' />
                  </div>
                )}
              </>
            )}
          </Block>
        </Section>

        <Section className='status'>
          {certs.length ? (
            <img src={success} />
          ) : (
            <>
              {error && !loading && <ErrorCard device={device} />}
              {loading && !error && (
                <Loading size='large' />
              )}
            </>
          )}
        </Section>

      </Container>

      <Advice />
      <Associated />
      <Contact />
    </Page>
  )
}

export default Certs
