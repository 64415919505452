import React from 'react'
import PropTypes from 'prop-types'

import spinner from 'Assets/loading-spinner.gif'
import dots from 'Assets/loading-dots.gif'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { Image, Overlay, Card } from './style'

const Loading = ({ size, overlay, type, message }) => {
  const device = useWindowDimensions()

  const loading = type === 'dots' ? dots : spinner

  return (
    <>
      {overlay ? (
        <Overlay>
          <Card device={device}>
            <Image src={loading} size='large' />
            <p className='title'>Por favor espere.</p>
            <p>{message}</p>
          </Card>
        </Overlay>
      ) : (
        <Image src={loading} size={size} />
      )}
    </>
  )
}

Loading.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['spinner', 'dots']),
  message: PropTypes.string,
  overlay: PropTypes.bool
}

export default Loading
