import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

export const MyPage = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${family.barlow};
  color: ${colors.primary.dark};

  padding: 0 ${({ device }) => device === BREAKPOINTS.DESKTOP ? '0' : spacing.oneAndAHalf};

  scroll-behavior: smooth;
`
