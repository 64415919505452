import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { weight } from 'Global/font'

export const Wrapper = styled.div `
  width: 100%;
  margin-top: ${spacing.one};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const Column = styled.div `
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
`

export const Container = styled.div `
  width: 281px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: ${spacing.one} 0;
`

export const Block = styled.div `
  width: 230px;
  color: ${({ included }) => included ? colors.primary.dark : colors.grays[20]};

  p {
    margin: 0;
    font-size: 12pt;
  }

  > .title {
    ${weight.regular};
  }

  > .desc {
    ${weight.light}
  }

`

export const Icon = styled.div `
  width: ${spacing.four};
  min-width: ${spacing.four};

  height: ${spacing.four};
  min-height: ${spacing.four};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ included }) => included ? colors.primary.regular : colors.grays[20]};

  > :first-child {
    height: 100%;
    width: 100%;
  }
`