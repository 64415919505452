/* eslint-disable */
export default [
  {
    question: '¿Qué vehículos deben contratar el seguro?',
    items: [
      {
        text: 'Todos los vehículos que circulen por la vía pública, sean automotores o remolcados.'
      },
      {
        text: 'Están exonerados de su contratación:'
      },
      {
        type: 'circle',
        text: 'Los automotores que circulen sobre rieles.'
      },
      {
        type: 'circle',
        text: 'Los automotores utilizados exclusivamente en el interior de establecimientos industriales, comerciales, agropecuarios, de playas ferroviarias o de cualquier otro lugar al que no tenga acceso el público.'
      },
      {
        type: 'circle',
        text: 'Los vehículos que se encuentren en depósito judicial.'
      },
      {
        type: 'circle',
        text: 'En general, todo vehículo no utilizado para la circulación vial.'
      },
      {
        text: 'Cabe indicar que aún en estos casos quedarán incluidos en la obligación de contratar el seguro en caso que circulen en la vía pública o sean remolcados en ella. Los vehículos matriculados en países extranjeros o ingresados en régimen de admisión temporaria, estarán igualmente sujetos a las disposiciones de esta ley.'
      },
      {
        type: 'check',
        text: 'Riesgos cubiertos por el seguro:'
      },
      {
        text: 'Lesiones o muerte causados a terceras personas, por el vehículo asegurado.'
      },
      {
        type: 'cross',
        text: 'Riesgos no cubiertos:'
      },
      {
        text: 'Daños materiales de ningún tipo. Daño moral. Daños no causados directamente por el vehículo asegurado.'
      }
    ]
  },

  {
    question: '¿A quiénes no se considera terceros a efectos de este seguro?',
    items: [
      {
        type: 'circle',
        text: `El propietario del vehículo, el tomador del seguro y el conductor, así como el cónyuge o
        concubino y los ascendientes o descendientes por consanguinidad o afinidad o por
        adopción y los parientes colaterales hasta el segundo grado de cualquiera de ellos,
        respecto del seguro del mismo vehículo.`
      },
      {
        type: 'circle',
        text: `Los dependientes a cualquier título del propietario, tomador del seguro o conductor,
        cuando se encuentren en el mismo vehículo, desempeñando tareas que tengan otra
        cobertura de seguro.`
      },
      {
        type: 'circle',
        text: `Las personas transportadas en el vehículo a título oneroso que tengan otra cobertura de
        seguro.`
      },
      {
        type: 'circle',
        text: `Los ocupantes de vehículos hurtados, salvo que probaren el desconocimiento de dicha
        circunstancia o no hubiera mediado voluntad en ocupar el vehículo.`
      },
      {
        type: 'circle',
        text: `La víctima o sus causahabientes, cuando haya mediado dolo de su parte para la produc-
        ción de las lesiones o la muerte.`
      }
    ]
  },

  {
    question: '¿Qué capitales cubre el SOA?',
    items: [
      {
        text: `Durante el primer año de vigencia de la ley (19/8/2009 – 19/08/2010): UI 150.000 (aprox.
          U$S 12.800)`
      },
      {
        text: 'Durante el segundo año de vigencia (19/8/2010 – 19/08/2011): UI 200.000'
      },
      {
        text: 'A partir del tercer año de vigencia de la ley (19/8/2011 – 19/08/2012): UI 250.000'
      },
      {
        text: `Los capitales indicados son los máximos por evento. Dichos capitales máximos se harán
        efectivos en caso de fallecimiento. En caso de lesiones se aplicará un baremo que
        determinará el porcentaje de capital a indemnizar según el tipo de lesión.`
      }
    ]
  },

  {
    question: 'UI (Unidad Indexada)',
    items: [
      {
        text: `Es una unidad de valor, creada en junio de 2002, que se va reajustando de acuerdo a la
        inflación, medida por el IPC- Índice de Precios al Consumo. Puede ser consultada a
        través del Instituto Nacional de Estadística, que es la entidad que fija y publica su valor
        oficial.`
      }
    ]
  }
]
