import React from 'react'
import PropTypes from 'prop-types'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import { Wrapper } from './style'

const DatePicker = ({ valid, name, value, disabled, placeholder, handleChange }) => {
  const date = value ? new Date(value.replace(/-/g, '/')) : ''

  const handleDayChange = d => {
    if (!d) return

    const val = d.toISOString().split('T')[0]
    const target = { name, value: val }
    handleChange({ target })
  }

  return (
    <Wrapper valid={valid}>
      <DayPickerInput
        name={name}
        value={date}
        placeholder={placeholder}
        inputProps={{ name, disabled }}

        onDayChange={handleDayChange}
      />
    </Wrapper>
  )
}

DatePicker.propTypes = {
  valid: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func
}

export default DatePicker
