import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { BAISC_INPUT_TYPES } from 'Global/constants'
import { message } from 'Messages/factory'
import { checkField } from 'Utils/checks'

import { Input } from 'Components/Input'
import Button from 'Components/Button'

import SELECT from './Redux/selectors'
import { FIELD_ACTIONS } from './Redux/actions'

const Ci = ({ next }) => {
  const dispatch = useDispatch()
  const ci = useSelector(SELECT.CI)

  const onChange = ({ value, name }) => {
    dispatch(FIELD_ACTIONS.CHANGE({ value, name }))
  }

  const validate = () => {
    const valid = checkField({
      type: BAISC_INPUT_TYPES.CI,
      value: ci,
      required: true
    })

    if (valid) {
      return true
    }

    message.warning({
      message: 'Los siguientes campos son inválidos: Cédula de identidad',
      time: 8000
    })

    return false
  }

  const handleNext = () => {
    if (validate()) next()
  }

  return (
    <>
      <Input
        type={BAISC_INPUT_TYPES.CI}
        name='ci'
        label='Cédula de identidad'
        required
        value={ci}
        onChange={onChange}
      />

      <Button
        size='large'
        onClick={handleNext}
      >
        Seguir
      </Button>
    </>
  )
}

Ci.propTypes = {
  next: PropTypes.func
}

export default Ci
