/* eslint-disable max-len */
export const ERROR_MESSAGES = {
    GENERAL: 'Hubo un problema. Por favor pruebe más tarde.',
    CANCEL_POLICY: 'Ha ocurrido un error cancelando la póliza',
    GETTING_PRODUCTS: 'Ha ocurrido un error consiguiendo los productos. Por favor pruebe más tarde.',
    GETTING_PROVIDERS: 'Ha ocurrido un error consiguiendo los provedores. Por favor pruebe más tarde.',
    GETTING_COUNTRIES: 'Ha ocurrido un error consiguiendo los países. Por favor pruebe más tarde',
    GETTING_DEPARTMETS: 'Ha ocurrido un error consiguiendo los departamentos. Por favor pruebe más tarde',
    GETTING_CITIES: 'Ha ocurrido un error consiguiendo las ciudades. Por favor pruebe más tarde',
    GETTING_STREETS: 'Ha ocurrido un error consiguiendo las calles. Por favor pruebe más tarde',
    GETTING_DATA: 'Hubo un problema cargando los datos. Por favor pruebe más tarde',
    USER: 'Hubo un problema con el ingreso del usuario. Por favor pruebe más tarde',
    GET_FORM: 'Hubo un problema al cargar el formulario. Por favor pruebe más tarde.',
    GET_FORM_FIELD_OPTION: 'Hubo un problema al cargar el formulario. Por favor pruebe más tarde.',
    SUBMIT_FORM: 'El formulario no es válido',
    RATE: 'Hubo un problema creando la cotización. Por favor pruebe más tarde.',
    CONTACT: 'Hubo un error al enviar mail de contacto',
    ERROR: 'Error',
    PAGE_ERROR: 'Hubo un eror accediendo a la página. Por favor vuelva a efectuar el proceso.',
    CERT: 'Hubo un problema descargando el certificado. Por favor pruebe más tarde.',
    TIMEOUT: 'Estó tardó mucho. Por favor vuelva a intentar',
    EMAIL: 'Error enviando cotizaciones.'

}

export const SUCCESS_MESSAGES = {
    CANCEL_POLICY: 'Se ha enviado la cancelación de póliza correctamente. Por favor espere, lo van a contactar por e-mail o teléfono',
    CONTACT: 'Se ha enviado mail de contacto',
    SUCCESS: 'Éxito',
    EMAIL: 'Hemos enviado una copia de la cotización a tu correo. Quedamos a las órdenes por cualquier consulta que te surja. ¡Muchas gracias por recibir nuestra propuesta!'
}

export const WAITING_MESSAGES = {
    POLICY_FORM: 'No cierre ni refresque la pantalla, estamos trabajando en su póliza',
    RATES_FORM: 'No cierre ni refresque la pantalla, estamos trabajando en sus cotizaciones.',
    QUOTE: 'No cierre ni refresque la pantalla.',
    CANCEL_POLICY: 'Se está envíando la petición de cancelación.',
    PROVIDERS: 'Por favor espere mientras se cargan los provedores.'
}