import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { spacing } from 'Global/theme'

export const Image = styled.img`
  width: 100%;
  max-width: 468px;
  height: auto;
`

export const Container = styled.div`
  width: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return '100%'
      case BREAKPOINTS.TABLET: return '80%'
      default: return '50%'
    }
  }};

  margin-top: ${spacing.four};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-bottom: ${spacing.five};
  }
`
