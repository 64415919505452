import { takeLatest, put, call } from 'redux-saga/effects'

import axios from 'Axios'

import { PRODUCTS, PROVIDERS } from './types'
import { PRODUCT_ACTIONS, PROVIDER_ACTIONS } from './actions'

const apiCalls = {
  getProducts: () => axios.get('/products'),
  getProviders: () => axios.get('/providers')
}

export function * getProducts () {
  try {
    const products = yield call(apiCalls.getProducts)
    yield put(PRODUCT_ACTIONS.SUCCESS(products))
  } catch (err) {
    yield put(PRODUCT_ACTIONS.ERROR(err))
  }
}

export function * getProviders () {
  try {
    const providers = yield call(apiCalls.getProviders)
    yield put(PROVIDER_ACTIONS.SUCCESS(providers))
  } catch (err) {
    yield put(PROVIDER_ACTIONS.ERROR(err))
  }
}

export default function * saga () {
  yield takeLatest(PRODUCTS.LOAD, getProducts)
  yield takeLatest(PROVIDERS.LOAD, getProviders)
}
