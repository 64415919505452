import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { PHONES, EMAILS, WHATSAPP } from 'Global/data'

import Card from 'Components/Card'
import Title from 'Components/Title'

import { Container, CardContainer, CardChild } from './style'

const height = '92'
const width = '320'

const Advice = () => {
  const mailTo = () => {
    window.location.assign(`mailto:${EMAILS.INFO}`)
  }

  const sendWhatsapp = () => {
    window.open(WHATSAPP.LINK, '_blank')
  }
  const sendCall = () => {
    window.open('tel:27069238')
  }
  return (
    <Container>
      <Title title='¿NECESITAS ASESORAMIENTO?' />

      <CardContainer>
        <Card width={width} height={height} onClick={mailTo}>
          <CardChild>
            <div className='icon'>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className='text'>
              <p>Envíanos un mail</p>
              <p className='data'>{EMAILS.INFO}</p>
            </div>
          </CardChild>
        </Card>

        <Card width={width} height={height} onClick={sendCall}>
          <CardChild>
            <div className='icon'>
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className='text'>
              <p>Llámanos</p>
              <p className='data'>{PHONES.PRIMARY} - {PHONES.SECONDARY}</p>
            </div>
          </CardChild>
        </Card>

        <Card width={width} height={height} onClick={sendWhatsapp}>
          <CardChild>
            <div className='icon'>
              <FontAwesomeIcon icon={faWhatsapp} />
            </div>
            <div className='text'>
              <p>Whatsapp</p>
              <p className='data'>{WHATSAPP.NUMBER}</p>
            </div>
          </CardChild>
        </Card>
      </CardContainer>

    </Container>
  )
}

export default Advice
