import styled, { css } from 'styled-components'
import { colors } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'
const oneLiner = css`
  position: absolute;
  float: right;
  right: 10px;
  top: 5px;
`

const twoLiner = css`
  position: absolute;
  right: 10px;
`
export const Container = styled.div`
  ${
    props => props.alternative ? twoLiner : oneLiner
  }
 display: ${
    props => props.device === BREAKPOINTS.MOBILE ? 'hidden' : 'flex'
  };
`

export const ItemContainer = styled.div`
  font-size: 26px;
  color: ${colors.primary.regular};
  display: flex;
  margin-bottom: 4px;
`
export const Item = styled.div`
  margin: ${({ margin }) => margin || 0};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  :hover {
    color: ${colors.primary.light};
  }
`