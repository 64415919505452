export const HOME = '/inicio'
export const ABOUT_US = '/acerca-de'
export const SOA = '/soa'
export const CONTACT = '/contacto'
export const CANCEL = '/cancelar-poliza'
export const QUOTE = '/cotizacion'
export const RATES_FORM = '/formulario-producto'
export const POLICY_FORM = '/formulario-poliza'
export const GLOSSARY = '/glosario'
export const HIRE = '/contratar-poliza'
export const CERTS = '/certificados'
export const FAQ = '/preguntas-frecuentes'
export const INSURANCES = '/seguro-poliza'
export const PRIVACY = '/privacidad'
