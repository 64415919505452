import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from 'Global/constants'
import { numberToString } from 'Utils/conversions'
import { insuranceCarddPT } from 'Global/propTypes'

import { useWindowDimensions } from 'Components/WindowDimensions'

import DCard from './desktop'
import MCard from './mobile'

import { Wrapper } from './style'

const Info = ({ data, handleContract, preview }) => {
  const device = useWindowDimensions()

  const {
    alias,
    payments,
    provider,
    metadata,
    currencyType,
    currencyTypeDeductible,
    deductibleAmount,
    providerCoverageType
  } = data

  const image = provider.company.publicLogoUrl
  const deductible =
    `${currencyTypeDeductible ? currencyTypeDeductible.symbol : currencyType.symbol}${numberToString(deductibleAmount)}`

  const p = payments.find(e => Number(e.shareQuantity) === 1)
  const fee = `${currencyType.symbol}${numberToString(p.shareAmount)}`

  const name = alias || providerCoverageType

  const props = {
    fee,
    name,
    image,
    preview,
    metadata,
    deductible,
    handleContract,
    device
  }

  return (
    <Wrapper device={device}>
      {device === BREAKPOINTS.DESKTOP ? (
        <DCard {...props} />
      ) : (
        <MCard {...props} />
      )}
    </Wrapper>
  )
}

Info.propTypes = {
  data: insuranceCarddPT,
  handleContract: PropTypes.func,
  preview: PropTypes.bool
}

export default Info
