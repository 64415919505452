import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { colors, spacing } from 'Global/theme'

export const Card = styled.div`
  height: ${({ device }) => device !== BREAKPOINTS.MOBILE ? '100px' : 'auto'};
  /* height: 100px; */
  width: 100%;
  max-width: 832px;
  overflow: hidden;

  border-radius: 8px;
  box-shadow: 0 0 10px 0 ${colors.grays[20]};
  border: solid thin ${colors.grays[10]};

  background-color: ${colors.background.white};

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 'row' : 'column-reverse'};

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `${spacing.one} ${spacing.twoAndAHalf}`
      case BREAKPOINTS.TABLET: return `${spacing.one} ${spacing.five}`
      default: return `${spacing.one} ${spacing.five}`
    }
  }};

  margin: ${spacing.two} 0;

  p {
    margin: 0;
    font-size: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 16 : 14}pt;
    text-align: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 'left' : 'center'};
  }

  img {
    height: auto;
    width: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 120 : 100}px;
  }
`
