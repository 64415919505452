import styled, { css } from 'styled-components'
import { colors, spacing } from 'Global/theme'

// COLORS
// -- primary
const PSolid = css`
  background-color: ${colors.primary.regular};
  color: ${colors.secondary.regular};

  :hover {
    background-color: ${colors.primary.hover};
  }
`
const POutline = css`
  background-color: transparent;
  color: ${colors.primary.regular};
  border: 2px solid ${colors.primary.regular};

  :hover {
    color: ${colors.primary.hover};
    border: 2px solid ${colors.primary.hover};
  }
`

const Primary = css`
  ${({ fill }) => fill === 'outline' ? POutline : PSolid}
`

// --- dark
const DkSolid = css`
  background-color: ${colors.primary.dark};
  color: ${colors.secondary.regular};

  :hover {
    background-color: ${colors.primary.regular};
  }
`
const DkOutline = css`
  background-color: transparent;
  color: ${colors.primary.dark};
  border: 2px solid ${colors.primary.dark};

  :hover {
    color: ${colors.primary.regular};
    border: 2px solid ${colors.primary.regular};
  }
`

const Dark = css`
  ${({ fill }) => fill === 'outline' ? DkOutline : DkSolid}
`

// -- secondary
const SSolid = css`
  background-color: ${colors.secondary.regular};
  color: ${colors.primary.regular};

  :hover {
    background-color: ${colors.secondary.hover};
  }
`
const SOutline = css`
  background-color: transparent;
  color: ${colors.secondary.regular};
  border: 2px solid ${colors.secondary.regular};

  :hover {
    color: ${colors.secondary.hover};
    border: 2px solid ${colors.secondary.hover};
  }
`
const Secondary = css`
  ${({ fill }) => fill === 'outline' ? SOutline : SSolid}
`

// -- success
const ScsSolid = css`
  background-color: ${colors.success.regular};
  color: ${colors.secondary.regular};

  :hover {
    background-color: ${colors.success.hover};
  }
`
const ScsOutline = css`
  background-color: transparent;
  color: ${colors.success.regular};
  border: 2px solid ${colors.success.regular};

  :hover {
    color: ${colors.success.hover};
    border: 2px solid ${colors.success.hover};
  }
`
const Success = css`
  ${({ fill }) => fill === 'outline' ? ScsOutline : ScsSolid}
`

// -- Danger
const DgSolid = css`
  background-color: ${colors.danger.regular};
  color: ${colors.secondary.regular};

  :hover {
    background-color: ${colors.danger.hover};
  }
`
const DgOutline = css`
  background-color: transparent;
  color: ${colors.danger.regular};
  border: 2px solid ${colors.danger.regular};

  :hover {
    color: ${colors.danger.hover};
    border: 2px solid ${colors.danger.hover};
  }
`
const Danger = css`
  ${({ fill }) => fill === 'outline' ? DgOutline : DgSolid}
`

// -- warning
const WSolid = css`
  background-color: ${colors.warning.regular};
  color: ${colors.grays[90]};

  :hover {
    background-color: ${colors.warning.hover};
  }
`
const WOutline = css`
  background-color: transparent;
  color: ${colors.warning.regular};
  border: 2px solid ${colors.warning.regular};

  :hover {
    color: ${colors.warning.hover};
    border: 2px solid ${colors.warning.hover};
  }
`
const Warning = css`
  ${({ fill }) => fill === 'outline' ? WOutline : WSolid}

  background-color: ${colors.warning};
  color: ${colors.secondary.regular};
`

// -- info
const ISolid = css`
  background-color: ${colors.info.regular};
  color: ${colors.secondary.regular};

  :hover {
    background-color: ${colors.info.hover};
  }
`
const IOutline = css`
  background-color: transparent;
  color: ${colors.info.regular};
  border: 2px solid ${colors.info.regular};

  :hover {
    color: ${colors.danger.hover};
    border: 2px solid ${colors.info.hover};
  }
`
const Info = css`
  ${({ fill }) => fill === 'outline' ? IOutline : ISolid}
`

// ---------------
// SIZES
const Small = css`
  min-width: ${spacing.twelveAndAHalf};
  height: ${spacing.three};
`

const Medium = css`
  min-width: 144px;
  height: ${spacing.fourAndAHalf};

`

const Large = css`
  min-width: 192px;
  height: ${spacing.six};
`

// STYLED BUTTON
export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: ${props => props.margin ? props.margin : '0px'};
  > p {
    margin: 0;
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return Small
      case 'large':
        return Large
      default:
        return Medium
    }
  }};
  ${({ block }) => block ? 'width: 100%' : ''};

  border: none;
  cursor: pointer;

  text-decoration: none;
  font-weight: bold;

  ${({ color }) => {
    switch (color) {
      case 'info':
        return Info
      case 'warning':
        return Warning
      case 'danger':
        return Danger
      case 'success':
        return Success
      case 'secondary':
        return Secondary
      case 'dark':
        return Dark
      default:
        return Primary
    }
  }};

  ${({ disabled }) => disabled && `background-color: ${colors.grays[40]};`}


  border-radius: ${({ shape }) => shape === 'square' ? 'none' : '8px'};

  > :not(:last-child) {
    margin-right: ${spacing.one};
  }

   :hover {
    ${({ color, disabled }) => {
      if (disabled) return 'background-color: grey !important;'
      else return null
    }};
  }

  :focus, :active, :focus-visible {
    outline: 0 !important;
  }
`
