import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { INPUT_TYPES } from 'Global/constants'
import { message } from 'Messages/factory'
import { checkField } from 'Utils/checks'

import { Input } from 'Components/Input'
import Button from 'Components/Button'

import SELECT from './Redux/selectors'
import { FIELD_ACTIONS } from './Redux/actions'

const Info = ({ next, robot, captcha }) => {
  const dispatch = useDispatch()

  const ci = useSelector(SELECT.CI)
  const firstName = useSelector(SELECT.FIRST_NAME)
  const lastName = useSelector(SELECT.LAST_NAME)
  const birthDate = useSelector(SELECT.BIRTH_DATE)
  const email = useSelector(SELECT.EMAIL)
  const cellphone = useSelector(SELECT.CELLPHONE)
  const phone = useSelector(SELECT.PHONE)

  const onChange = ({ value, name }) => {
    dispatch(FIELD_ACTIONS.CHANGE({ value, name }))
  }

  const validate = () => {
    const err = []
    let params

    params = { type: INPUT_TYPES.CI, value: ci, required: true }
    if (!checkField(params)) err.push('Cédula de identidad')

    params = { type: INPUT_TYPES.NAME, value: firstName, required: true }
    if (!checkField(params)) err.push('Nombre')

    params = { type: INPUT_TYPES.NAME, value: lastName, required: true }
    if (!checkField(params)) err.push('Apellido')

    params = { type: INPUT_TYPES.DATE, value: birthDate, required: true }
    if (!checkField(params)) err.push('Fecha de nacimiento')

    params = { type: INPUT_TYPES.EMAIL, value: email, required: true }
    if (!checkField(params)) err.push('E-mail')

    params = { type: INPUT_TYPES.CELLPHONE, value: cellphone, required: true }
    if (!checkField(params)) err.push('Teléfono celular')

    params = { type: INPUT_TYPES.TEL, value: phone, required: false }
    if (!checkField(params, true)) err.push('Teléfono')

    const text = err.join(', ')

    if (!text) return true

    message.warning({
      message: `Los siguientes campos son inválidos: ${text}`,
      time: 8000
    })

    return false
  }

  const handleNext = () => {
    if (validate()) next()
  }

  return (
    <>
      <Input
        name='ci'
        value={ci}
        label='Cédula de identidad'
        type={INPUT_TYPES.CI}
        onChange={onChange}
        required
      />

      <Input
        name='firstName'
        value={firstName}
        label='Nombre'
        type={INPUT_TYPES.NAME}
        onChange={onChange}
        required
      />

      <Input
        name='lastName'
        value={lastName}
        label='Apellido'
        type={INPUT_TYPES.NAME}
        onChange={onChange}
        required
      />

      <Input
        name='birthDate'
        value={birthDate}
        label='Fecha de nacimiento'
        type={INPUT_TYPES.DATE}
        onChange={onChange}
        required
      />

      <Input
        name='email'
        value={email}
        label='E-mail'
        type={INPUT_TYPES.EMAIL}
        onChange={onChange}
        required
      />

      <Input
        name='cellphone'
        value={cellphone}
        label='Teléfono celular'
        type={INPUT_TYPES.CELLPHONE}
        onChange={onChange}
        required
      />

      <Input
        name='phone'
        value={phone}
        label='Teléfono'
        type={INPUT_TYPES.TEL}
        onChange={onChange}
      />

      {captcha}
      <Button
        size='large'
        onClick={handleNext}
        disabled={!robot}
      >
        Seguir
      </Button>
    </>
  )
}

Info.propTypes = {
  next: PropTypes.func
}

export default Info
