import styled, { css } from "styled-components"
import { BREAKPOINTS } from "Global/constants"
export const ExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`
export const Container = styled.div`
  width: 100%;
  margin-top: 0;
  height: ${({ device }) => (device === BREAKPOINTS.MOBILE ? "750px" : "100%")};
`
const mobile = css`
  background-position: top;
  background-size: auto;
`
const desktop = css`
  background-position: center center;
  background-size: cover;
`
export const SliderContainer = styled.div`
  height: 500px;
  background-image: url(${(props) => props.image});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  ${({ device }) => (device === BREAKPOINTS.MOBILE ? mobile : desktop)};
`
export const Image = styled.img`
  height: auto;
  width: 100%;
`
