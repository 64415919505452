import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 15;
  top: 0;

  left: ${({ showing }) => showing ? '0' : '100%'};
  transition: ${({ showing }) => showing ? 'left 0.2s' : ''};

  overflow-x: hidden;
  background-color: ${colors.background.gray};
`

export const Bar = styled.div`
  position: fixed;
  z-index: 10;

  width: 100%;
  height: ${spacing.five};
  padding: ${spacing.half} ${spacing.oneAndAHalf};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: ${colors.secondary.regular};
  background-color: ${colors.primary.dark};
`

export const Image = styled.img`
  height: 100%;
  color: red;
  cursor: pointer;
`

export const Hamburger = styled.div`
  width: ${spacing.five};
  height: ${spacing.five};

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  > :first-child {
    height: 70%;
    width: auto;
  }
`
