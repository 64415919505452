import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Wrapper = styled.div`
  width: 100%;

  padding: 0 ${({ device }) => device === BREAKPOINTS.MOBILE ? spacing.one : spacing.eight};
  margin-bottom: ${spacing.five};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${family.barlow};
  color: ${colors.primary.dark};
`

export const Title = styled.p`
  ${weight.bold};
  font-size: 23pt;
  align-self: flex-start;
`

export const Block = styled.div`
  width: 100%;
  height: auto;

  margin-bottom: ${spacing.two};

  display: flex;
  justify-content: flex-start;
  align-self: flex-start;

  padding: 0 ${({ device }) => device === BREAKPOINTS.MOBILE ? 0 : spacing.five};
`

export const Icon = styled.div`
  width: ${spacing.threeAndAHalf};
  height: ${spacing.threeAndAHalf};

  margin-right: ${spacing.two};

  > :first-child {
    height: 100%;
    width: 100%;
  }
`

export const Container = styled.div`
  width: 100%;

  p {
    margin: 0;
    font-size: 14pt;
    text-align: justify;
  }

`
