import React from 'react'
import { Wrapper, Arrow, Text } from './styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useWindowDimensions } from 'Components/WindowDimensions'
export const Item = ({ text }) => {
  const device = useWindowDimensions()
  return (
    <Wrapper device={device}>
      <Arrow device={device}><FontAwesomeIcon icon={faChevronRight} /></Arrow>
      <Text device={device}>{text.toString()}</Text>
    </Wrapper>)
}
export default Item
