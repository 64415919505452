import styled, { css } from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

import RButton from 'Components/Button'

export const Img = styled.div`

  width: 100%;
  height: 0;
  padding-top: 37%;
  position: relative;

  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
`

const Gradient = css`
  background: ${({ direction, colors }) => {
    const dir = 'to ' + direction
    const steps = colors.join(', ')

    return 'linear-gradient(' + dir + ', ' + steps + ');'
  }};
`

export const Content = styled.div`
    width: 100%;
    height: 100%;

    ${({ gradient }) => gradient ? Gradient : ''}

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    position: absolute;
    top: 0;
    left: 0;

    padding: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return '0'
        case BREAKPOINTS.TABLET: return spacing.five
        default: return spacing.eight
      }
    }} ${({ device }) => device !== BREAKPOINTS.MOBILE ? spacing.eight : '0'} 0;

    > p {
      color: ${({ color }) => {
        switch (color) {
          case 'secondary': return colors.secondary.regular
          default: return colors.primary.dark
        }
      }};

      ${family.barlow};
      ${weight.bold};

      margin: 0;
    }
`

export const Text = styled.p`
  font-size: 16pt
`
export const Button = styled(RButton)`
  margin-top: ${spacing.three}
`
