export const family = {
  openSans: 'font-family: \'Open Sans\', sans-serif',
  barlow: 'font-family: \'Barlow\', sans-serif'
}

export const weight = {
  light: 'font-weight: 300',
  regular: 'font-weight: 400',
  semiBold: 'font-weight: 600',
  bold: 'font-weight: 700'
}

export const size = {
  eight: '8pt',
  ten: '10pt',
  twelve: '12pt',
  fourteen: '14pt',
  fifteen: '15pt',
  twentyFour: '24pt'
}
