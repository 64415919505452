import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BREAKPOINTS } from 'Global/constants'
import { colors, spacing } from 'Global/theme'

export const Overlay = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  background-color: ${colors.primary.dark70};
  overflow: hidden;
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  overflow: auto;
  outline: 0;
`

export const Area = styled.div`
  top: 10%;
  margin: auto;
  width: 600px;
  max-width: 90%;
  height: auto;
  max-height: 800px;

  border-radius: 20px;
  padding: ${spacing.twoAndAHalf} ${spacing.three};

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `${spacing.six} 0 ${spacing.twoAndAHalf}`
      case BREAKPOINTS.TABLET: return `${spacing.six} ${spacing.three} ${spacing.twoAndAHalf}`
      default: return `${spacing.twoAndAHalf} ${spacing.three}`
    }
  }};


  position: relative;
  display: flex;
  flex-direction: column;

  background-color: ${colors.background.white};
  z-index: 12;

  transform: height 1s;
`

export const Close = styled(FontAwesomeIcon)`
  position: absolute;
  top: ${spacing.twoAndAHalf};
  right: ${spacing.three};
  align-self: flex-end;
  color: ${colors.primary.dark};
  cursor: pointer;
`
