import styled, { css } from 'styled-components'

import { colors, spacing } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'
export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width:  ${({ device }) => device === BREAKPOINTS.DESKTOP ? '100%' : '70%'};

  > :not(:last-child) {
    margin-right: ${spacing.one};
  }
  ${props => props.centered ? centeredContent : null};
`
const centeredContent = css`
  justify-content: flex-start;
  align-items: center;
`

export const Icon = styled.div`
  position: relative;
  width: ${spacing.three};
  height: ${spacing.three};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${spacing.one};

  color: ${({ included }) => included ? colors.primary.regular : colors.grays[20]};

  > :first-child {
    height: 100%;
    width: 100%;
  }

  .tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 9;
    background-color: ${colors.primary.dark};
    border-radius: 6px;
    color: ${colors.secondary.regular};
    padding: ${spacing.half} ${spacing.one};
    bottom: 150%;
    white-space: nowrap;


    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: ${colors.primary.dark} transparent transparent transparent;
      top: 100%;
      left: calc(50% - 10px);
    }
  }

  &:hover .tooltip {
    visibility: visible;
  }
`
