export const key = 'global'

export const PRODUCTS = {
  LOAD: 'GLOBAL/PRODUCTS/LOAD',
  SUCCESS: 'GLOBAL/PRODUCTS/SUCCESS',
  ERROR: 'GLOBAL/PRODUCTS/ERROR',
  SELECT: 'GLOBAL/PRODUCTS/SELECTED',
  CLEAR: 'GLOBAL/PRODUCTS/CLEAR'
}

export const PROVIDERS = {
  LOAD: 'GLOBAL/PROVIDERS/LOAD',
  SUCCESS: 'GLOBAL/PROVIDERS/SUCCESS',
  ERROR: 'GLOBAL/PROVIDERS/ERROR',
  CLEAR: 'GLOBAL/PROVIDERS/CLEAR'
}

export const MODAL = {
  OPEN: 'GLOBAL/MODAL/OPEN',
  CLOSE: 'GLOBAL/MODAL/CLOSE',
  TOGGLE: 'GLOBAL/MODAL/TOGGLE'
}

export default key
