import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Block } from './style'

const Payment = ({ payments, symbol }) => (
  <Wrapper>
    {payments.map(({ shareQuantity, shareAmount }) => {
      const num = Number(shareAmount) / Number(shareQuantity)
      if (!num) return null

      const money = Math.floor(num)

      return (
        <Block key={shareQuantity}>
          <p className='quota'>
            {shareQuantity} {shareQuantity > 1 ? 'cuotas de' : 'cuota de'}
          </p>
          <p className='fee'>
            {symbol}{money.toLocaleString('de-DE')}
          </p>
        </Block>
      )
    })}
  </Wrapper>
)

Payment.propTypes = {
  symbol: PropTypes.string
}

export default Payment
