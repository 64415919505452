import produce from 'immer'

import { FIELDS, CONTACT } from './types'

export const initialState = {
  name: '',
  email: '',
  tel: '',
  message: '',
  sending: false,
  error: false
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // FIELDS
      case FIELDS.SAVE:
        draft[payload.name] = payload.value
        break

      case FIELDS.CLEAR:
        draft.name = ''
        draft.email = ''
        draft.tel = ''
        draft.message = ''
        break

      // CONTACT
      case CONTACT.SEND:
        draft.sending = true
        draft.error = false
        break

      case CONTACT.SUCCESS:
        draft.sending = false
        draft.error = false
        draft.name = ''
        draft.email = ''
        draft.tel = ''
        draft.message = ''
        break

      case CONTACT.ERROR:
        draft.sending = false
        draft.error = payload
        break

      default:
        break
    }
  })

export default reducer
