import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import { Wrapper, StyledLabel } from '../style'

import {
  Menu,
  Option,
  Control,
  Indicator,
  Placeholder,
  SingleValue,
  StyledSelect,
  ValueContainer,
  StyledAsyncSelect
} from './style'

const DropdownIndicator = (props) => (
  <Indicator {...props}>
    <FontAwesomeIcon icon={faSort} />
  </Indicator>
)

const Select = ({ name, label, options, value, placeholder, onChange, disabled, isLoading, required, formValid, asyncLoad }) => {
  const handleChange = value => {
    onChange({ name, value })
  }

  const CustomControl = props => (
    <Control {...props} valid={formValid} />
  )

  const renderSelect = () => (
    <StyledSelect
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      components={{
        IndicatorSeparator: null,
        Control: CustomControl,
        DropdownIndicator,
        ValueContainer,
        SingleValue,
        Placeholder,
        Option,
        Menu
      }}
      isDisabled={disabled}
      isLoading={isLoading}

      blurInputOnSelect

      valid={formValid}
    />
  )

  const renderAsyncSelect = () => (
    <StyledAsyncSelect
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      components={{
        IndicatorSeparator: null,
        Control: CustomControl,
        DropdownIndicator,
        ValueContainer,
        SingleValue,
        Placeholder,
        Option,
        Menu
      }}
      loadOptions={asyncLoad}
      isDisabled={disabled}
      isLoading={isLoading}

      blurInputOnSelect

      valid={formValid}
    />
  )

  return (
    <Wrapper>
      <StyledLabel
        htmlFor={name}
        valid={formValid}
        disabled={disabled}
      >
        {label}
        {required && <span> *</span>}
      </StyledLabel>

      {!asyncLoad ? renderSelect() : renderAsyncSelect()}

    </Wrapper>
  )
}

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  required: PropTypes.bool,
  formValid: PropTypes.bool,
  asyncLoad: PropTypes.func
}

export default Select
