import { PRODUCTS, PROVIDERS, MODAL } from './types'

/**
 *
 * PRODUCTS
 */
export const getProducts = () => ({
  type: PRODUCTS.LOAD
})

export const getProductsSuccess = payload => ({
  type: PRODUCTS.SUCCESS,
  payload
})

export const getProductsError = payload => ({
  type: PRODUCTS.ERROR,
  payload
})

export const selectProduct = payload => ({
  type: PRODUCTS.SELECT,
  payload
})

export const clearProducts = () => ({
  type: PRODUCTS.CLEAR
})

export const PRODUCT_ACTIONS = {
  LOAD: getProducts,
  SUCCESS: getProductsSuccess,
  ERROR: getProductsError,
  SELECT: selectProduct,
  CLEAR: clearProducts
}

/**
 *
 * PRODUCTS
 */
export const getProviders = () => ({
  type: PROVIDERS.LOAD
})

export const getProvidersSuccess = payload => ({
  type: PROVIDERS.SUCCESS,
  payload
})

export const getProvidersError = payload => ({
  type: PROVIDERS.ERROR,
  payload
})

export const clearProviders = () => ({
  type: PROVIDERS.CLEAR
})

export const PROVIDER_ACTIONS = {
  LOAD: getProviders,
  SUCCESS: getProvidersSuccess,
  ERROR: getProvidersError,
  CLEAR: clearProviders
}

/**
 *
 * MODAL
 */
export const openModal = () => ({
  type: MODAL.OPEN
})

export const closeModal = () => ({
  type: MODAL.CLOSE
})

export const toggleModal = () => ({
  type: MODAL.TOGGLE
})

export const MODAL_ACTIONS = {
  OPEN: openModal,
  CLOSE: closeModal,
  TOGLE: toggleModal
}
