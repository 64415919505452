import styled from 'styled-components'

import { spacing } from 'Global/theme'
import { isMobile } from 'react-device-detect'
export const Vertical = styled.div`
  width: 100%;
  height: auto;
  padding: ${spacing.nine} 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Container = styled.div`
  width: 90%;
  height: auto;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-top: ${spacing.sevenAndAHalf};
  flex-direction: ${isMobile ? 'column' : 'row'};
  justify-content: ${isMobile ? 'center' : 'space-around'};
  img {
    align-self:center;
  }
`

export const Image = styled.img`
  width:200px;
  align-self: flex-start;
  height: 200px;
  flex: 0;
  object-fit: scale-down;
  margin: 10px;
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
`
