export const key = 'certifications'

export const POLICY = {
  GET: 'CERTS/POLICY/GET',
  SUCCESS: 'CERTS/POLICY/GET_SUCCESS',
  ERROR: 'CERTS/POLICY/GET_ERROR',
  REFERENCE: 'CERTS/POLICY/REFERENCE',
  CODE: 'CERTS/POLICY/CODE',
  CLEAR: 'CERTS/POLICY/CLEAR'
}

export const CERTS = {
  GET: 'CERTS/CERTS/GET',
  SUCCESS: 'CERTS/CERTS/GET_SUCCESSS',
  ERROR: 'CERTS/CERTS/GET_ERROR'
}

export default key
