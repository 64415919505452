import React from 'react'
import PropTypes from 'prop-types'

import Loading from 'Components/Loading'

import { Wrapper, Image } from './style'

const ProviderLoader = ({ img }) => {
  return (
    <Wrapper>
      <Image>
        <img src={img} alt='loading' />
      </Image>

      <Loading type='dots' />
    </Wrapper>
  )
}

ProviderLoader.propTypes = {
  img: PropTypes.string
}

export default ProviderLoader
