import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { RATES_FORM } from 'Global/paths'
import { WAITING_MESSAGES } from 'Global/messages'
import { COVERAGE, BREAKPOINTS } from 'Global/constants'

import { useWindowDimensions } from 'Components/WindowDimensions'

import GLOBAL_SELECTOR from 'Redux/Global/selectors'
import { openModal, closeModal } from 'Redux/Global/actions'
import { selectQuery } from 'Routes/Redux/selectors'

import InsuranceCard from 'Components/InsuranceCard'

import useRedux from './Redux'
import SELECTORS from './Redux/selectors'
import { RATE_ACTIONS, PROVIDER_RATE_ACTIONS } from './Redux/actions'

import Page from 'Components/Page'
import Modal from 'Components/Modal'
import Button from 'Components/Button'
import Loading from 'Components/Loading'
import UserRegistry from 'Components/UserRegistry'
import EmailSender from 'Components/EmailSender'
import InfoCard from './Components/InfoCard'
import ProviderLoader from './Components/ProviderLoader'
import NoRates from './Components/NoProviderRate'

import {
  Disclaimer,
  ProvidersWrapper,
  CreationDate,
  Menu,
  EmailText
} from './style'

import Dropdown from 'Components/Input/Dropdown'

const ORDER = {
  PRICE_LOW: 'price_low',
  PRICE_HIGH: 'price_high',
  BENEFITS_HIGH: 'benefits_high',
  BENEFITS_LOW: 'benefits_low'
}

const dropdownOrder = {
  title: 'Ordenar',
  options: [
    { value: ORDER.PRICE_LOW, label: 'Menor precio' },
    { value: ORDER.PRICE_HIGH, label: 'Mayor precio' },
    { value: ORDER.BENEFITS_HIGH, label: 'Más beneficios' },
    { value: ORDER.BENEFITS_LOW, label: 'Menos beneficios' }
  ]
}
const disclaimer = 'Las cotizaciones que puede ver en esta sección son anónimas, el costo final puede diferir en caso de que hayas tenido un historial de siniestralidad en la compañía seleccionada. En caso de que eso suceda te alertaremos antes de contratar el seguro.'
const Quote = () => {
  const device = useWindowDimensions()

  useRedux()
  const dispatch = useDispatch()

  const history = useHistory()

  const [isShowingEmail, setShowingEmail] = useState(false)
  const [filtered, setFiltered] = useState([])
  const [type, setType] = useState(COVERAGE.TOTAL)
  const [order, setOrder] = useState(0)

  const [includes, setIncludes] = useState('')

  const [providersLoading, setProvidersLoading] = useState([])
  const [noRates, setNoRates] = useState([])

  const { quickRateId } = useSelector(selectQuery)

  const rates = useSelector(SELECTORS.RATES)
  const loading = useSelector(SELECTORS.LOADING)
  const coverageCategories = useSelector(SELECTORS.COVERAGE_CATEGORIES)
  const pendingProviders = useSelector(SELECTORS.PENDING_PROVIDERS)
  const creationDate = useSelector(SELECTORS.CREATION_DATE)

  const [rateId, setRateId] = useState(-1)
  const [providerId, setProviderId] = useState(-1)

  const allProviders = useSelector(GLOBAL_SELECTOR.PROVIDERS)
  const isShowing = useSelector(GLOBAL_SELECTOR.MODAL_SHOWING)
  const showModal = () => dispatch(openModal())
  const hideModal = () => dispatch(closeModal())
  const onSuccess = () => {
    hideModal()
  }
  useEffect(() => {
    if (allProviders.length) {
      dispatch(RATE_ACTIONS.CLEAR())
      dispatch(
        RATE_ACTIONS.LOAD(allProviders.map(({ id }) => ({ provider: id })))
      )
    }

    return () => {
      dispatch(PROVIDER_RATE_ACTIONS.STOP())
    }
  }, [dispatch, allProviders])
  const handleOpenModalEmail = () => {
    setShowingEmail(true)
    showModal()
  }
  const handleCloseModalEmail = () => {
    setShowingEmail(false)
  }
  /** Filters the list to show whenever the rates change (added new ones) or the category canges */
  useEffect(() => {
    let ret = rates.filter((rate) => {
      const { providerCoverageType } = rate
      const category = coverageCategories.find((c) => c.internalName === type.internalName)
      if (!category) return

      const f = category.providerCoverageTypes.find(
        ({ description }) => description === providerCoverageType
      )

      return !!f
    })
    const temp = JSON.parse(JSON.stringify(ret))
    ret = temp
    const sortIt = (a, b) => (a === b ? 0 : a < b ? -1 : 1)
    const sortByBenefits = (a, b) => {
      const includedBenefitsA = a.filter((benefit) => benefit.isIncluded)
        .length
      const includedBenefitsB = b.filter((benefit) => benefit.isIncluded)
        .length
      if (includedBenefitsA > includedBenefitsB) {
        return 1
      } else if (includedBenefitsA < includedBenefitsB) {
        return -1
      } else {
        return 0
      }
    }
    if (order !== 0) {
      ret = ret.sort((a, b) => {
        switch (order) {
          case ORDER.PRICE_LOW:
            return sortIt(a.payments[0].shareAmount, b.payments[0].shareAmount)
          case ORDER.PRICE_HIGH:
            return sortIt(b.payments[0].shareAmount, a.payments[0].shareAmount)
          case ORDER.BENEFITS_LOW:
            return sortByBenefits(a.metadata, b.metadata)
          case ORDER.BENEFITS_HIGH:
            return sortByBenefits(b.metadata, a.metadata)
          default:
            return 0
        }
      })
    }

    setFiltered(ret)
  }, [type, order, rates, coverageCategories])

  /** Gets no rate providers */
  useEffect(() => {
    if (allProviders.length) {
      const nr = allProviders
        .filter(({ id }) => !providersLoading.find((p) => p.id === id))
        .filter(
          ({ id }) => !filtered.find(({ provider }) => provider.id === id)
        )
        .map(({ id, company }) => ({
          id,
          name: company.name,
          img: company.publicLogoUrl
        }))

      setNoRates(nr)
    }
  }, [filtered, providersLoading, allProviders])

  useEffect(() => {
    const category = coverageCategories.find((c) => c.description === type)
    if (!category) return

    const { includeDescription } = category

    setIncludes(includeDescription)
  }, [type, coverageCategories])

  /** Loading providers */
  useEffect(() => {
    const loading = allProviders.filter(
      ({ id }) => !!pendingProviders.find(({ provider }) => id === provider)
    )
    const mapped = loading.map(({ id, company }) => ({
      id,
      name: company.name,
      img: company.publicLogoUrl
    }))

    setProvidersLoading(mapped)
  }, [allProviders, pendingProviders])

  const handleContract = ({ providerId, rateId }) => {
    setRateId(rateId)
    setProviderId(providerId)
    showModal()
  }

  const registryCancel = () => {
    setRateId(-1)
    setProviderId(-1)
    hideModal()
    handleCloseModalEmail()
  }

  const registryDone = (insuranceClientId) => {
    hideModal()
    dispatch(RATE_ACTIONS.SELECT(rateId))
    dispatch(RATE_ACTIONS.CREATE({ insuranceClientId, rateId, providerId }))
  }

  const formatDate = () => {
    const date = new Date(creationDate)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
  }

  const goBack = () => {
    history.push({
      pathname: RATES_FORM,
      search: `?quickRateId=${quickRateId}`
    })
  }

  return (
    <Page>
      {!allProviders.length && (
        <Loading overlay message={WAITING_MESSAGES.PROVIDERS} />
      )}

      <Menu device={device}>
        <Button color='dark' fill='outline' onClick={goBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
          {device !== BREAKPOINTS.MOBILE && 'Volver'}
        </Button>

        <div className='order'>
          {device !== BREAKPOINTS.MOBILE && <span>Ordenar por:</span>}
          <Dropdown
            title={dropdownOrder.title}
            options={dropdownOrder.options}
            onSelect={setOrder}
          />
        </div>
      </Menu>

      {creationDate && (
        <CreationDate>
          {`Cotizaciónes creadas el día: ${formatDate()}`}
        </CreationDate>
      )}

      <InfoCard
        type={type}
        changeType={setType}
        includes={includes}
        handleEdit={goBack}
      />
      <Disclaimer>
        <div>
          <span className='info-icon'>
            <FontAwesomeIcon icon={faInfoCircle} />{' '}
          </span>
        </div>
        <div>
          <p>{disclaimer}</p>
        </div>
      </Disclaimer>
      {!!providersLoading.length && (
        <ProvidersWrapper device={device}>
          {providersLoading.map(({ id, img }) => (
            <ProviderLoader device={device} key={id} img={img} />
          ))}
        </ProvidersWrapper>
      )}

      {filtered.map((rate, i) => (
        <InsuranceCard key={i} data={rate} handleContract={handleContract} />
      ))}

      {noRates.map(({ id, img, name }) => (
        <NoRates key={id} img={img} name={name} />
      ))}

      {loading && rateId !== -1 && (
        <Loading overlay message={WAITING_MESSAGES.QUOTE} />
      )}

      {filtered.length > 0 && (
        <>
          <EmailText onClick={handleOpenModalEmail}>
            ¿Quieres que te enviemos esta cotización por correo electrónico?
          </EmailText>
          <Button onClick={handleOpenModalEmail} margin='5px 0 20px 0'>
            Enviar cotización
          </Button>
        </>
      )}
      <Modal isShowing={isShowing} hide={registryCancel}>
        {isShowing && !isShowingEmail && (
          <UserRegistry providerId={providerId} done={registryDone} />
        )}
        {isShowingEmail && (
          <EmailSender onSuccess={onSuccess} quickRateId={quickRateId} />
        )}
      </Modal>
    </Page>
  )
}

export default Quote
