import React from 'react'
import PropTypes from 'prop-types'

import { StyledButton } from './style'

const Button = ({ children, onClick, ...props }) => (
  <StyledButton
    type='button'
    onClick={onClick}
    {...props}
  >
    {children}
  </StyledButton>
)

Button.propTypes = {
  onClick: PropTypes.func,
  block: PropTypes.bool,
  shape: PropTypes.oneOf([
    'round',
    'square'
  ]),
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ]),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark'
  ]),
  fill: PropTypes.oneOf([
    'solid',
    'outline'
  ]),
  children: PropTypes.any
}

export default Button
