import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons'

import { Body, Label, Options, Block, Icon } from './style'

const Checkbox = ({ name, label, options, value, required, onChange }) => (
  <Body>
    <Label>{label + (required ? '*' : '')}</Label>

    <Options>
      {options.map(prod => (
        <Block key={prod.id} onClick={() => onChange({ name, value: prod.id })}>
          <Icon>
            <FontAwesomeIcon icon={prod.id === value ? faDotCircle : faCircle} />
          </Icon>

          <p>{prod.name}</p>
        </Block>
      ))}
    </Options>

  </Body>
)

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func
}

export default Checkbox
