import { DYNAMIC_FIELD_TYPES, INPUT_TYPES } from 'Global/constants'

const getInputType = fieldType => {
  switch (fieldType) {
    case DYNAMIC_FIELD_TYPES.NUMERIC: return INPUT_TYPES.NUMBER
    case DYNAMIC_FIELD_TYPES.TEXT: return INPUT_TYPES.TEXT
    case DYNAMIC_FIELD_TYPES.BOOLEAN: return INPUT_TYPES.BOOLEAN
    case DYNAMIC_FIELD_TYPES.SELECT: return INPUT_TYPES.SELECT
    case DYNAMIC_FIELD_TYPES.ALPHANUMERIC: return INPUT_TYPES.TEXT
    case DYNAMIC_FIELD_TYPES.DATE: return INPUT_TYPES.DATE
    case DYNAMIC_FIELD_TYPES.EMAIL: return INPUT_TYPES.EMAIL
    default: return null
  }
}

export const formatData = payload => {
  payload = payload || {}
  const { commonFields, agentFields, specificFields } = payload

  if (!commonFields && !agentFields && !specificFields) {
    return { specificFields: formatSection(payload) }
  }

  return {
    agentFields: formatSection(agentFields),
    commonFields: formatSection(commonFields),
    specificFields: formatSection(specificFields)
  }
}

const formatSection = section => {
  if (!section || !section.length) return null

  const data = {}
  const wholeOrder = []

  section.forEach(group => {
    const { id, order, fieldType, childs } = group

    data[id] = formatField(group)

    const children = []
    childs.forEach(child => {
      const { id, fieldType, order } = child

      data[child.id] = formatField(child)
      children.push({
        id,
        order,
        fieldType: fieldType.id
      })
    })

    children.sort((a, b) => a.order - b.order)

    const block = {
      id,
      order,
      children,
      fieldType: fieldType.id
    }

    wholeOrder.push(block)
  })
  wholeOrder.sort((a, b) => a.order - b.order)
  return { data, order: wholeOrder }
}

const formatField = field => {
  const {
    id,
    order,
    label,
    parent,
    options,
    required,
    dependsOn,
    fieldName,
    fieldType,
    optionsUrl,
    highlightedField,
    translationAvailable
  } = field

  const formatted = {
    id,
    label,
    order,
    parent,
    required,
    fieldName,
    highlightedField,
    translationAvailable,
    value: '',
    error: '',
    loading: false,
    fieldType: fieldType.id,
    type: getInputType(fieldType.id),
    options: options ? options.map(o => ({ ...o, label: o.description, value: o.description })) : [],
    optionsUrl: optionsUrl ? optionsUrl.replace(/^(https?):\/\/.*:[0-9]+/, '') : null,
    dependsOn: dependsOn ? dependsOn.split(',').map(Number) : []
  }
  formatted.disabled = !!formatted.dependsOn.length

  if (formatted.fieldType === DYNAMIC_FIELD_TYPES.BOOLEAN) {
    formatted.options = [
      { id: 0, value: false, label: 'NO' },
      { id: 1, value: true, label: 'SI' }
    ]
  }

  return formatted
}
