export const getSize = val => {
  if (isNaN(Number(val))) {
    return val
  } else {
    return val + 'px'
  }
}

export const replaceParams = (url, params) => {
  let ret = url
  const keys = Object.keys(params)
  keys.forEach(key => {
    ret = ret.replace('{' + key + '}', params[key])
  })

  return ret
}

export const formObjectValues = values => {
  const result = {}
  Object.keys(values).forEach(key => {
    const data = values[key]
    result[key] = data.reduce((obj, { id, value }) => {
      obj[id] = value
      return obj
    }, {})
  })

  return result
}

export const numberToString = number => number.toLocaleString('de-DE')
