import styled from 'styled-components'
import { Link as RLink } from 'react-router-dom'

import { BREAKPOINTS } from 'Global/constants'

import { coverBackground } from 'Utils/style'
import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.primary.regular};
  ${coverBackground(colors.primary.regular)};
  display: flex;
  justify-content: center;
  padding: 10px;
  align-content: center;
  align-items: center;
`

export const Title = styled.p`
  ${family.barlow};
  font-weight: bold;
  font-size: 18pt;
  margin: 0;
`

export const Column = styled.div`
  height: 100%;
  width: ${props => props.width || '50%'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${colors.secondary.regular};
  font-size: 10pt;
  padding: ${({ padding }) => padding || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};
`

export const LinkContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

`

export const Link = styled(RLink)`
  color: ${colors.secondary.regular};
  text-decoration: underline;
  font-size: 10pt;
`
