import React from 'react'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import {
  HOME,
  // HIRE,
  ABOUT_US,
  // SOA,
  CONTACT,
  PRIVACY
  // CANCEL,
  // GLOSSARY
} from 'Global/paths'

import {
  Link,
  Title,
  Column,
  Wrapper,
  LinkContainer
} from './style'
import { INSURANCES, FAQ } from '../../../../Global/paths'

const Info = () => {
  const device = useWindowDimensions()

  return (
    <Wrapper device={device}>
      <Column>
        <p>
          Nuestro sistema está conectado directamente con las
          <br />
          empresas de seguro, para la emisión inmediata de tu
          <br />
          póliza.
        </p>
        <p>
          Quedas asegurado de forma inmediata y recibes tu
          <br />
          Contrato y boleto de pago por mail.
        </p>
        <p>
          Atención personal: Lunes a Viernes de 10:00 a 18:30 hs.
        </p>
        <Link to={PRIVACY}>
          Política de Privacidad
        </Link>
      </Column>

      {device !== BREAKPOINTS.MOBILE && (
        <Column>
          <Title>NAVEGACIÓN</Title>
          <LinkContainer>
            <Link to={HOME}>
              Inicio
            </Link>
            <Link to={INSURANCES}>
              Seguros
            </Link>
            <Link to={ABOUT_US}>
              Nosotros
            </Link>
            <Link to={FAQ}>
              FAQ
            </Link>
            <Link to={CONTACT}>
              Contacto
            </Link>
          </LinkContainer>
        </Column>
      )}

    </Wrapper>
  )
}

export default Info
