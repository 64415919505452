import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const _selectEmail = state => get(state, key, initialState)

const selectEmail = createSelector(
  _selectEmail, state => get(state, 'email', '')
)
const selectSent = createSelector(
  _selectEmail, state => get(state, 'done', false)
)

const selectSending = createSelector(
  _selectEmail, state => get(state, 'sending', false)
)

const selectError = createSelector(
  _selectEmail, state => get(state, 'error', false)
)

export {
  selectEmail,
  selectSent,
  selectSending,
  selectError
}
