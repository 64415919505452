import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  > .DayPickerInput{
    width:100%;

    input {
      width: 100%;
      height: ${spacing.four};

      border: none;
      border-radius: 0px;
      border-bottom: solid thin ${({ valid }) => valid ? colors.grays[50] : colors.danger.regular};

      color: ${colors.primary.dark} !important;
      ${family.openSans};
      ${weight.bold};
      font-size: 12pt;

      appearance: none;
      -webkit-appearance: none;

      :invalid {
        box-shadow: none;
        border: none;
        border-bottom: solid thin ${colors.danger.regular};
      }

      &:hover, &:focus {
        outline: none;
      }
    }

    .DayPickerInput-Overlay {
      z-index: 10;
    }
  }
`
