import styled from 'styled-components'

import { spacing } from 'Global/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 95%;
  width: 100%;

  > :not(:first-child) {
    margin-top: ${spacing.twoAndAHalf};
  }

  margin-bottom: ${spacing.three};
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  h2, p {
    margin: 0;
  }
`

export const Check = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: ${spacing.two};
  }
`
