import styled, { css } from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

const topMobile = css`
  align-items: center;
  flex-direction: column;
`

export const TopContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90%;
  border-radius: 8px;
  border: solid thin ${colors.grays[10]};
  box-shadow: 0 0 2px 0 ${colors.grays[20]};
  ${props => props.device === BREAKPOINTS.MOBILE ? topMobile : null};
  justify-content: space-evenly;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10%;
`

export const Card = styled.div`
  width: 100%;
  max-width: 928px;
  height: auto;

  background-color: ${colors.background.white};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: ${spacing.twoAndAHalf} 0 ${spacing.five} 0;
`

export const CoverageBlock = styled.div`
  height: 100%;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  position: relative;

  padding: ${spacing.two} 0;

  > p {
    margin: 0;
    ${family.barlow};
    color: ${colors.primary.dark};
    font-size: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.DESKTOP:
          return '18pt'
        default:
          return '16pt'
      }
    }};
  }

  > img {
    max-width: 100%;
    margin-bottom: ${spacing.two};
  }
`

export const CoverageImg = styled.img`
  flex: 1;
  width: 75%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  overflow: hidden;
`

export const InfoBlock = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: ${props => props.device === BREAKPOINTS.MOBILE ? '80%' : '33%'};

  position: relative;

  padding: ${spacing.twoAndAHalf} ${spacing.one} ${spacing.twoAndAHalf} ${spacing.one};

  p {
    margin: 0;
  }

  & p.title {
    ${family.barlow};
    font-size: 12pt;
    color: ${colors.primary.dark};
    margin-bottom: ${spacing.one};
  }

  & p.text {
    ${family.openSans};
    font-size: 10pt;
    color: ${colors.primary.regular};
    align-self: flex-end;
    text-align: left;

    word-wrap: normal;
    word-break: break-all;
    width: 100%;
    align-self: flex-end;
    white-space: normal;
    display: block;
    height: auto;
    margin: 3px auto;
    line-height: 1.4;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`

const horizontal = css`
  width: 90%;
  align-self: center;
  height: 1px;
`
export const Line = styled.div`
  width: 1px;
  height: 200px;
  align-self: center;

  background-color: ${colors.grays[10]};
  ${(props) => (props.device === BREAKPOINTS.MOBILE ? horizontal : null)};
`

export const TagWrapper = styled.div`
  align-self: flex-start;
  ${props => props.margin ? `margin: ${props.margin};` : ''}
`

export const Title = styled.div`
  margin-top: 20px;
  font-weight: bold;
  color: #00725d;
  font-size: 20px;
  text-align: center;
`
