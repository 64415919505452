import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { weight } from 'Global/font'

export const Wrapper = styled.div`
  height: ${spacing.fourAndAHalf};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: ${spacing.one} ${spacing.one};
  border-radius: 0 0 8px 8px;

  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};

  background-color: ${({ color }) => {
    switch (color) {
      case 'secondary': return colors.secondary.regular
      default: return colors.primary.regular
    }
  }};

  color: ${({ color }) => {
    switch (color) {
      case 'secondary': return colors.primary.regular
      default: return colors.secondary.regular
    }
  }};

  box-shadow: 0 3px 6px ${colors.grays[20]};

  :hover {
    background-color: ${({ color }) => {
      switch (color) {
        case 'secondary': return colors.secondary.hover
        default: return colors.primary.hover
      }
    }};
  }

  > :first-child {
    margin-right: ${spacing.oneAndAHalf};
  }

  > p {
    margin: 0;
    font-size: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 11 : 8}pt;
    ${weight.bold};
  }
`
