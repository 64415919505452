import React from 'react'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from 'Global/constants'

import { Input, TextArea, Select } from 'Components/Input'

export const RenderInput = ({ field, valid, onChange }) => {
  // ALL INPUTS HAVE THIS PROPS
  const {
    id,
    type,
    label,
    value,
    disabled,
    required
  } = field

  // SELECT INPUT HAS THIS PROPS
  const {
    options,
    loading,
    asyncLoad
  } = field

  switch (type) {
    case INPUT_TYPES.TEXT:
    case INPUT_TYPES.NAME:
    case INPUT_TYPES.PASSWORD:
    case INPUT_TYPES.EMAIL:
    case INPUT_TYPES.TEL:
    case INPUT_TYPES.NUMBER:
    case INPUT_TYPES.CI:
    case INPUT_TYPES.DATE:
      return (
        <Input
          type={type}
          name={id + ''}
          label={label}
          value={value}
          formValid={valid}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
      )

    case INPUT_TYPES.BOOLEAN:
    case INPUT_TYPES.SELECT:
      return (
        <Select
          name={id + ''}
          label={label}
          value={value}
          formValid={valid}
          options={options}
          isLoading={loading}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
      )

    case INPUT_TYPES.ASYNC_SELECT:
      return (
        <Select
          name={id + ''}
          label={label}
          value={value}
          formValid={valid}
          isLoading={loading}
          disabled={disabled}
          required={required}
          asyncLoad={asyncLoad}
          onChange={onChange}
        />
      )

    case INPUT_TYPES.TEXT_AREA:
      return (
        <TextArea
          name={id + ''}
          label={label}
          value={value}
          disabled={disabled}
          required={required}
          onChange={onChange}
          formValid={valid}
        />
      )

    default:
      return null
  }
}

RenderInput.propTypes = {
  valid: PropTypes.bool,
  onChange: PropTypes.func,
  field: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    label: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    options: PropTypes.array,
    loading: PropTypes.bool,
    asyncLoad: PropTypes.func
  })
}

export default RenderInput
