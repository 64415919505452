import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectQuotes = state => get(state, key, initialState)

const selectError = createSelector(
  selectQuotes, state => get(state, 'error', false)
)

const selectLoading = createSelector(
  selectQuotes, state => get(state, 'loading', false)
)

const selectRates = createSelector(
  selectQuotes, state => get(state, 'rates', [])
)

const selectPendingProviders = createSelector(
  selectQuotes, state => get(state, 'pendingProviders', [])
)

const selectCanGetRates = createSelector(
  selectQuotes, state => get(state, 'canGetRates', false)
)

const selectQuickRateId = createSelector(
  selectQuotes, state => get(state, 'quickRateId', 0)
)

const selectCoverageCategories = createSelector(
  selectQuotes, state => get(state, 'coverageCategories', [])
)

const selectHightlights = createSelector(
  selectQuotes, state => get(state, 'highlights', [])
)

const selectSelected = createSelector(
  selectQuotes, state => get(state, 'selected', -1)
)

const selectCreationDate = createSelector(
  selectQuotes, state => get(state, 'creationDate', '')
)

const selectSelectedRate = createSelector(
  selectQuotes, state => {
    const selected = get(state, 'selected', -1)
    const rates = get(state, 'rates', [])
    return rates.find(({ rateId }) => rateId === selected)
  }
)

export default {
  ERROR: selectError,
  LOADING: selectLoading,
  RATES: selectRates,
  PENDING_PROVIDERS: selectPendingProviders,
  CANT_GET_RATES: selectCanGetRates,
  QUICK_RATE_ID: selectQuickRateId,
  COVERAGE_CATEGORIES: selectCoverageCategories,
  HIGHLIGHTS: selectHightlights,
  SELECTED: selectSelected,
  SELECTED_RATE: selectSelectedRate,
  CREATION_DATE: selectCreationDate
}
