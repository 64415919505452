import React from 'react'
import { Wrapper, Column, Link } from './styles'
import { useWindowDimensions } from 'Components/WindowDimensions'
import SocialNetworks from './SocialNetworks'
import {
  PRIVACY
} from 'Global/paths'
export const MobileFooter = () => {
  const device = useWindowDimensions()
  return (
    <Wrapper device={device}>
      <Column padding='0 0 0 20px' width='60%'>
        <p>
          0800 8904
          <br />
          099 072 440
          <br />
          2706 9238
          <br />
          <a href='mailto:info@segurosonline.com.uy' target='_blank' rel='noopener noreferrer'>info@segurosonline.com.uy</a>
        </p>
        <p>
          Francisco Soca 1573
          <br />
          Montevideo, Uruguay
        </p>
        <p>
          <Link to={PRIVACY}>
            Política de Privacidad
          </Link>
        </p>
      </Column>
      <Column marginLeft='10px' padding='0 0px 10px 0' width='40%'>
        <SocialNetworks />
      </Column>
    </Wrapper>
  )
}

export default MobileFooter
