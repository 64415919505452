export const colors = {
  primary: {
    regular: '#01826d',
    regular50: 'rgba(1, 130, 109, 0.5)',
    dark: '#005442',
    dark70: 'rgba(0, 84, 66, 0.7)',
    dark80: 'rgba(0, 84, 66, 0.8)',
    light: '#42BDA4',
    hover: '#008F77'
  },
  secondary: {
    regular: '#ffffff',
    hover: '#cccccc'
  },
  stared: {
    fill: '#fcc203',
    border: '#e9d521',
    shadow: 'rgba(196, 163, 30, 0.16)'
  },
  success: {
    regular: '#28a745',
    hover: '#218838'
  },
  danger: {
    regular: '#dc3545',
    hover: '#c82333'
  },
  warning: {
    regular: '#ffc107;',
    hover: '#e0a800'
  },
  info: {
    regular: '#17a2b8',
    hover: '#138496'
  },
  background: {
    white: '#ffffff',
    gray: '#F2F2F2',
    select: 'rgba(199, 199, 199, 0.3)'
  },
  grays: {
    0: '#ffffff',
    10: '#e6e6e6',
    20: '#cccccc',
    30: '#b3b3b3',
    40: '#999999',
    50: '#808080',
    60: '#666666',
    70: '#4d4d4d',
    80: '#333333',
    90: '#191919',
    100: '#000000'
  }
}
export const spacing = {
  quarter: '2px',
  half: '4px',
  one: '8px',
  oneAndAHalf: '12px',
  two: '16px',
  twoAndAHalf: '20px',
  three: '24px',
  threeAndAHalf: '28px',
  four: '32px',
  fourAndAHalf: '36px',
  five: '40px',
  fiveAndAHalf: '44px',
  six: '48px',
  sixAndAHalf: '52px',
  seven: '56px',
  sevenAndAHalf: '60px',
  eight: '64px',
  eightAndAHalf: '68px',
  nine: '72px',
  nineAndAHalf: '76px',
  ten: '80px',
  tenAndAHalf: '84px',
  eleven: '88px',
  elevenAndAHalf: '92px',
  twelve: '96px',
  twelveAndAHalf: '100px',
  thirteen: '104px',
  fourteen: '112x',
  fifteen: '120px'
}

export const page = {
  width: '1200px'
}
