import React from 'react'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from 'Global/constants'

import Form from 'Components/Form'

import { Wrapper } from './style'

import { SECTION } from '../../constants'

const data = {
  name: {
    id: 'name',
    name: 'name',
    label: 'Nombre',
    type: INPUT_TYPES.NAME,
    required: true,
    static: true,
    value: ''
  },
  email: {
    id: 'email',
    name: 'email',
    label: 'E-mail',
    type: INPUT_TYPES.EMAIL,
    required: true,
    static: true,
    value: ''
  },
  tel: {
    id: 'tel',
    name: 'tel',
    label: 'Teléfono',
    type: INPUT_TYPES.NUMBER,
    static: true,
    value: ''
  },
  message: {
    id: 'message',
    name: 'message',
    label: 'Mensaje',
    type: INPUT_TYPES.TEXT_AREA,
    required: true,
    static: true,
    value: ''
  }
}

const order = [
  { id: 'name', order: 1 },
  { id: 'email', order: 2 },
  { id: 'tel', order: 3 },
  { id: 'message', order: 4 }
]

const form = {
  actives: [SECTION],
  [SECTION]: { data, order }
}

const ContactForm = ({ handleSubmit }) => (
  <Wrapper>
    <Form
      submit={handleSubmit}
      buttonText='Enviar'
      rawForms={form}
    />
  </Wrapper>
)

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

export default ContactForm
