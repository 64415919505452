import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectGlobal = state => get(state, key, initialState)

const selectProducts = createSelector(
  selectGlobal, state => get(state, 'products.data', [])
)

const selectProductsLoading = createSelector(
  selectGlobal, state => get(state, 'products.loading', true)
)

const selectSelectedProduct = createSelector(
  selectGlobal, state => get(state, 'products.selected', null)
)

const selectProviders = createSelector(
  selectGlobal, state => get(state, 'providers.data', [])
)

const selectProvidersLoading = createSelector(
  selectGlobal, state => get(state, 'providers.loading', true)
)

const selectModalShowing = createSelector(
  selectGlobal, state => get(state, 'modal.isShowing', false)
)

const selectProviderImg = id => createSelector(
  selectGlobal, state => {
    const data = get(state, 'providers.data', [])
    const provider = data.find(el => el.id === id)
    return provider ? provider.company.publicLogoUrl : ''
  }
)

const selectProviderAddresHandle = id => createSelector(
  selectGlobal, state => {
    const data = get(state, 'providers.data', [])
    const provider = data.find(el => el.id === id)
    return provider ? provider.hasAddressHandle : false
  }
)

export {
  selectProducts,
  selectProviders,
  selectSelectedProduct,
  selectModalShowing,
  selectProviderImg,
  selectProductsLoading,
  selectProvidersLoading
}

export default {
  PRODUCTS: selectProducts,
  PRODUCTS_LOADING: selectProductsLoading,
  SELECTED_PRODUCT: selectSelectedProduct,
  PROVIDERS: selectProviders,
  PROVIDERS_LOADING: selectProvidersLoading,
  MODAL_SHOWING: selectModalShowing,
  PROVIDER_IMG: selectProviderImg,
  PROVIDER_ADDRESS_HANDLER: selectProviderAddresHandle
}
