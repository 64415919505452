export const PHONES = {
  PRIMARY: '0800 8904',
  SECONDARY: '2706 9238'
}

export const EMAILS = {
  INFO: 'info@segurosonline.com.uy'
}

export const WHATSAPP = {
  NUMBER: '099 072 440',
  LINK: 'https://api.whatsapp.com/send?phone=59899072440'
}

export const ADDRESS = {
  STREET: 'Francisco Soca',
  NUMBER: '1573',
  CITY: 'Montevideo',
  COUNTRY: 'Uruguay'
}
