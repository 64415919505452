import styled, { css } from 'styled-components'
import { colors, spacing } from 'Global/theme'

export const StyledDisclaimer = styled.div`
  min-width: 200px;
  max-width: 650px;
  margin: auto;
  font-size: 12pt;

  border-radius: 8px;
  border: solid thin #e6e6e6;
  box-shadow: 0 0 2px 0 #cccccc;
  padding: 0 20px 0 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .info-icon {
    font-size: 20pt;
    color: ${colors.primary.regular};
    margin-right: 20px;
  }

`;