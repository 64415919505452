
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { pick } from 'lodash'

import axios from 'Axios'
import { formatData } from 'Utils/formFormatter'
import { selectQuery } from 'Routes/Redux/selectors'

import { HOME, CERTS } from 'Global/paths'
import { message } from 'Messages/factory'
import { ERROR_MESSAGES } from 'Global/messages'
import { DYNAMIC_FORM_SECTIONS } from 'Global/constants'

import QUOTE_SELECTOR from 'Containers/Quote/Redux/selectors'

import { FORM, RATE } from './types'
import { FORM_ACTIONS, RATE_ACTIONS } from './actions'
import SELECTOR from './selector'

import { PAYMENTS } from '../constants'

const apiCalls = {
  getForm: data => axios.get('/policy_form_data', { params: data }),
  getOptions: url => axios.get(`/options?opt_url=${url}`),
  createPolicy: data => axios.post('/policy_creation_from_application', data),
  getSelectedRate: id => axios.get(`/get_selected_rate?application_id=${id}`)
}

export function * getRate () {
  try {
    const { applicationId } = yield select(selectQuery)
    const rate = yield call(apiCalls.getSelectedRate, applicationId)
    yield put(RATE_ACTIONS.SUCCESS(rate))
  } catch (err) {
    yield put(RATE_ACTIONS.ERROR(err))
  }
}

export function * getForm () {
  try {
    const { productId, providerId } = yield select(selectQuery)

    const selectedRate = yield select(QUOTE_SELECTOR.SELECTED_RATE)

    if (!selectedRate || !productId || !providerId) {
      message.error(ERROR_MESSAGES.PAGE_ERROR)
      yield put(push(HOME))
      return
    }

    const sections = Object.values(DYNAMIC_FORM_SECTIONS)

    const response = yield call(apiCalls.getForm, {
      product_id: productId,
      provider_id: providerId
    })

    const { formId } = response

    const form = formatData(response)
    form.actives = sections.filter(section => !!form[section])

    const { payments } = selectedRate

    yield put(FORM_ACTIONS.GET_SUCCESS({ form, payments, formId }))
  } catch (err) {
    yield put(FORM_ACTIONS.GET_ERROR(err))
  }
}

export function * createPolicy ({ payload }) {
  try {
    const values = pick(payload, Object.values(DYNAMIC_FORM_SECTIONS))
    Object.values(DYNAMIC_FORM_SECTIONS).forEach(section => {
      if (!values[section]) {
        values[section] = []
      }
    })

    const payment = payload[PAYMENTS][0]

    const { shareQuantity, shareAmount, shareCode } = payment.value

    const formId = yield select(SELECTOR.FORM_ID)
    const { providerId, applicationId } = yield select(selectQuery)

    const policy = {
      ...values,
      applicationId,
      providerId,
      shareQuantity: Number(shareQuantity),
      shareAmount: Number(shareAmount),
      shareCode,
      formId,
      verificationId: payload.verificationid
    }

    const { insurancePolicyId } = yield call(apiCalls.createPolicy, policy)

    if (insurancePolicyId) {
      yield put(push({
        pathname: CERTS,
        search: `?policyId=${insurancePolicyId}`
      }))
    } else {
      message.error(ERROR_MESSAGES.GENERAL)
    }
  } catch (err) {
    yield put(FORM_ACTIONS.TO_START())
  }
}

export default function * saga () {
  yield takeLatest(RATE.GET, getRate)
  yield takeLatest(FORM.LOAD, getForm)
  yield takeLatest(FORM.SUBMIT, createPolicy)
}
