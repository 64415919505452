import styled from 'styled-components'

import { spacing, colors } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'
export const List = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 35px;
  width: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '90%' : '100%'};
  margin-top: ${({ device }) => device === BREAKPOINTS.MOBILE || device === BREAKPOINTS.TABLET ? '10px' : '40px'};

`

export const Image = styled.img``
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '90%' : '100%'};
`
export const Title = styled.div`
  width: 100%;
  align-self: center;
  color: ${colors.primary.dark};
  font-weight: bold;
  font-size: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '35px' : '28px'};
  width: 100%;
  padding-top: 10px;
`
