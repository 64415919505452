import { DYNAMIC_FORM_SECTIONS, DYNAMIC_FIELD_TYPES } from 'Global/constants'

export const formatValues = forms => {
  const values = {}

  Object.keys(forms).forEach(key => {
    const { data } = forms[key]
    const section = Object.keys(data).filter(k =>
      data[k].fieldType !== DYNAMIC_FIELD_TYPES.GROUP
    )

    values[key] = section.map(k => {
      const { id, fieldType, value } = data[k]
      const ret = { id, value: {} }

      if (Object.values(DYNAMIC_FORM_SECTIONS).includes(key)) {
        switch (fieldType) {
          case DYNAMIC_FIELD_TYPES.SELECT:
            ret.value.id = value.id
            ret.value.description = value.description
            break
          case DYNAMIC_FIELD_TYPES.BOOLEAN:
            ret.value.id = value.value
            ret.value.description = value.value
            break
          case DYNAMIC_FIELD_TYPES.NUMERIC:
          case DYNAMIC_FIELD_TYPES.TEXT:
          default:
            ret.value.id = value
            ret.value.description = value
        }
      } else {
        ret.value = value
      }
      return ret
    })
  })

  return values
}
