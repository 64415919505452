import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import image from 'Assets/quoteInsurance_2.jpg'

import { INPUT_TYPES } from 'Global/constants'
import { WAITING_MESSAGES } from 'Global/messages'

import useRedux from './Redux'
import SELECTOR from './Redux/selector'
import { FORM_ACTIONS, RATE_ACTIONS } from './Redux/actions'

import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'
import Form from 'Components/Form'
import Loading from 'Components/Loading'
import HeroImage from 'Components/HeroImage'
import InsuranceCard from 'Components/InsuranceCard'
import InsuranceCardLoading from 'Components/InsuranceCard/Components/Loading'
import Advice from 'Components/Advice'
import { message } from 'Messages/factory'

import { PAYMENTS } from './constants'
import { Container, Disclaimer } from './style'
const disclaimer = 'Esta cotización puede haber cambiado. Por favor revísela atentamente.'

const PolicyForm = () => {
  useRedux()
  const [verificationId, setVerificationId] = useState(null)
  const dispatch = useDispatch()
  const [toasted, setToasted] = useState(false)
  const device = useWindowDimensions()

  const loading = useSelector(SELECTOR.LOADING)
  const form = useSelector(SELECTOR.DATA)
  const payments = useSelector(SELECTOR.PAYMENTS)
  const rate = useSelector(SELECTOR.RATE)
  const startStep = useSelector(SELECTOR.START_STEP)
  const done = useSelector(SELECTOR.DONE)

  useEffect(() => {
    dispatch(RATE_ACTIONS.GET())
    dispatch(FORM_ACTIONS.GET())
  }, [])

  const handleSubmit = (values) => {
    dispatch(FORM_ACTIONS.SUBMIT({ ...values, verificationid: verificationId }))
  }

  const renderForm = () => {
    if (Object.keys(form).length === 0) {
      return
    }
    const options = payments
      .filter(({ shareAmount }) => shareAmount !== 0)
      .map((p) => {
        if (p.shareQuantity === '1') {
          return {
            label: `${p.shareQuantity} Cuota de: $ ${p.shareAmount.toLocaleString('de-DE')}`,
            value: p.shareCode,
            ...p
          }
        } else {
          return {
            label: `${p.shareQuantity} Cuotas de: $ ${parseInt(
              p.shareAmount / p.shareQuantity
            ).toLocaleString('de-DE')} - ($ ${p.shareAmount.toLocaleString('de-DE')})`,
            value: p.shareCode,
            ...p
          }
        }
      })

    const data = {
      payment: {
        id: 'payment',
        name: 'payment',
        label: 'Formas de pago',
        type: INPUT_TYPES.SELECT,
        options: options,
        value: '',
        required: true
      }
    }

    const order = [{ id: 'payment', order: 1 }]

    const newForms = {
      ...form,
      actives: [...form.actives, PAYMENTS],
      [PAYMENTS]: { data, order }
    }
    const handleValidation = id => {
      setVerificationId(id)
    }
    const text = ['Siguiente', 'Finalizar póliza']
    const onSuccess = (m) => {
      if (!toasted) {
        message.success('Número verificado con éxito')
        setToasted(true)
      }
    }
    return (
      <Form
        buttonText={text}
        rawForms={newForms}
        submit={handleSubmit}
        buttonBlock
        startStep={startStep}
        recaptcha
        sms={true}
        handleValidation={handleValidation}
        onSuccess={onSuccess}
      />
    )
  }
  return (
    <Page>
      {done && <Loading overlay message={WAITING_MESSAGES.POLICY_FORM} />}

      <HeroImage src={image} />

      {rate ? <InsuranceCard data={rate} preview /> : <InsuranceCardLoading />}
      <Disclaimer>
        <div>
          <span className='info-icon'>
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </div>
        <div>
          <p>{disclaimer}</p>
        </div>
      </Disclaimer>
      <Container device={device}>
        {loading | done ? <Loading size='large' /> : renderForm()}
      </Container>
      <Advice />
    </Page>
  )
}

export default PolicyForm
