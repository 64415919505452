import React from 'react'
import { Container, Item, ItemContainer } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
export const SocialIcons = ({ alternative, device }) => {
  const onClickInstagram = () => {
    window.open('https://www.instagram.com/segurosonline.uy')
  }
  const onClickFacebook = () => {
    window.open('https://www.facebook.com/segurosonline.uy')
  }
  const onClickLinkedIn = () => {
    window.open('https://www.linkedin.com/company/seguros-online-uy/')
  }
  return (
    <Container alternative={alternative} device={device}>
      <ItemContainer>
        <Item margin='0 7px 7px 0' onClick={onClickInstagram}><FontAwesomeIcon icon={faInstagram} /></Item>
        <Item margin='0 7px 7px 0' onClick={onClickFacebook}><FontAwesomeIcon icon={faFacebook} /></Item>
        <Item margin='0 7px 7px 0' onClick={onClickLinkedIn}><FontAwesomeIcon icon={faLinkedin} /></Item>
      </ItemContainer>
    </Container>)
}

export default SocialIcons
