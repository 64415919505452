import produce from 'immer'

import { RATES, PROVIDER_RATE, CATEGORY } from './types'

export const initialState = {
  loading: false,
  error: false,
  rates: [],
  pendingProviders: [],
  canGetRates: false,
  coverageCategories: [],
  quickRateId: 0,
  selected: -1,
  highlights: [],
  creationDate: ''
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // CATEGORY
      case CATEGORY.LOAD:
        draft.error = payload
        break

      case CATEGORY.SUCCESS:
        draft.error = false
        draft.coverageCategories = payload
        break

      case CATEGORY.ERROR:
        draft.error = payload
        break

      // RATES
      case RATES.LOAD:
        draft.error = false
        draft.loading = true
        draft.pendingProviders = payload

        break

      case RATES.SUCCESS:
        draft.error = false
        draft.quickRateId = payload.quickRateId
        draft.pendingProviders = payload.pendingProviders
        draft.highlights = payload.highlights
        draft.creationDate = payload.creationDate
        break

      case RATES.ERROR:
        draft.error = payload
        draft.loading = false
        draft.pendingProviders = []
        break

      case RATES.SELECT:
        draft.selected = payload
        break

      case RATES.CLEAR:
        return initialState

      case RATES.CREATE:
        draft.loading = true
        break

      // PROVIDER RATES
      case PROVIDER_RATE.LOAD:
        draft.canGetRates = true
        break

      case PROVIDER_RATE.STOP:
        draft.canGetRates = false
        break

      case PROVIDER_RATE.SUCCESS: {
        draft.loading = false
        draft.rates = draft.rates.concat(payload.quotes)
        const i = draft.pendingProviders.findIndex(
          ({ provider }) => provider === payload.provider
        )

        draft.pendingProviders.splice(i, 1)
        break
      }

      case PROVIDER_RATE.NO_RATES: {
        const i = draft.pendingProviders.findIndex(
          ({ provider }) => provider === payload.provider
        )
        draft.pendingProviders.splice(i, 1)
        break
      }

      case PROVIDER_RATE.ERROR:
        draft.error = payload
        draft.loading = false
        draft.rates = []
        draft.pendingProviders = []
        break

      default:
        break
    }
  })

export default reducer
