import styled, { css } from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { spacing, colors } from 'Global/theme'
import { weight } from 'Global/font'

const Small = css`
  width: ${spacing.four};
  height: ${spacing.four};
`

const Medium = css`
  width: ${spacing.eight};
  height: ${spacing.eight};
`

const Large = css`
  width: ${spacing.twelve};
  height: ${spacing.twelve};
`

export const Image = styled.img`
  ${({ size }) => {
    switch (size) {
      case 'small': return Small
      case 'large': return Large
      default: return Medium
    }
  }}
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;

  background-color: ${colors.primary.regular50};

  display: flex;

  justify-content: center;
  align-items: center;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  position: absolute;

  top: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return '10%'
      case BREAKPOINTS.TABLET: return '20%'
      default: return '30%'
    }
  }};

  width: 400px;
  max-width: 90%;

  background-color: ${colors.background.white};

  border-radius: 8px;

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `${spacing.two} ${spacing.two}`
      case BREAKPOINTS.TABLET: return `${spacing.two} ${spacing.three}`
      default: return `${spacing.three} ${spacing.four}`
    }
  }};

  > :not(:last-child) {
    margin-bottom: ${spacing.one};
  }

  > p {
    margin: 0;
    font-size: 16pt;
    text-align: center;
  }

  > p.title {
    margin: ${spacing.oneAndAHalf} 0;
    ${weight.bold};
  }

  transition: top 0.5s;
`
