import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  > :not(:first-child) {
    margin-top: ${spacing.two};
  }

  > :last-child {
    margin-top: ${spacing.three};
  }
`

export const GroupContainer = styled.div`
  width: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: ${spacing.half} ${spacing.two} ${spacing.two};

  border-radius: 8px;
  border: solid thin ${colors.primary.dark};
`

export const GroupLabel = styled.div`
  position: absolute;
  top: 0;
  right: 50;
  margin-left: ${spacing.two};
  margin-top: -${spacing.two};
  padding: 0 ${spacing.oneAndAHalf};
  background-color: ${colors.background.white};

  color: ${colors.primary.dark};
  font-size: 16pt;
  ${family.barlow};
  ${weight.semiBold};
`
