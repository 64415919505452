import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectProviders } from 'Redux/Global/selectors'

import Title from 'Components/Title'

import { Vertical, Container, Image, MainContainer } from './style'

const Associated = () => {
  const [providers, setProviders] = useState([])
  const providersUnordered = useSelector(selectProviders)

  useEffect(() => {
    if (providersUnordered) {
      const sorted = [...providersUnordered].sort((a, b) =>
        a.company.name.localeCompare(b.company.name)
      )
      setProviders(sorted)
    }
  }, [providersUnordered])
  return (
    <Vertical>
      <Title title='COMPAÑÍAS ASOCIADAS' />

      <MainContainer>
        <Container>
          {providers.map(({ id, company: { name, publicLogoUrl } }) => (
            <Image key={id} alt={name} src={publicLogoUrl} />
          ))}
        </Container>
      </MainContainer>
    </Vertical>
  )
}

export default Associated
