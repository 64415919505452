import React, { useState } from 'react'
import { Container, Col, InputContainer, Text, Icon } from './styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import Button from 'Components/Button'
import Input from 'Components/Input/TextInput'
import spinner from 'Assets/loading-spinner.gif'
import { useWindowDimensions } from 'Components/WindowDimensions'
import { BREAKPOINTS } from 'Global/constants'
export const Sms = ({ value, validator, onChangePhone, onChangeValidator, requestCode, verifyCode, sentRequest, sentVerification, formState, validatedCaptcha }) => {
  const margin = '8px 0px 0px 10px'
  const device = useWindowDimensions()
  return (
    <Container device={device}>
      <Col width='35%'>
        <Icon>
          <FontAwesomeIcon size='6x' icon={faMobileAlt} />
        </Icon>
      </Col>
      <Col width={device === BREAKPOINTS.MOBILE ? '100%' : '50%'}>
        <Text style={{ marginTop: '10px', textAlign: 'left', width: '100%' }}>Ingrese un número de celular para la verificación</Text>
        <InputContainer device={device}>
          <Input disabled={sentRequest} value={value} type='text' onChange={onChangePhone} />
          {
            !formState.isLoading && <Button style={{padding: 0}} disabled={!validatedCaptcha} onClick={requestCode} margin={margin} size='small'>Enviar</Button>

          }
          {
            formState.isLoading && <img style={{ marginTop: '5px' }} width='50px' height='50px' src={spinner} />
          }
        </InputContainer>

        <Text style={{ marginTop: '10px', textAlign: 'left', width: '100%' }}>Ingrese el código que recibió en el SMS</Text>
        <InputContainer device={device}>
          <Input disabled={formState.isFirstRun | formState.isLoading} value={validator} type='text' onChange={onChangeValidator} />
          <Button style={{padding: 0}} style={{ borderRadius: '8px' }} disabled={sentVerification | formState.isFirstRun | formState.isLoading} onClick={verifyCode} margin={margin} size='small'>Verificar</Button>
        </InputContainer>

      </Col>
    </Container>
  )
}
export default Sms
