import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useWindowDimensions } from 'Components/WindowDimensions'

import useRedux from './Redux'
import { sendContact, clearFields } from './Redux/actions'

import Form from './Components/Form'
import Map from './Components/Map'

import { Container } from './style'

const Contact = () => {
  useRedux()
  const dispatch = useDispatch()
  const device = useWindowDimensions()

  useEffect(() => {
    return () => {
      dispatch(clearFields())
    }
  }, [])

  const handleSubmit = values => {
    dispatch(sendContact(values))
  }

  return (
    <Container device={device}>
      <div className='card card--contact'>
        <Form handleSubmit={handleSubmit} />
      </div>
      <div className='card'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.271562568175!2d-56.15979998435782!3d-34.899633980723095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f8100b65b431f%3A0x457202eeba61a7e5!2sAv.%20Dr.%20Francisco%20Soca%201573%2C%2011600%20Montevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1ses!2suy!4v1624991837057!5m2!1ses!2suy'
          style={{ border: 0, width: '100%', height: '100%' }}
          loading='lazy'
        />
        {/* <Map /> */}
      </div>
    </Container>
  )
}

export default Contact
