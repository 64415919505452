import { CANCEL_POLICY, FORM_FIELD } from './types'
/**
 *
 * FORM FIELD
 */
export const changeField = payload => ({
  type: FORM_FIELD.CHANGE,
  payload
})

export const updateFields = payload => ({
  type: FORM_FIELD.UPDATE,
  payload
})

export const loadFieldOptions = payload => ({
  type: FORM_FIELD.LOAD,
  payload
})

export const loadFieldOptionsSuccess = payload => ({
  type: FORM_FIELD.SUCCESS,
  payload
})

export const loadFieldOptionsError = payload => ({
  type: FORM_FIELD.ERROR,
  payload
})

export const clearField = payload => ({
  type: FORM_FIELD.CLEAR,
  payload
})

export const FIELD_ACTIONS = {
  CHANGE: changeField,
  UPDATE: updateFields,
  LOAD_OPTIONS: loadFieldOptions,
  LOAD_OPTIONS_SUCCESS: loadFieldOptionsSuccess,
  LOAD_OPTIONS_ERROR: loadFieldOptionsError,
  CLEAR: clearField
}

/**
 *
 * CANCEL
 */
export const sendCancelPolicy = () => ({
  type: CANCEL_POLICY.SEND
})

export const sendCancelPolicySuccess = payload => ({
  type: CANCEL_POLICY.SUCCESS,
  payload
})

export const sendCancelPolicyError = payload => ({
  type: CANCEL_POLICY.ERROR,
  payload
})

export const CANCEL_POLICY_ACTIONS = {
  SEND: sendCancelPolicy,
  SUCCESS: sendCancelPolicySuccess,
  ERROR: sendCancelPolicyError
}
