import { FORM, FIELD } from './types'

export const initForm = payload => ({
  type: FORM.INIT,
  payload
})

export const setupForm = payload => ({
  type: FORM.SETUP,
  payload
})

export const setupFormSuccess = payload => ({
  type: FORM.SUCCESS,
  payload
})

export const setupFormError = payload => ({
  type: FORM.ERROR,
  payload
})

export const clearForm = () => ({
  type: FORM.CLEAR
})

export const FORM_ACTIONS = {
  INIT: initForm,
  SETUP: setupForm,
  SUCCESS: setupFormSuccess,
  ERROR: setupFormError,
  CLEAR: clearForm
}

// FIELD ACTIONS

export const changeField = payload => ({
  type: FIELD.CHANGE,
  payload
})

export const updateFields = payload => ({
  type: FIELD.UPDATE,
  payload
})

export const loadFieldOptions = payload => ({
  type: FIELD.LOAD,
  payload
})

export const loadFieldOptionsSuccess = payload => ({
  type: FIELD.SUCCESS,
  payload
})

export const loadFieldOptionsError = payload => ({
  type: FIELD.ERROR,
  payload
})

export const clearField = payload => ({
  type: FIELD.CLEAR,
  payload
})

export const smsSend = payload => (
  {
    type: FIELD.SMS_SEND,
    payload
  }
)
export const smsFail = payload => ({
  type: FIELD.SMS_FAIL,
  payload
})
export const smsSuccess = payload => ({
  type: FIELD.SMS_SUCCESS,
  payload
})

export const verificationSend = payload => ({
  type: FIELD.VERIFICATION_SEND,
  payload
})
export const verificationFail = payload => ({
  type: FIELD.VERIFICATION_FAIL,
  payload
})
export const verificationSuccess = payload => ({
  type: FIELD.VERIFICATION_SUCCESS,
  payload
})

export const FIELD_ACTIONS = {
  CHANGE: changeField,
  UPDATE: updateFields,
  LOAD_OPTIONS: loadFieldOptions,
  LOAD_OPTIONS_SUCCESS: loadFieldOptionsSuccess,
  LOAD_OPTIONS_ERROR: loadFieldOptionsError,
  CLEAR: clearField,
  SMS_SEND: smsSend,
  SMS_FAIL: smsFail,
  SMS_SUCCESS: smsSuccess,
  VERIFICATION_SEND: verificationSend,
  VERIFICATION_FAIL: verificationFail,
  VERIFICATION_SUCCESS: verificationSuccess
}
