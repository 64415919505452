import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from 'Global/constants'

import Button from 'Components/Button'

import IconList from './Components/IconList'

import { Sector, Image, Block, JoinSector, Empty, Spacing } from './style'

const MCard = ({ fee, name, preview, image, metadata, deductible, handleContract, device }) => (
  <>
    <Sector device={device}>
      <Spacing />
      <Image src={image} mobile />
      <Spacing />
    </Sector>

    <Sector device={device} mb>
      <Block>
        <p className='name align-start text-align-left'>{name}</p>
      </Block>
    </Sector>

    <JoinSector>
      <Sector justify='start' device={device} mb>
        <Block spaced>
          <p className='h1 align-start'>Precio contado</p>
          <p className='big-money align-start'>{fee}</p>
          <p className='h2'>seguro por año</p>
        </Block>
      </Sector>

      <Sector justify='start' align='end' device={device} mb>
        <Block spaced>
          <p className='h1'>Deducible</p>
          <p className='big-money'>{deductible}</p>
        </Block>
      </Sector>
    </JoinSector>

    <Sector mb centered>
      <Block mb cover>
        <p className='h3'>Beneficios</p>
      </Block>

      {metadata.length ? <IconList centered device={device} benefits={metadata} /> : <Empty />}
    </Sector>

    {!preview && (
      <Sector>
        <Button size='large' color='primary' onClick={handleContract}>
          Contratar
        </Button>
      </Sector>
    )}
  </>
)

MCard.propTypes = {
  fee: PropTypes.string,
  name: PropTypes.string,
  preview: PropTypes.bool,
  image: PropTypes.string,
  metadata: PropTypes.array,
  deductible: PropTypes.string,
  handleContract: PropTypes.func,
  device: PropTypes.oneOf(Object.values(BREAKPOINTS))
}

export default MCard
