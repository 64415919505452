import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;
  ${family.barlow};
  color: ${colors.primary.dark};
`

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  margin: ${spacing.two} 0;

  border-bottom: solid thin ${colors.primary.dark};

  > p {
    margin: 0;
  }

  > p.quota {
    font-size: 14pt;
    ${weight.bold};
  }

  > p.fee {
    font-size: 24pt;
    ${weight.regular};
  }
`
