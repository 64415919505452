import produce from 'immer'

import { CERTS, POLICY } from './types'

export const initialState = {
  loading: false,
  error: '',
  reference: '',
  code: '',
  certs: []
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // POLICY
      case POLICY.GET:
        draft.loading = true
        break

      case POLICY.ERROR:
        draft.error = payload
        draft.loading = false
        break

      case POLICY.REFERENCE:
        draft.reference = payload
        break

      case POLICY.CODE:
        draft.code = payload
        break

      case POLICY.CLEAR:
        return initialState

      // CERTIFICATES
      case CERTS.GET:
        draft.certs = []
        break

      case CERTS.SUCCESS:
        draft.loading = false
        draft.error = ''
        draft.certs = payload
        break

      case CERTS.ERROR:
        draft.loading = false
        draft.error = payload
        draft.certs = []
        break
    }
  })

export default reducer
