import styled, { css } from "styled-components"
import { colors } from "Global/theme"
import { BREAKPOINTS } from "Global/constants"
const tabletWidth = 1200

const mobileContainer = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  align-self: center;
`
const desktopContainer = css`
  width: 400px;
  margin-left: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100%;
  padding-bottom: 150px;
`
export const Container = styled.div`
  ${(props) => (props.isMobile ? mobileContainer : desktopContainer)}
`

export const titleType1 = css`
  color: ${colors.primary.dark};
`
export const titleType2 = css`
  color: white;
`
export const titleType3 = css`
  color: white;
`
const mobileTitle = css`
  text-align: center;
  color: ${colors.primary.dark};
`
const desktopTitle = css``
export const Title = styled.div`
  width: 100%;
  align-self: center;
  font-weight: bold;
  padding-top: 10px;
  margin-bottom: 4px;

  font-size: ${({ device }) => {
    if (device <= BREAKPOINTS.MOBILE) {
      return '26px'
    }
    else if (device > BREAKPOINTS.MOBILE && device <= BREAKPOINTS.TABLET) {
      return '35px'
    }
    else {
      return '40px'
    }
  }};
  ${({ type }) => {
    switch (type) {
      case 0:
        return titleType1
      case 1:
        return titleType2
      default:
        return titleType3
    }
  }};
  ${(props) => (props.isMobile ? mobileTitle : desktopTitle)}
`

export const subtitleType1 = css`
  color: white;
`
export const subtitleType2 = css`
  color: white;
  border: 4px solid white;
`
export const subtitleType3 = css`
  color: ${colors.primary.dark};
  border: 4px solid ${colors.primary.dark};
  background-color: white;
`
const mobileSubtitle = css`
  align-self: center;
  text-align: center;
  border: 0px solid ${colors.primary.regular};
  background-color: ${colors.primary.regular};
  color: white;
  font-size: ${(props) => (props.device <= tabletWidth ? "22px" : "30px")};

`
const desktopSubtitle = css``
export const Subtitle = styled.div`
  width: ${(props) => (props.device <= tabletWidth ? "39%" : "42%")};
  display: flex;
  align-self: flex-start;
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => (props.device <= tabletWidth ? "34px" : "43px")};
  padding-top: 10px;
  border-radius: 10px;
  background-color: ${colors.primary.regular};
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 0;
  max-width: ${(props) => (props.device <= tabletWidth ? "130px" : "190px")};
  cursor: pointer;
  :hover {
    background-color: ${colors.primary.light};
  }

  p {
    text-align: center;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    padding-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
  }
  ${({ type }) => {
    switch (type) {
      case 0:
        return subtitleType1
      case 1:
        return subtitleType2
      default:
        return subtitleType3
    }
  }};
  ${(props) => (props.isMobile ? mobileSubtitle : desktopSubtitle)}
`

export const descriptionType1 = css`
  color: ${colors.primary.regular};
`
export const descriptionType2 = css`
  color: ${colors.success.regular};
`
export const descriptionType3 = css`
  color: ${colors.success.regular};
`
const mobileDescription = css`
  text-align: center;
  color: ${colors.primary.regular};
`
const desktopDescription = css``
export const Description = styled.div`
  width: 100%;
  align-self: center;
  font-weight: 600;
  font-size: ${(props) => (props.device <= tabletWidth ? "20px" : "37px")};

  ${({ type }) => {
    switch (type) {
      case 0:
        return descriptionType1
      case 1:
        return descriptionType2
      default:
        return descriptionType3
    }
  }};
  ${(props) => (props.isMobile ? mobileDescription : desktopDescription)}
`
