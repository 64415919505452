import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

`
export const Item = styled.div`
  width: 100%;
  display: flex;
  font-size: 29px;
  padding: 5px;
`
