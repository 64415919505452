import styled from 'styled-components'

import { spacing } from 'Global/theme'
import { family, weight, size } from 'Global/font'

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: ${spacing.eight};
  margin: ${spacing.one} 0;
`

export const Label = styled.p`
  font-size: 13pt;
  top: ${spacing.four};
  align-self: flex-start;
  margin: 0;
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;


  margin: ${spacing.oneAndAHalf} 0 0 ${spacing.one};

  > :not(:last-child) {
    margin-bottom: ${spacing.one};
  }
`

export const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;

  > p {
    margin: 0;
    font-size: ${size.fourteen};
  }

  :hover {
    cursor: pointer;
    ${family.openSans};
    ${weight.bold};
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${spacing.threeAndAHalf};
  height: ${spacing.threeAndAHalf};
  margin-right: ${spacing.half};

  > :first-child {
    height: 80%;
    width: 80%;
  }
`
