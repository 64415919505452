import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectCerts = state => get(state, key, initialState)

const selectError = createSelector(
  selectCerts, state => get(state, 'error', '')
)
const selectLoading = createSelector(
  selectCerts, state => get(state, 'loading', false)
)

const selectReference = createSelector(
  selectCerts, state => get(state, 'reference', undefined)
)

const selectCode = createSelector(
  selectCerts, state => get(state, 'code', undefined)
)

const selectCertificates = createSelector(
  selectCerts, state => get(state, 'certs', [])
)

export default {
  ERROR: selectError,
  LOADING: selectLoading,
  REFERENCE: selectReference,
  CODE: selectCode,
  CERTS: selectCertificates
}
