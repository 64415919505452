import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'
import Information from 'Components/Information'

//import data from './data'

import { Block, Header, Title, Option, Info, Separation, Description, BlockContainer } from './styles'

const Privacy = () => {
  const device = useWindowDimensions()

  return (
    <Page>

      <Information
        title='POLÍTICA DE PRIVACIDAD'
        info='NOS COMPROMETEMOS A LA PROTECCIÓN DE TUS DATOS PERSONALES, Y A LA CONFIDENCIALIDAD DE LOS MISMOS, SIN ENTREGARLOS A OTRA EMPERSA FUERA DE SEGUROSONLINE O LAS COMPAÑIAS ASOCIADAS.'
      />

      
        <BlockContainer>
          <div>
            <p>
              Los datos personales que se recaben serán utilizados con la finalidad de realizar las acciones solicitadas en nuestra página: cotizar sus seguros o realizar contratos.
            </p>
            <p>
              Sus direcciones de mail o números telefónicos solo serán utilizadas por nuestra empresa con el fin de comunicarles información de su interés, nuevos beneficios o promociones.
            </p>
            <p>
              Podrán borrarse de la lista de distribución de mail cuando quieran.
            </p>
            <p>
              Respecto de la recolección y tratamiento de datos realizado mediante mecanismos automatizados con el objeto de generar registros de actividad de los visitantes, en ningún caso realizará operaciones que impliquen asociar dicha información a algún usuario identificado o identificable.
            </p>
            <p>
              El formulario de contacto del sitio web está concebido como un buzón de atención al cliente y tiene como objetivo facilitar las comunicaciones e intercambio de información entre los interesados y Seguros online, y los datos se mantendrán con la finalidad de recibir y responder consultas, así como obtener datos estadísticos.
            </p>
            <p>
              Los datos personales recabados no serán entregados a terceros fuera de Segurosonline o sus compañías de seguros asociadas.
            </p>
          </div>
      
        </BlockContainer>

      <Advice />
      <Associated />
      <Contact />

    </Page>
  )
}

export default Privacy
