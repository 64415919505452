import React from 'react'
import PropTypes from 'prop-types'

import { Loading } from './style'

const LoadingGradient = ({ width, height, type }) => (
  <Loading
    width={width}
    height={height}
    type={type}
  />
)

LoadingGradient.propTypes = {
  type: PropTypes.oneOf(['circle', 'line', 'text']),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default LoadingGradient
