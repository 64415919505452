import styled from 'styled-components'
import { colors, spacing } from 'Global/theme'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 832px;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: ${spacing.fourAndAHalf} 0;
`

export const Card = styled.div`
  height: auto;
  width: 100%;

  border-radius: 8px;
  box-shadow: 0 0 10px 0 ${({ stared }) => stared ? colors.stared.shadow : colors.grays[20]};
  border: solid thin ${({ stared }) => stared ? colors.stared.border : colors.grays[10]};

  background-color: ${colors.background.white};

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Star = styled.div`
  position: absolute;
  width: ${spacing.oneAndAHalf};
  height: ${spacing.oneAndAHalf};

  margin: ${spacing.oneAndAHalf} 0 0 ${spacing.two};

  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;

  color: ${colors.stared.fill};

  > :first-child {
    width: 100%;
    height: 100%;
  }
`
