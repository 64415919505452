export const key = 'userRegistry'

export const GENERAL = {
  SET_PROVIDER: 'USER_REGISTRY/GENERAL/SET_PROVIDER'
}

export const FIELDS = {
  CHANGE: 'USER_REGISTRY/FIELDS/CHANGE',
  CLEAR: 'USER_REGISTRY/FIELDS/CLEAR'
}

export const ADDRESS = {
  GET: 'USER_REGISTRY/ADDRESS/GET',
  SUCCESS: 'USER_REGISTRY/ADDRESS/GET_SUCCESS',
  ERROR: 'USER_REGISTRY/ADDRESS/GET_ERROR',
  SELECT: 'USER_REGISTRY/ADDRESS/SELECT',
  STREET_SELECT: 'USER_REGISTRY/ADDRESS/STREET_SELECT',
  CLEAR: 'USER_REGISTRY/ADDRESS/CLEAR'
}

export const FLOW = {
  LOADING: 'USER_REGISTRY/FLOW/LOADING',
  NEXT: 'USER_REGISTRY/FLOW/NEXT',
  STEP: 'USER_REGISTRY/FLOW/STEP',
  USER: 'USER_REGISTRY/FLOW/USER',
  ADDRESS: 'USER_REGISTRY/FLOW/ADDRESS',
  COMPLETE: 'USER_REGISTRY/FLOW/COMPLETE',
  ERROR: 'USER_REGISTRY/USER/ERROR',
  CLEAR: 'USER_REGISTRY/USER/CLEAR'
}

export default key
