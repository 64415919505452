import styled from 'styled-components'

import { coverBackground } from 'Utils/style'
import { spacing, colors } from 'Global/theme'
import { weight } from 'Global/font'

export const Container = styled.div`
  width: 100%;
  position: relative;

  padding: ${spacing.nine} 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: ${colors.background.gray};
  ${coverBackground(colors.background.gray)};

`

export const CardContainer = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;

  > * {
    margin-top: ${spacing.sevenAndAHalf};
  }

`

export const CardChild = styled.div`
  width: 100%;
  height: 100%;

  padding: ${spacing.threeAndAHalf} ${spacing.three};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    height: auto;
    width: ${spacing.six};
    min-width: ${spacing.six};
    margin-right: ${spacing.oneAndAHalf};

    display: flex;
    justify-content: center;
    align-items: center;

    > :first-child {
      width: 100%;
      height: auto;
    }
  }

  .text {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    font-size: 18px;

    p {
      margin: 0;
    }

    .data {
      ${weight.semiBold};
    }
  }
`
