import { put, takeLatest, call } from 'redux-saga/effects'

import axios from 'Axios'
import { SUCCESS_MESSAGES } from 'Global/messages'

import { message } from 'Messages/factory'

import { CONTACT } from './types'
import { CONTACT_ACTIONS } from './actions'

import { SECTION } from '../constants'

export const apiCalls = {
  sendMessage: data => axios.post('/contact', data)
}

export function * sendMessage ({ payload }) {
  try {
    const data = payload[SECTION]

    const body = {}
    data.forEach(({ id, value }) => {
      body[id] = value
    })

    yield call(apiCalls.sendMessage, body)
    yield put(CONTACT_ACTIONS.SUCCESS())
    message.success(SUCCESS_MESSAGES.CONTACT)
  } catch (err) {
    yield put(CONTACT_ACTIONS.ERROR(err))
  }
}

export default function * saga () {
  yield takeLatest(CONTACT.SEND, sendMessage)
}
