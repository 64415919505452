import React from 'react'
import PropTypes from 'prop-types'

import { METADATAICONS } from 'Global/mapping'
import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { Wrapper, Container, Block, Icon, Column } from './style'

const IconDetails = ({ benefits }) => {
  const device = useWindowDimensions()

  const left = []
  const right = []

  benefits.forEach((benefit, i) => {
    const { type, id, isIncluded, description } = benefit
    const benefitIcon = METADATAICONS.find(elem => elem.id === type.id)
    const iconToUse = benefitIcon ? benefitIcon.icon : faInfoCircle

    const el = (
      <Container key={id}>
        <Icon included={isIncluded}>
          <FontAwesomeIcon icon={iconToUse} />
        </Icon>
        <Block included={isIncluded}>
          <p className='title'>{type.name}:</p> <span className='desc'>{description}</span>
        </Block>
      </Container>
    )

    if (i % 2 === 0) left.push(el)
    else right.push(el)
  })

  return (
    <Wrapper device={device}>
      <Column>
        {left}
        {device === BREAKPOINTS.MOBILE && right}
      </Column>

      {device !== BREAKPOINTS.MOBILE && (
        <Column>
          {right}
        </Column>
      )}
    </Wrapper>
  )
}

IconDetails.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      type: PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      }),
      isIncluded: PropTypes.bool
    })
  )
}

export default IconDetails
