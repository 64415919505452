export const key = 'cancelPolicy'

export const CANCEL_POLICY = {
  SEND: 'CANCEL_POLICY/SEND',
  SUCCESS: 'CANCEL_POLICY/SUCCESS',
  ERROR: 'CANCEL_POLICY/ERROR'
}

export const FORM = {
  LOAD: 'CANCE_POLICY/FORM/LOAD',
  LOAD_SUCCESS: 'CANCE_POLICY/FORM/LOAD_SUCCESS',
  LOAD_ERROR: 'CANCE_POLICY/FORM/LOAD_ERROR',
  SAVE: 'CANCE_POLICY/FORM/SAVE',
  SAVE_SUCCESS: 'CANCE_POLICY/FORM/SAVE_SUCCESS',
  SAVE_ERROR: 'CANCE_POLICY/FORM/SAVE_ERROR'
}

export const FORM_FIELD = {
  LOAD: 'CANCE_POLICY/FORM_FIELD/LOAD',
  SUCCESS: 'CANCE_POLICY/FORM_FIELD/SUCCESS',
  ERROR: 'CANCE_POLICY/FORM_FIELD/ERROR',
  CHANGE: 'CANCE_POLICY/FORM_FIELD/CHANGE',
  UPDATE: 'CANCE_POLICY/FORM_FIELD/UPDATE',
  CLEAR: 'CANCE_POLICY/FORM_FIELD/CLEAR'
}

export default key
