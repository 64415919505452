import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { checkField } from 'Utils/checks'
import { BAISC_INPUT_TYPES } from 'Global/constants'

import DatePicker from 'Components/Input/DatePicker'

import { Wrapper, StyledInput, StyledLabel } from '../style'

const Input = ({ type, name, label, value, required, onChange, disabled, placeholder, formValid }) => {
  const [onTop, setOnTop] = useState(!!value)
  const [valid, setValid] = useState(true)
  const [changed, setChange] = useState(false)

  const [safari, setSafari] = useState(false)

  useEffect(() => {
    const mobile = ['iPhone', 'iPad', 'iPod']
    const { userAgent, platform } = navigator

    const browser = /Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)
    const desktop = mobile.indexOf(platform) === -1

    setSafari(browser & desktop)
  }, [])

  useEffect(() => {
    if (formValid !== undefined) {
      setValid(valid && formValid)
    }
  }, [formValid])

  const handleChange = ({ target }) => {
    if (!changed) setChange(true)

    const { value } = target

    const check = checkField({ type, value })

    let isValid = check && !(required && changed && !value)

    if (formValid !== undefined) {
      isValid = isValid && formValid
    }

    setValid(isValid)

    onChange(target)
  }

  const handleFocus = () => {
    setOnTop(true)
  }

  const handleBlur = () => {
    setOnTop(!!value)
  }

  let fieldType = type
  if (type === BAISC_INPUT_TYPES.NUMBER || type === BAISC_INPUT_TYPES.CI || type === BAISC_INPUT_TYPES.NAME) {
    fieldType = 'text'
  } else if (type === BAISC_INPUT_TYPES.CELLPHONE) {
    fieldType = 'tel'
  }

  return (
    <Wrapper>
      <StyledLabel
        isOnTop={onTop}
        htmlFor={name}
        valid={valid}
        disabled={disabled}
      >
        {label}
        {required && <span> *</span>}
      </StyledLabel>

      {fieldType === BAISC_INPUT_TYPES.DATE && safari ? (
        <DatePicker
          name={name}
          value={value}
          valid={valid}
          disabled={disabled}
          handleChange={handleChange}
          placeholder=''
        />
      ) : (
        <StyledInput
          name={name}
          type={fieldType}
          value={value}
          valid={valid}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}

          onFocus={handleFocus}
          onBlur={handleBlur}

          covered={!onTop}
        />
      )}

    </Wrapper>
  )
}

Input.propTypes = {
  type: PropTypes.oneOf(Object.values(BAISC_INPUT_TYPES)),
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.any,
  formValid: PropTypes.bool
}

export default Input
