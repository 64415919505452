import { put, takeLatest, select, call } from 'redux-saga/effects'

import axios from 'Axios'
import GLOBAL_SELECT from 'Redux/Global/selectors'
import { PROVIDERS } from 'Global/constants'

import { STEPS, PLACES, NEXT_PLACE } from '../constants'
import { isMontevideo } from '../utils'

import SELECT from './selectors'
import { ADDRESS, FLOW } from './types'
import {
  ADDRESS_ACTIONS,
  FLOW_ACTIONS
} from './actions'

const apiCalls = {
  getAddresss: url => axios.get(`/options?opt_url=${url}`),
  checkUser: data => axios.get('/check_client_created', { params: data }),
  createUser: user => axios.post('/create_client/', user),
  checkAddress: data => axios.get('/check_client_provider_address', { params: data }),
  addAddress: address => axios.post('/create_client_provider_address/', address)
}

function * getCounties () {
  try {
    const providerId = yield select(SELECT.PROVIDER_ID)
    const hasAddressHandler = yield select(GLOBAL_SELECT.PROVIDER_ADDRESS_HANDLER(providerId))

    const url = hasAddressHandler
      ? `/ws/all_provider_countries/${providerId}`
      : '/ws/countries/'

    return yield call(apiCalls.getAddresss, url)
  } catch (err) {
    yield put(ADDRESS_ACTIONS.ERROR(err))
  }
}

function * getDepartments () {
  try {
    const providerId = yield select(SELECT.PROVIDER_ID)
    const hasAddressHandler = yield select(GLOBAL_SELECT.PROVIDER_ADDRESS_HANDLER(providerId))
    const country = yield select(SELECT.COUNTRY)

    const { id } = country.value
    const url = hasAddressHandler
      ? `/ws/all_provider_departments/${providerId}/${id}/`
      : `/ws/departments/${id}/`

    return yield call(apiCalls.getAddresss, url)
  } catch (err) {
    yield put(ADDRESS_ACTIONS.ERROR(err))
  }
}

function * getCities () {
  try {
    const providerId = yield select(SELECT.PROVIDER_ID)
    const hasAddressHandler = yield select(GLOBAL_SELECT.PROVIDER_ADDRESS_HANDLER(providerId))
    const department = yield select(SELECT.DEPARTMENT)
    const { id } = department.value

    const url = hasAddressHandler
      ? `/ws/all_provider_department_cities/${providerId}/${id}/`
      : `/ws/department_cities/${id}/`

    return yield call(apiCalls.getAddresss, url)
  } catch (err) {
    yield put(ADDRESS_ACTIONS.ERROR(err))
  }
}

export function * getPlaceOptions ({ payload }) {
  try {
    const { place } = payload
    let result = {}
    switch (place) {
      case PLACES.COUNTRY:
        result = yield call(getCounties)
        break
      case PLACES.DEPARTMENT:
        result = yield call(getDepartments)
        break
      case PLACES.CITY:
        result = yield call(getCities)
        break
      default:
        return
    }

    result = result.map(r => ({
      ...r,
      label: r.description || r.nombreRadio,
      value: r.description || r.id
    }))
    yield put(ADDRESS_ACTIONS.SUCCESS({ place, options: result }))
  } catch (err) {
    yield put(ADDRESS_ACTIONS.ERROR(err))
  }
}

export function * addressSelected ({ payload }) {
  try {
    const { place } = payload
    if (place === PLACES.STREET) return

    /* eslint-disable no-fallthrough */
    switch (place) {
      case PLACES.COUNTRY:
        yield put(ADDRESS_ACTIONS.CLEAR({ place: PLACES.DEPARTMENT }))

      case PLACES.DEPARTMENT:
        yield put(ADDRESS_ACTIONS.CLEAR({ place: PLACES.CITY }))

      case PLACES.CITY:
        yield put(ADDRESS_ACTIONS.CLEAR({ place: PLACES.STREET }))
        break

      default:
        return
    }

    const nextPlace = NEXT_PLACE(place)
    yield put(ADDRESS_ACTIONS.GET({ place: nextPlace }))
  } catch (err) {
    yield put(ADDRESS_ACTIONS.ERROR(err))
  }
}

function * getAddress (userId) {
  const providerId = yield select(SELECT.PROVIDER_ID)
  const country = yield select(SELECT.COUNTRY)
  const department = yield select(SELECT.DEPARTMENT)
  const city = yield select(SELECT.CITY)
  const { main, corner } = yield select(SELECT.STREET)
  const number = yield select(SELECT.NUMBER)
  const aptNumber = yield select(SELECT.APT_NUMBER)

  const hasAddressHandler = yield select(GLOBAL_SELECT.PROVIDER_ADDRESS_HANDLER(providerId))

  let addressCity = {
    id: city.value.id,
    name: city.value.description
  }
  let addressStreet = {
    id: main.id,
    name: main.description
  }
  let addressCornerStreet = {
    id: corner.id,
    name: corner.description
  }

  if (providerId === PROVIDERS.BSE) {
    addressCornerStreet = undefined

    if (!isMontevideo(department.value)) {
      const { label: cLabel, value: cValue, ...cityRest } = city.value
      addressCity = cityRest

      addressStreet = { streetName: main }
    }
  }

  return {
    providerId: hasAddressHandler ? providerId : null,
    insuranceClientId: userId,
    address: {
      addressCountry: {
        id: country.value.id,
        name: country.value.description
      },
      addressDepartment: {
        id: department.value.id,
        name: department.value.description
      },
      addressCity,
      addressStreet,
      addressCornerStreet,
      addressNumber: number,
      addressAppartmentNumber: aptNumber,
      isCorrespondence: true,
      isPrincipal: true,
      isCollection: true
    }
  }
}

function * getUser () {
  const ci = yield select(SELECT.CI)
  const firstName = yield select(SELECT.FIRST_NAME)
  const lastName = yield select(SELECT.LAST_NAME)
  const birthDate = yield select(SELECT.BIRTH_DATE)
  const email = yield select(SELECT.EMAIL)
  const cellphone = yield select(SELECT.CELLPHONE)
  const phone = yield select(SELECT.PHONE)

  const ret = {
    person: {
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate,
      emails: [
        {
          value: email,
          main: true,
          deleted: false
        }
      ],
      phones: [
        {
          type: {
            id: 2,
            name: 'celular'
          },
          value: cellphone,
          main: true,
          deleted: false
        }
      ],
      documents: [
        {
          value: ci,
          main: true,
          deleted: false,
          type: {
            id: 1
          }
        }
      ],
      pictureUrl: null,
      deleted: false,
      isUnique: false
    },
    files: [],
    providerId: null
  }

  if (phone) {
    ret.person.phones.push({
      type: {
        id: 1,
        name: 'personal'
      },
      value: phone,
      main: false,
      deleted: false
    })
  }

  return ret
}

export function * nextStep () {
  try {
    yield put(FLOW_ACTIONS.LOADING(true))

    const showing = yield select(SELECT.SHOWING)
    switch (showing) {
      case STEPS.CI: {
        const ci = yield select(SELECT.CI)
        const { insuranceClient } = yield call(apiCalls.checkUser, {
          doc_type: 1,
          doc_value: ci
        })

        if (insuranceClient) {
          yield put(FLOW_ACTIONS.USER(insuranceClient))
          const providerId = yield select(SELECT.PROVIDER_ID)
          const { hasProviderAddress } = yield call(apiCalls.checkAddress, {
            insurance_client_id: insuranceClient,
            provider_id: providerId
          })

          if (hasProviderAddress) {
            yield put(FLOW_ACTIONS.STEP(STEPS.CHECK))
          } else {
            yield put(FLOW_ACTIONS.STEP(STEPS.ADDRESS))
          }
        } else {
          // usar esto para saltar
          yield put(FLOW_ACTIONS.STEP(STEPS.INFO))
        }
        break
      }
      case STEPS.CHECK:
        yield put(FLOW_ACTIONS.COMPLETE())
        break
      case STEPS.INFO:
        yield put(FLOW_ACTIONS.STEP(STEPS.ADDRESS))
        break
      case STEPS.ADDRESS: {
        let userId = yield select(SELECT.USER)
        if (!userId) {
          const user = yield getUser()
          const { insuranceClient } = yield call(apiCalls.createUser, user)

          userId = insuranceClient
          yield put(FLOW_ACTIONS.USER(userId))
        }

        const address = yield getAddress(userId)
        yield call(apiCalls.addAddress, address)
        yield put(FLOW_ACTIONS.COMPLETE())
        break
      }
      default:
        return
    }
    yield put(FLOW_ACTIONS.LOADING(false))
  } catch (err) {
    yield put(FLOW_ACTIONS.ERROR(err))
  }
}

export default function * saga () {
  yield takeLatest(ADDRESS.GET, getPlaceOptions)
  yield takeLatest(ADDRESS.SELECT, addressSelected)
  yield takeLatest(FLOW.NEXT, nextStep)
}
