import styled, { css } from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { weight } from 'Global/font'

export const Wrapper = styled.div`
  height: ${({ device }) => device === BREAKPOINTS.DESKTOP ? '300px' : 'auto'};
  width: 100%;

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `0 ${spacing.oneAndAHalf} ${spacing.four}`
      case BREAKPOINTS.TABLET: return `0 ${spacing.three} ${spacing.four}`
      default: return `${spacing.three} 0`
    }
  }};

  display: flex;
  flex-direction: ${({ device }) => device === BREAKPOINTS.DESKTOP ? 'row' : 'column'};
  justify-content: space-evenly;
  align-items: center;

  p {
    font-family: 'Barlow', sans-serif;
    color: ${colors.primary.dark};
    margin: 0;
  }
`

export const Sector = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  ${({ device }) => device === BREAKPOINTS.DESKTOP ? 'flex: 1' : ''};
  flex-direction: column;

  justify-content: ${({ justify }) => {
    switch (justify) {
      case 'start': return 'flex-start'
      case 'between': return 'space-between'
      default: return 'center'
    }
  }};

  align-items: ${({ align }) => {
    switch (align) {
      case 'end': return 'felx-end'
      case 'start': return 'flex-start'
      default: return 'center'
    }
  }};

  margin-bottom: ${({ mb }) => mb ? spacing.four : 0};

  * > p.name {
    font-size: ${({ device }) => device !== BREAKPOINTS.DESKTOP ? 20 : 15}pt;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-align: center
  }

  * > p.h1 {
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? 18 : 20}pt;
  }

  * > p.h2 {
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? 14 : 15}pt;
    ${weight.light};
  }

  * > p.h3 {
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? 8 : 12}pt;
    ${weight.light}
  }

  * > p.money {
    font-size: 15pt;
  }

  * > p.big-money {
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? 23 : 32}pt;
  }

  * > p.align-start {
    align-self: flex-start;
  }
`

export const JoinSector = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const Block = styled.div`
  width: ${({ cover }) => cover ? '100%' : 'auto'};
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: ${({ mb }) => mb ? spacing.oneAndAHalf : 0};
  padding: 0 ${({ ph }) => ph ? spacing.four : 0};

  > :not(:last-child) {
    margin-bottom: ${({ spaced }) => spaced ? spacing.one : ''};
  }
`
export const Spacing = styled.div `
  height: 30px;
  width: 2px;
`
export const Image = styled.img`
  width: auto;
  max-width: 200px;
  max-height: ${({ mobile }) => mobile ? 100 : 70}%;
`

export const Empty = styled.div`
  width: 100%;
  height: ${spacing.four};
`

export const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${colors.grays[10]};
  margin: 0 ${spacing.oneAndAHalf};
`
