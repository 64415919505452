import React from 'react'

import { Bar } from './style'
import { useWindowDimensions } from 'Components/WindowDimensions'
import SocialIcons from './SocialIcons'
const Info = ({ line = 1 }) => {
  const width = useWindowDimensions()
  return (
    <>
      {line === 1 && (
        <Bar device={width}>
          <p>Francisco Soca 1573 - Montevideo, Uruguay / 0800 8904 - 099 072 440 - 2706 9238 <a href='mailto:info@segurosonline.com.uy'>info@segurosonline.com.uy</a>
          </p>
          <SocialIcons />
        </Bar>
      )}
      {line !== 1 && (
        <Bar device={width}>
          <p>Francisco Soca 1573 - Montevideo, Uruguay</p>
          <p>
            0800 8904 - 099 072 440 - 2706 9238{' '}
            <a href='mailto:info@segurosonline.com.uy'>info@segurosonline.com.uy</a>
          </p>
          <SocialIcons alternative device={width} />
        </Bar>
      )}
    </>
  )
}
export default Info
