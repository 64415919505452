import iconTodoRiesgo from 'Assets/Icono-SEGURO-TODORIESGO-2.svg'
import iconRespCivil from 'Assets/Icono-SEGURO-RESP-CIVIL-2.svg'
import iconTriple from 'Assets/Icono-SEGURO-TRIPLE-2.svg'

export default [
  {
    name: 'Todo riesgo',
    icon: iconTodoRiesgo,
    options: [
      'Daño Propio (daños al vehículo).',
      'Hurto (parcial o total) e Incendio (parcial o total ) del Vehículo.',
      'Responsabilidad Civil (Daños materiales y lesiones a terceros).',
      'SOA'
    ],
    info: [
      `La cobetura contra Todo Riesgo, llamada también Cobertura Total, te brinda la mayor tranquilidad,
       ya que contratando esta póliza la compañía asume los costos tanto de los daños materiales y lesiones
       hasta el límite indicado en la póliza contratada , causados a terceros , como del hurto parcial
       o total o incendio parcial o total de tu vehículo.`,
      'También estás cubierto con la Cobertura Seguro Obligatorio establecida por Ley (SOA)'
    ]
  },
  {
    name: 'Triple',
    icon: iconTriple,
    options: [
      'Hurto (parcial o total)  e Incendio  (parcial o total ) del Vehículo.',
      'Responsabilidad Civil (Daños materiales y lesiones) a terceros',
      'SOA'

    ],
    info: [
      `La Cobertura Triple (Terceros, Robo e Incendio) también llamada Parcial por algunas compañías
       aseguradoras, te brinda un importante grado de tranquilidad ya que contratando esta póliza,
       la compañía de seguros asume los costos tanto de los daños materiales y lesiones a terceros
       hasta el límite indicado en la póliza contratada, como el hurto parcial o total y el incendio
       parcial o total de tu vehículo.`,
      'También  estás cubierto con la Cobertura Seguro Obligatorio establecida por Ley (SOA).'

    ]
  },
  {
    name: 'Responsabilidad civil',
    icon: iconRespCivil,
    options: [
      'Daños materiales y lesiones a terceros',
      'SOA'
    ],
    info: [
      `La Cobertura  de Responsabilidad Civil también llamada Terceros, por algunas compañías
       aseguradoras, te brinda un importante grado de tranquilidad ya que contratando esta póliza,
       la compañía de seguros asume los costos tanto de los daños materiales y lesiones a terceros
       hasta el límite indicado en la póliza contratada.`,
      'También  estás cubierto con la Cobertura Seguro Obligatorio establecida por Ley (SOA)'
    ]
  }
]
