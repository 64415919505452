export const key = 'email'

export const FIELDS = {
  SAVE: 'EMAIL/FIELDS/SAVE',
  CLEAR: 'EMAIL/FIELDS/CLEAR'
}

export const EMAIL = {
  SEND: 'EMAIL/SEND',
  SUCCESS: 'EMAIL/SUCCESS',
  ERROR: 'EMAIL/ERROR'
}

export default key
