import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectMessagesReducer = state => get(state, key, initialState)

const selectMessages = createSelector(
  selectMessagesReducer, state => get(state, 'messages', [])
)

export default {
  MESSAGES: selectMessages
}
