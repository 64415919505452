import produce from 'immer'

import { STEPS, PLACES } from '../constants'

import { FIELDS, ADDRESS, GENERAL, FLOW } from './types'

export const initialState = {
  loading: false,
  error: '',
  showing: STEPS.CI,
  providerId: 0,
  ci: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  email: '',
  phone: '',
  cellphone: '',
  [PLACES.COUNTRY]: {
    value: '',
    options: undefined,
    loading: false
  },
  [PLACES.DEPARTMENT]: {
    value: '',
    options: undefined,
    loading: false
  },
  [PLACES.CITY]: {
    value: '',
    options: undefined,
    loading: false
  },
  [PLACES.STREET]: {
    value: '',
    options: undefined,
    loading: false,
    main: '',
    corner: ''
  },
  number: '',
  aptNumber: '',
  userId: undefined,
  complete: false
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // GENERAL
      case GENERAL.SET_PROVIDER:
        draft.providerId = payload
        break

      // FIELDS
      case FIELDS.CHANGE:
        draft[payload.name] = payload.value
        break

      case FIELDS.CLEAR:
        return initialState

      // SELECTORS
      case ADDRESS.GET:
        // draft.loading = true
        draft.error = ''
        draft[payload.place].loading = true
        break

      case ADDRESS.SUCCESS:
        // draft.loading = false
        draft.error = ''
        draft[payload.place].options = payload.options
        draft[payload.place].loading = false
        break

      case ADDRESS.ERROR:
        draft = {
          ...initialState,
          error: payload
        }
        break

      case ADDRESS.SELECT:
        draft[payload.place].value = payload.value
        break

      case ADDRESS.STREET_SELECT:
        draft[PLACES.STREET][payload.name] = payload.value
        break

      case ADDRESS.CLEAR:
        draft[payload.place] = { ...initialState[payload.place] }
        break

      // FLOW
      case FLOW.STEP:
        draft.showing = payload
        break

      case FLOW.USER:
        draft.userId = payload
        break

      case FLOW.ADDRESS:
        draft.user.address = payload
        break

      case FLOW.COMPLETE:
        draft.complete = true
        break

      case FLOW.ERROR:
        draft.error = payload
        draft.loading = false
        break

      case FLOW.LOADING:
        draft.loading = payload
        break

      case FLOW.CLEAR:
        return initialState
    }
  })

export default reducer
