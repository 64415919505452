import styled from 'styled-components'

import { spacing } from 'Global/theme'
import { weight } from 'Global/font'

export const Block = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: ${spacing.oneAndAHalf} 0;
`

export const Question = styled.p`
  ${weight.bold};
  font-size: 23pt;
  margin: ${spacing.twoAndAHalf} 0 ${spacing.one};
`

export const Answer = styled.p`
  font-size: 14pt;
  text-align: justify;

  margin: 0;

`
