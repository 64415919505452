import React from 'react'
import PropTypes from 'prop-types'

import { METADATAICONS } from 'Global/mapping'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'

import { Wrapper, Icon } from './style'

const IconList = ({ benefits, device, centered }) => (
  <Wrapper centered device={device}>
    {benefits.map(({ type, id, isIncluded }) => {
      const benefitIcon = METADATAICONS.find(el => el.id === type.id)
      const iconToUse = benefitIcon ? benefitIcon.icon : faInfoCircle
      const toolTipText = type.name

      return (
        <Icon key={id} included={isIncluded}>
          <FontAwesomeIcon icon={iconToUse} />
          <span className='tooltip'>{toolTipText}</span>
        </Icon>
      )
    }
    )}
  </Wrapper>
)

IconList.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      type: PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      }),
      isIncluded: PropTypes.bool
    })
  )
}

export default IconList
