import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Block = styled.div`
  box-shadow: 0 3px 6px 0 ${colors.grays[20]};
  width: 100%;

  max-width: 400px;

  height: auto;
  border-radius: ${spacing.one};
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Icon = styled.div`
  width: ${spacing.threeAndAHalf};
  min-width: ${spacing.threeAndAHalf};
  height: ${spacing.threeAndAHalf};
  min-height: ${spacing.four};
  display: flex;
  align-items: center;
  margin: ${spacing.oneAndAHalf} 0 ${spacing.oneAndAHalf} ${spacing.oneAndAHalf};

  ${({ device }) => device !== BREAKPOINTS.DESKTOP ? 'align-self: center' : ''};

  justify-content: center;

  > :first-child {
      height: 100%;
      width: 100%;
      color: ${colors.primary.light};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.two};
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  > p.title {
    ${weight.bold};
    ${family.barlow};
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? '20pt' : '24pt'};
    margin: 0;
  }

  > p.text {
    margin: ${spacing.two} 0 0;
  }
`
