import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { colors, spacing } from 'Global/theme'
import { weight } from 'Global/font'

export const Nav = styled.nav`
  background-color: ${colors.primary.dark};
  width: 50%;
  display: flex;
  min-height: 100%;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`

export const StyledLink = styled(Link)`
  height: 76px;
  min-height: 100%;
  justify-content: ${props => props.justifyContent || 'center'};
  margin-right: ${props => props.marginRight ? props.marginRight : '0'};
  flex-grow: 1;
  display: flex;
  align-items: center;

  color: ${colors.secondary.regular};
  ${weight.bold};
  font-size: small;
  text-decoration: none;

  background-color: ${props => props.selected ? colors.primary.regular : 'none'};

  :hover {
    background-color: ${props => !props.notSelectable ? colors.primary.hover : 'none'}
  }

  :focus, :active {
    outline: 0 !important;
  }
`

export const Background = styled.div`
  width: 100%;
  min-height: 100%;
  z-index: -9;
  position: absolute;
  background-color: ${colors.primary.dark};
`
