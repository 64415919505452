import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Wrapper = styled.div`
  padding:${spacing.five} ${spacing.oneAndAHalf};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 12;

height: 100%;


  > :first-child {
    margin-bottom: ${spacing.six};
  }
`

export const Section = styled.div`
  width: 100%;

  margin: ${spacing.oneAndAHalf} 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const PrimaryLink = styled(Link)`
  width: 100%;
  height: ${spacing.seven};
  padding: 0 ${spacing.two};

  border-bottom: solid thin ${colors.primary.regular};

  color: ${colors.primary.regular};
  font-size: 24pt;
  ${family.barlow};
  ${weight.semiBold};
  text-decoration: none;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const SecondaryLink = styled(Link)`
  width: 100%;
  height: ${spacing.five};
  padding: 0 ${spacing.two};

  border-bottom: solid thin ${colors.primary.regular};

  color: ${colors.primary.regular};
  font-size: 18pt;
  ${family.barlow};
  ${weight.regular};
  text-decoration: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const WhatsApp = styled.a`
  margin-top: auto;
  width: 100%;

  border-radius: ${spacing.one};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing.one} 0;

  background-color: ${colors.primary.regular};

  font-size: 18pt;
  color: ${colors.secondary.regular};

  text-decoration: none;

  > :first-child {
    margin-right: ${spacing.three};

  }
`
