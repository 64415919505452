import { CERTS, POLICY } from './types'

// POLICY ACTIONS

export const getPolicy = payload => ({
  type: POLICY.GET,
  payload
})

export const getPolicySuccess = payload => ({
  type: POLICY.SUCCESS,
  payload
})

export const getPolicyError = payload => ({
  type: POLICY.ERROR,
  payload
})

export const setReference = payload => ({
  type: POLICY.REFERENCE,
  payload
})

export const setCode = payload => ({
  type: POLICY.CODE,
  payload
})

export const clearPolicy = () => ({
  type: POLICY.CLEAR
})

export const POLICY_ACTIONS = {
  GET: getPolicy,
  SUCCESS: getPolicySuccess,
  ERROR: getPolicyError,
  REFERENCE: setReference,
  CODE: setCode,
  CLEAR: clearPolicy
}

// CERT ACTIONS

export const getCerificates = payload => ({
  type: CERTS.GET,
  payload
})

export const getCerificatesSuccess = payload => ({
  type: CERTS.SUCCESS,
  payload
})

export const getCerificatesError = payload => ({
  type: CERTS.ERROR,
  payload
})

export const CERT_ACTIONS = {
  GET: getCerificates,
  SUCCESS: getCerificatesSuccess,
  ERROR: getCerificatesError
}
