import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, useHistory } from 'react-router-dom'

import {
  HOME,
  ABOUT_US,
  SOA,
  CONTACT,
  CANCEL,
  QUOTE,
  RATES_FORM,
  GLOSSARY,
  HIRE,
  POLICY_FORM,
  CERTS,
  FAQ,
  INSURANCES,
  PRIVACY
} from 'Global/paths'

import { getProducts, getProviders } from 'Redux/Global/actions'

import { useSaga } from 'Redux/Global'

import Messages from 'Messages/'

import Footer from 'Components/Footer'
import Header from 'Components/Header'
import Start from 'Containers/Home'
import AboutUs from 'Containers/AboutUs'
import Soa from 'Containers/Soa'
import Contact from 'Containers/Contact'
import Cancel from 'Containers/Cancel'
import Quote from 'Containers/Quote'
import RatesForm from 'Containers/RatesForm'
import PolicyForm from 'Containers/PolicyForm'
import Glossary from 'Containers/Glossary'
import Hire from 'Containers/Hire'
import Certs from 'Containers/Certs'
import Faq from 'Containers/FAQ'
import Insurances from 'Containers/Insurance'
import Privacy from 'Containers/Privacy'
import Carousel from 'Components/Carousel'
import ScrollToTop from './scrollToTop'
import HeroImage from 'Components/HeroImage'

import insurances from 'Assets/insurances.jpg'
import contact from 'Assets/contact.jpg'
import aboutUs from 'Assets/about-us-1.jpg'
import faq from 'Assets/faq.jpg'
import { useWindowDimensions } from 'Components/WindowDimensions'
import { VerticalSeparation, Page } from './style'
const App = () => {
  useSaga()
  const device = useWindowDimensions()
  const dispatch = useDispatch()
  const history = useHistory()
  const [image, setImage] = useState(null)
  useEffect(() => {
    dispatch(getProducts())
    dispatch(getProviders())
  }, [dispatch])
  const showSlider = history.location.pathname === HOME ||
    history.location.pathname === '/' ||
    history.location.pathname === ''

  const goToQuote = id => {
    history.push({
      pathname: RATES_FORM,
      search: `?productId=${id}`
    })
  }

  useEffect(() => {
    const getImg = () => {
      switch (history.location.pathname) {
        case '/insurances':
          return insurances
        case '/about-us':
          return aboutUs
        case '/faq':
          return faq
        case '/contact':
          return contact
        default:
          return null
      }
    }
    setImage(getImg())
  }, [history.location.pathname])

  return (
    <>
      <ScrollToTop />
      <Messages />
      <Header />
      {
        showSlider && <Carousel action={goToQuote} />
      }
      {
        <VerticalSeparation device={device} />
      }
      {image && (
        <HeroImage
          src={image}
          color='primary'
        />
      )}
      <Page>

        <Switch>
          <Route path={HOME} component={Start} />
          <Route path={ABOUT_US} component={AboutUs} />
          <Route path={SOA} component={Soa} />
          <Route path={CONTACT} component={Contact} />
          <Route path={CANCEL} component={Cancel} />
          <Route path={QUOTE} component={Quote} />
          <Route path={RATES_FORM} component={RatesForm} />
          <Route path={POLICY_FORM} component={PolicyForm} />
          <Route path={GLOSSARY} component={Glossary} />
          <Route path={HIRE} component={Hire} />
          <Route path={CERTS} component={Certs} />
          <Route path={FAQ} component={Faq} />
          <Route path={INSURANCES} component={Insurances} />
          <Route path={PRIVACY} component={Privacy} />

          <Route component={Start} />
        </Switch>

        <Footer />
      </Page>
    </>
  )
}

export default App
