import React from 'react'

import soa from 'Assets/contact.jpg'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import ContactComponent from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Information from 'Components/Information'
import Advice from 'Components/Advice'

const Contact = () => {
  const device = useWindowDimensions()
  const subtitle = (
    <div>
      <p style={{ textAlign: 'left' }}>Estamos para ayudarte!</p>
      <p style={{ textAlign: 'left' }}>Envianos tu consulta y te responderemos a la brevedad posible.</p>
    </div>
  )
  return (
    <Page>
      <Information title='CONTACTO' subtitle={subtitle} />
      <ContactComponent />
      <Advice />
    </Page>
  )
}

export default Contact
