import { call, put, takeLatest, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import axios from 'Axios'

import { message } from 'Messages/factory'

import { QUOTE, HOME } from 'Global/paths'
import { DYNAMIC_FORM_SECTIONS } from 'Global/constants'

import { selectQuery } from 'Routes/Redux/selectors'

import { formatData } from 'Utils/formFormatter'

import GLOBAL_SELECTOR from 'Redux/Global/selectors'

import { FORM } from './types'
import SELECTOR from './selectors'
import { FORM_ACTIONS, PRODUCT_ACTIONS } from './actions'

const apiCalls = {
  getForm: productId => axios.get(`/form?product_id=${productId}`),
  getRates: data => axios.post('/rate/', data),
  getRate: id => axios.get(`/rate/?quick_rate_id=${id}`)
}

/**
 *
 * GETS THE DYNAMIC FORM BASE DATA
 */
export function * getForm ({ payload }) {
  try {
    const sections = Object.values(DYNAMIC_FORM_SECTIONS)
    const { quickRateId, productId } = yield select(selectQuery)

    if (!quickRateId && !productId) {
      message.warning('Hubo un problema')
      yield put(push(HOME))
    }

    let form
    if (quickRateId) {
      const res = yield call(apiCalls.getRate, quickRateId)
      form = res.form
      yield put(PRODUCT_ACTIONS.SET(res.product.id))
      yield put(FORM_ACTIONS.SAVE_SUCCESS(res.values))
    } else {
      yield put(PRODUCT_ACTIONS.SET(productId))
      form = yield call(apiCalls.getForm, productId)
    }

    const formatted = formatData(form)
    formatted.actives = sections.filter(section => !!formatted[section])
    yield put(FORM_ACTIONS.GET_SUCCESS({ forms: formatted }))
  } catch (err) {
    yield put(FORM_ACTIONS.GET_ERROR(err))
  }
}

export function * saveValues ({ payload }) {
  try {
    yield put(FORM_ACTIONS.SAVE_SUCCESS(payload))

    const productId = yield select(SELECTOR.PRODUCT_ID)

    const providers = yield select(GLOBAL_SELECTOR.PROVIDERS)

    const data = {
      productId: Number(productId),
      getRateJobs: true,
      providers: providers.map(({ id }) => ({ id })),
      ...payload
    }

    const res = yield call(apiCalls.getRates, data)

    yield put(push({
      pathname: QUOTE,
      search: `?quickRateId=${res.id}`
    }))
  } catch (err) {
    yield put(FORM_ACTIONS.SAVE_ERROR(err))
  }
}

export default function * saga () {
  yield takeLatest(FORM.LOAD, getForm)
  yield takeLatest(FORM.SAVE, saveValues)
}
