import styled from 'styled-components'

import { colors } from 'Global/theme'
import { getSize } from 'Utils/conversions'

export const MyCard = styled.div`
  height: ${({ height }) => getSize(height)};
  width: ${({ width, block }) => {
    if (block) return '100%'
    else return getSize(width)
  }};

  background-color: ${({ color }) => {
    switch (color) {
      case 'white': return colors.background.white
      case 'secondary': return colors.secondary.regular
      default: return colors.primary.regular
    }
  }};
  border-radius: 8px;

  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};

  color: ${({ color }) => {
    switch (color) {
      case 'secondary': return colors.primary.regular
      case 'white': return colors.primary.regular
      default: return colors.secondary.regular
    }
  }};

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
