import React from 'react'

import { Container, MainContainer, List, Title } from './style'
import Item from './Item'
import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'
import TextBlock from './TextBlock'
const Breadcrumbs = ({ action }) => {
  const device = useWindowDimensions()
  const title = 'Cotizá y contratá aquí el seguro de tu vehículo'
  const items = [
    {
      text: 'Ingresa tus datos'
    },
    {
      text: 'Compará opciones'
    },
    {
      text: 'Elegí la mejor para vos'
    },
    {
      text: 'Recibí tu contrato'
    }
  ]
  return (
    <MainContainer device={device}>
      <Container device={device}>
        {
          device !== BREAKPOINTS.MOBILE  && (<Title>{title}</Title>)
        }
        <List>
          {items.map((item, i) => <Item key={i} text={item.text} />)}
        </List>
      </Container>
    </MainContainer>
  )
}

export default Breadcrumbs
// 
// {device === BREAKPOINTS.MOBILE || device === BREAKPOINTS.TABLET ? <TextBlock action={action} /> : null}