import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

import { PLACES } from '../constants'

const selectUserRegistry = state => get(state, key, initialState)

export const selectProviderId = createSelector(
  selectUserRegistry, state => get(state, 'providerId', -1)
)

export const selectLoading = createSelector(
  selectUserRegistry, state => get(state, 'loading', '')
)

export const selectError = createSelector(
  selectUserRegistry, state => get(state, 'error', '')
)

export const selectShowing = createSelector(
  selectUserRegistry, state => get(state, 'showing', '')
)

export const selectCi = createSelector(
  selectUserRegistry, state => get(state, 'ci', '')
)

export const selectFirstName = createSelector(
  selectUserRegistry, state => get(state, 'firstName', '')
)

export const selectLastName = createSelector(
  selectUserRegistry, state => get(state, 'lastName', '')
)

export const selectBirthDate = createSelector(
  selectUserRegistry, state => get(state, 'birthDate', '')
)

export const selectEmail = createSelector(
  selectUserRegistry, state => get(state, 'email', '')
)

export const selectPhone = createSelector(
  selectUserRegistry, state => get(state, 'phone', '')
)

export const selectCellphone = createSelector(
  selectUserRegistry, state => get(state, 'cellphone', '')
)

export const selectCountry = createSelector(
  selectUserRegistry, state => get(state, `${PLACES.COUNTRY}`, {})
)

export const selectDepartment = createSelector(
  selectUserRegistry, state => get(state, `${PLACES.DEPARTMENT}`, {})
)

export const selectCity = createSelector(
  selectUserRegistry, state => get(state, `${PLACES.CITY}`, {})
)

export const selectStreet = createSelector(
  selectUserRegistry, state => get(state, `${PLACES.STREET}`, {})
)

export const selectNumber = createSelector(
  selectUserRegistry, state => get(state, 'number', '')
)

export const selectAptNumber = createSelector(
  selectUserRegistry, state => get(state, 'aptNumber', '')
)

export const selectComplete = createSelector(
  selectUserRegistry, state => get(state, 'complete', false)
)

export const selectUser = createSelector(
  selectUserRegistry, state => get(state, 'userId', undefined)
)

export default {
  PROVIDER_ID: selectProviderId,
  LOADING: selectLoading,
  ERROR: selectError,
  SHOWING: selectShowing,
  CI: selectCi,
  FIRST_NAME: selectFirstName,
  LAST_NAME: selectLastName,
  BIRTH_DATE: selectBirthDate,
  EMAIL: selectEmail,
  PHONE: selectPhone,
  CELLPHONE: selectCellphone,
  COUNTRY: selectCountry,
  DEPARTMENT: selectDepartment,
  CITY: selectCity,
  STREET: selectStreet,
  NUMBER: selectNumber,
  APT_NUMBER: selectAptNumber,
  COMPLETE: selectComplete,
  USER: selectUser
}
