export const key = 'quotes'

export const CATEGORY = {
  LOAD: 'INSURANCE_QUOTES/CATEGORY/LOAD',
  SUCCESS: 'INSURANCE_QUOTES/CATEGORY/SUCCESS',
  ERROR: 'INSURANCE_QUOTES/CATEGORY/ERROR'
}

export const RATES = {
  LOAD: 'INSURANCE_QUOTES/RATES/LOADING',
  SUCCESS: 'INSURANCE_QUOTES/RATES/SUCCESS',
  ERROR: 'INSURANCE_QUOTES/RATES/ERROR',
  SELECT: 'INSURANCE_QUOTES/RATES/SELECT',
  CREATE: 'INSURANCE_QUOTES/RATES/CREATE',
  CLEAR: 'INSURANCE_QUOTES/RATES/CLEAR',
  WAIT: 'INSURANCE_QUOTES/RATES/WAIT'
}

export const PROVIDER_RATE = {
  LOAD: 'INSURANCE_QUOTES/PROVIDER_RATE/LOADING',
  SUCCESS: 'INSURANCE_QUOTES/PROVIDER_RATE/SUCCESS',
  ERROR: 'INSURANCE_QUOTES/PROVIDER_RATE/ERROR',
  STOP: 'INSURANCE_QUOTES/PROVIDER_RATE/STOP',
  NO_RATES: 'INSURANCE_QUOTES/PROVIDER_RATE/NO_RATES',
  REMOVE_NO_RATES: 'INSURANCE_QUOTES/PROVIDER_RATE/REMOVE_NO_RATES'
}

export default key
