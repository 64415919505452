import React from 'react'
import PropTypes from 'prop-types'

import { useWindowDimensions } from 'Components/WindowDimensions'

import Title from 'Components/Title'

import { Wrapper } from './style'

const Information = ({ title, subtitle, info }) => {
  const device = useWindowDimensions()

  return (
    <Wrapper device={device}>
      <Title
        title={title}
        subtitle={subtitle}
      />

      {Array.isArray(info) ? (
        info.map((t, i) => <p key={i}>{t}</p>)
      ) : (
        info && <p>{info}</p>
      )}

    </Wrapper>
  )
}

Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  info: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ])
}

export default Information
