/* eslint-disable */
import {
  faEye,
  faStar,
  faUser,
  faLock,
  faMagic,
  faChild,
  faTrophy,
  faDesktop,
  faBullseye,
  faSuitcase
} from '@fortawesome/free-solid-svg-icons'

export default [
  {
    icon: faDesktop,
    title: 'Contratación Online',
    description: 'SegurosOnline te ofrece la posibilidad de contratar un seguro desde el primer momento y desde la tranquilidad de tu casa. ¡Sin necesidad de registrarte! Solo tienes que introducir unos pocos datos, obtienes un presupuesto y contratas de forma online o telefónicamente el seguro que mejor se adapte a vos. Es muy simple y solo te toma un par de minutos'
  },
  {
    icon: faMagic,
    title: 'Fácil',
    description: 'Simple, sencilla, directa. Sin necesidad de largos cuestionarios, basta rellenar unos pocos datos, sin identificación personal alguna, pulsar aceptar y el resultado de la cotización salta a tu pantalla en unos pocos segundos. Las características funcionales y de ahorro de tiempo de SegurosOnline, le permitirán una introducción de datos mínima para la presentación de una oferta clara y comparable, facilitándote una toma de decisión. Ante cualquier duda ya sea por chat on-line o por línea telefónica lo guiarán gustosamente'
  },
  {
    icon: faStar,
    title: 'Estándar',
    description: 'Te encuentras en un sitio web, dinámico, para que fácilmente puedas optar por la cobertura que más se adapta a su necesidad y posibilidad. Enlazamos online a las compañías de seguros seleccionadas, agilizamos los procesos y minimizamos su trabajo de búsqueda y comparación. El uso de un estándar del sistema te evita equívocos a la hora de comparar las diferentes coberturas. También te evita interpretaciones erróneas de coberturas, exclusiones, y las condiciones de diferentes contratos.'
  },
  {
    icon: faUser,
    title: 'Atención Personalizada Post-contratación',
    description: 'Nuestros profesionales atienden y resuelven tus dudas de forma personalizada dedicándote todo el tiempo que precises. Además, SegurosOnline te ayudará a ser mucho más ágil en el tiempo de respuesta y acción ante emergencias o situaciones críticas. Nosotros siempre estamos y estaremos a tu lado, desde el momento que ingresas a nuestro sitio y fundamentalmente cuando te surja cualquier percance.'
  },
  {
    icon: faSuitcase,
    title: 'Garantía de Compromiso',
    description: 'El tener contratado un buen seguro es clave en tu tranquilidad. Trabajamos con las mejores aseguradoras para ofrecerte el seguro que mejor se adapte a tus necesidades. Tenemos en cuenta las opiniones de nuestros clientes y tanto para ellos como para ti hemos desarrollado segurosonline.com.uy, la web más eficaz e innovadora del mercado. Cuando es necesario solventar un problema, lo último que deseas es que te pongan obstáculos inoportunos, por ello, nuestros profesionales realizan todas las acciones necesarias para resolver con la mayor eficiencia tu problema.'
  },
  {
    icon: faTrophy,
    title: 'Solvencia, profesionalidad, fiabilidad y confianza',
    description: 'Uno de nuestros principales principios es la seriedad en todos nuestros actos, perseguimos con ello tu credibilidad. Porque la relación lleva a la confianza y el creciente conocimiento mutuo, nos permitirá ofrecerte los mejores productos. Nuestra experiencia y profesionalidad sumada a la solvencia de las compañías seleccionadas, primeras marcas nacionales y pertenecientes a los primeros grupos internacionales en seguros, es la garantía que tú necesitas.'
  },
  {
    icon: faBullseye,
    title: 'Excelentes Coberturas y Servicios',
    description: 'La selección de la compañía, nuestras normas de calidad propias, la dedicación de nuestros profesionales, el conocimiento y estudio permanente del mercado nos permiten disponer de unas ofertas en coberturas adaptadas a sus necesidades. Todos nuestros procesos se revisan continua y exhaustivamente, incluyendo toda la documentación que le entregamos al contratar la póliza. Disponemos de diferentes tecnologías integradas entre sí para darte siempre el mejor servicio'
  },
  {
    icon: faLock,
    title: 'Seguridad',
    description: 'El acceso a los servicios transaccionales y aquellos que incluyen la captura de datos personales cuando contratas con nosotros se realiza en un entorno seguro utilizando protocolos por demás confiables. El servidor seguro establece una conexión de modo que la información se transmite cifrada. Esto asegura que el contenido transmitido es sólo inteligible entre tu computadora y nuestro servidor.'
  },
  {
    icon: faEye,
    title: 'Privacidad',
    description: 'Manteniendo siempre nuestra independencia, estamos integrados con las aseguradoras para permitir trabajar sin esfuerzos adicionales a nuestros profesionales con información online sobre las situaciones a analizar. Tenemos establecidos los mejores flujos de trabajo mediante la integración con los centros operativos de siniestros, respetando en todo momento tu privacidad.'
  },
  {
    icon: faChild,
    title: 'La última razón la tienes tú',
    description: 'Esperamos que estas reflexiones te animen a dar el click y contratar a través de SegurosOnline el mejor seguro que se adapte a tus necesidades. Y si aún no te animas a contratar con nosotros te agradecemos el tiempo que le has dedicado a conocer nuestra Web.'
  }
]
