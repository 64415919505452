export const title =
  [
    `Cotizá y contratá
     el seguro de tu
     vehículo, online.`,
    `En 1 minuto
    compará y elegí el mejor seguro`,
    `El seguro
    que se adapta
    a lo que necesitas`

  ]
export const subtitle =
  [
    'Cotizar',
    'Cotizar',
    'Cotizar'
  ]
export const description =
  [
    'En 1 minuto!',
    'En 1 minuto!',
    'En 1 minuto!'
  ]
