import { put, takeLatest, select, call } from 'redux-saga/effects'

import axios from 'Axios'

import { SUCCESS_MESSAGES } from 'Global/messages'
import { message } from 'Messages/factory'

import { CANCEL_POLICY } from './types'
import { CANCEL_POLICY_ACTIONS } from './actions'
import SELECTORS from './selectors'

export const apiCalls = {
  sendCancelPolicy: data => axios.post('/cancel_policy', data)
}

export function * sendCancelPolicy () {
  try {
    const productType = yield select(SELECTORS.PRODUCT_TYPE)
    const email = yield select(SELECTORS.EMAIL)
    const tel = yield select(SELECTORS.TEL)
    const documentId = yield select(SELECTORS.DOCUMENT_ID)
    const policyId = yield select(SELECTORS.POLICY_ID)

    yield call(apiCalls.sendCancelPolicy, { productType, email, tel, documentId, policyId })
    yield put(CANCEL_POLICY_ACTIONS.SUCCESS())
    message.success({
      message: SUCCESS_MESSAGES.CANCEL_POLICY,
      time: 8000
    })
  } catch (err) {
    yield put(CANCEL_POLICY_ACTIONS.ERROR(err))
  }
}

export default function * saga () {
  yield takeLatest(CANCEL_POLICY.SEND, sendCancelPolicy)
}
