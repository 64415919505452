import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import {
  Line,
  Primary,
  Container,
  Separator
} from './style'

const Title = ({ title, subtitle, line, color }) => {
  const device = useWindowDimensions()

  return (
    <>
      <Container color={color} device={device}>
        <Primary>
          <p className='title'>{title}</p>
          {subtitle && device === BREAKPOINTS.DESKTOP && <Separator />}
        </Primary>
        {subtitle && <p className='subtitl'>{subtitle}</p>}
      </Container>
      {line && <Line color={color} />}
    </>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  line: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary'])
}

export default Title
