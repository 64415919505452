import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
export const FixedImage = styled.img`
  object-fit: scale-down;
  width: 100%;
`
export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  width: 100%;
  ${props => props.width < 900 ? 'flex-direction: column;' : ''}

`

export const ImageContainer = styled.div`
  display: flex;
  width: 100%;
`
export const Spacing = styled.div`
  width: 100%;
  height: ${props=> props.spacing ? props.spacing : '50px'}
`
export const Title = styled.p`
  color: #00725d;
  font-weight: bold;
  font-size: 40px;
  text-align:left;
  width: 100%;
  margin: 0;
`
export const Subtitle = styled.p`
  color: #61bd75;
  font-weight: bold;
  font-size: 44px;
  text-align: left;
  width: 100%;
  margin: 0;

`

export const TextContainer = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
