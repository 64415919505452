import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

export const Wrapper = styled.div`
  
  top: 100%;
  ${props => props.margin ? `margin: ${props.margin};` : ''}

  z-index: 3;
  max-width: 270px;
  min-width: 100px;
`

export const Select = styled.div`
  width: 100%;
  height: 200px;
  max-height: ${({ expanded }) => expanded ? '192px' : '0px'};

  overflow-y: hidden;
  transition: max-height 0.5s;
`

export const Option = styled.div`
  width: 100%;
  
  height: ${spacing.eight};

  border-left: solid thin ${colors.grays[10]};
  border-right: solid thin ${colors.grays[10]};

  background-color: ${colors.background.gray};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  overflow: hidden;

  padding: 0 ${spacing.one};
  > :first-child {
    margin-right: ${spacing.one};
  }

  > img {
    max-height: ${props => props.device === BREAKPOINTS.DESKTOP || props.device === BREAKPOINTS.TABLE ? '80%' : '0px'};
    transition: max-height 0.2s;
  }

  p {
    overflow-wrap: anywhere;
    margin: 0;
    ${family.barlow};
    color: ${colors.primary.dark};
    font-size: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 12 : 10}pt;
  }

  :hover {
    background-color: ${colors.background.white};
  }

  & .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`
