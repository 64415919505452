import React, { useEffect, useState } from 'react'
import { Container, ExternalContainer, SliderContainer } from './styles'
// desktop
import slide1 from '../../../assets/slides/slide1.jpg'
import slide2 from '../../../assets/slides/slide2.jpg'
import slide3 from '../../../assets/slides/slide3.jpg'
// tablet
// import slide1Tablet from '../../../assets/slides/slide1_mobile.jpg'
// import slide2Tablet from '../../../assets/slides/slide2_mobile.jpg'
// import slide3Tablet from '../../../assets/slides/slide3_mobile.jpg'
// mobile
import slide1Mobile from '../../../assets/slides/slide1_mobile_small.jpg'
import slide2Mobile from '../../../assets/slides/slide2_mobile_small.jpg'
import slide3Mobile from '../../../assets/slides/slide3_mobile_small.jpg'

import Slider from 'react-slick'
import { useWindowDimensions } from 'Components/WindowDimensions'
import { BREAKPOINTS } from 'Global/constants'
import TextBlock from './TextBlock'

export const Carousel = ({ action }) => {
  const device = useWindowDimensions()
  const [slides, setSlides] = useState([])
  useEffect(() => {
    if (device === BREAKPOINTS.DESKTOP) {
      setSlides([
        {
          image: slide1,
          component: <TextBlock device={device} type={0} action={action} />
        },
        {
          image: slide2,
          component: <TextBlock device={device} type={1} action={action} />
        },
        {
          image: slide3,
          component: <TextBlock device={device} type={2} action={action} />
        }
      ])
    }
    else if (device === BREAKPOINTS.TABLET) {
      setSlides([
        {
          image: slide1,
          component: <TextBlock device={device} type={0} action={action} />
        },
        {
          image: slide2,
          component: <TextBlock device={device} type={1} action={action} />
        },
        {
          image: slide3,
          component: <TextBlock device={device} type={2} action={action} />
        }
      ])
    }
    else {
      setSlides([
        {
          image: slide1Mobile,
          component: <TextBlock device={device} type={0} action={action} />
        },
        {
          image: slide2Mobile,
          component: <TextBlock device={device} type={1} action={action} />
        },
        {
          image: slide3Mobile,
          component: <TextBlock device={device} type={2} action={action} />
        }
      ])
    }
  }, [device])

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  };
  return (
    <Container device={device}>
      <Slider {...settings}>
        {
          slides.map((s, i) => (
            <React.Fragment key={i}>
              <SliderContainer device={device} key={i} image={s.image}>
                {
                  device !== BREAKPOINTS.MOBILE && s.component
                }
              </SliderContainer>
              {
                device === BREAKPOINTS.MOBILE && <ExternalContainer>{s.component}</ExternalContainer>
              }
            </React.Fragment>
          ))
        }
      </Slider>
    </Container>)
}
export default Carousel
