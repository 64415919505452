import styled from 'styled-components'

import { spacing } from 'Global/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Image = styled.div`
  width: ${spacing.elevenAndAHalf};
  height: ${spacing.sixAndAHalf};
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 100%;
  }
`
