import { FORM, PRODUCT } from './types'

export const getForm = payload => ({
  type: FORM.LOAD,
  payload
})

export const getFormSuccess = payload => ({
  type: FORM.LOAD_SUCCESS,
  payload
})

export const getFormError = payload => ({
  type: FORM.LOAD_ERROR,
  payload
})

export const saveFormValues = payload => ({
  type: FORM.SAVE,
  payload
})

export const saveFormValuesSuccess = payload => ({
  type: FORM.SAVE_SUCCESS,
  payload
})

export const saveFormValuesError = payload => ({
  type: FORM.SAVE_ERROR,
  payload
})

export const FORM_ACTIONS = {
  GET: getForm,
  GET_SUCCESS: getFormSuccess,
  GET_ERROR: getFormError,
  SAVE: saveFormValues,
  SAVE_SUCCESS: saveFormValuesSuccess,
  SAVE_ERROR: saveFormValuesError
}

export const setProductId = payload => ({
  type: PRODUCT.SET,
  payload
})

export const PRODUCT_ACTIONS = {
  SET: setProductId
}
