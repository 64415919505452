import React, { useEffect, useState } from 'react'
import { Container, Title, Subtitle, Description } from './styles'
import { title, subtitle, description } from './helper'
import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'
export const TextBlock = ({ type, action }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const isMobile = useWindowDimensions()
  const actionId = 1
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }
  , [])
  return (
    <Container device={width} isMobile={isMobile === BREAKPOINTS.MOBILE}>
      <Title device={width} isMobile={isMobile === BREAKPOINTS.MOBILE} type={type}>{title[type]}</Title>
      <Subtitle isMobile={isMobile === BREAKPOINTS.MOBILE} onClick={() => action(actionId)} device={width} type={type}><p>{subtitle[type]}</p></Subtitle>
      <Description isMobile={isMobile === BREAKPOINTS.MOBILE} device={width} type={type}>{description[type]}</Description>
    </Container>
  )
}

export default TextBlock
