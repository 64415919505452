import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faDotCircle } from '@fortawesome/free-solid-svg-icons'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { Wrapper, Title, Block, Icon, Container } from './style'

const getIcon = type => {
  switch (type) {
    case 'check': return faCheck
    case 'cross': return faTimes
    case 'circle': return faDotCircle
    default: return undefined
  }
}

const Faq = ({ question, items }) => {
  const device = useWindowDimensions()

  return (
    <Wrapper device={device}>
      <Title>{question}</Title>
      {items.map((item, i) => {
        const icon = getIcon(item.type)
        return (
          <Block key={i} device={device}>
            <Icon>
              {icon && (
                <FontAwesomeIcon icon={icon} />
              )}
            </Icon>
            <Container>
              <p>{item.text}</p>
            </Container>
          </Block>
        )
      })}
    </Wrapper>
  )
}

Faq.propTypes = {
  question: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      type: PropTypes.oneOf(['check', 'cross', 'circle', 'none'])
    })
  )
}

Faq.defaultProps = {
  items: []
}

export default Faq
