import { INPUT_TYPES } from 'Global/constants'

export const checkName = name => {
  const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g
  return !!name.trim().match(regex)
}

export const checkPhone = phone => {
  if (!phone) return true

  const number = phone.replace(/[^0-9a-zA-Z]/g, '')
  if (number.length !== 8 || (number[0] !== '4' && number[0] !== '2')) {
    return false
  }

  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
  return !!number.match(regex)
}

export const checkCellphone = phone => {
  if (!phone) return true

  const number = phone.replace(/[^0-9a-zA-Z]/g, '')
  if (number.length !== 9 || number[0] !== '0' || number[1] !== '9') {
    return false
  }

  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
  return !!number.match(regex)
}

export const checkEmail = email => {
  // eslint-disable-next-line
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email.match(regex)
}

export const checkNumber = number => {
  return !isNaN(number)
}

export const checkCI = ci => {
  const check = [2, 9, 8, 7, 6, 3, 4].reverse()

  ci = ci.replace(/[ /.-]/gi, '').split('').map(n => Number(n))

  if (ci.length < 7) return false

  const v = ci.pop()
  ci.reverse()

  let sum = 0

  for (let i = 0; i < ci.length; i++) {
    if (isNaN(ci[i])) return false

    sum += ci[i] * check[i]
  }

  const top = Math.ceil(sum / 10) * 10
  return top - sum === v
}

export const checkDate = date => {
  const d = new Date(date)
  // eslint-disable-next-line
  return d.getTime() === d.getTime()
}

export const checkField = ({ type, value, required }) => {
  if (required && !value) return false

  switch (type) {
    case INPUT_TYPES.NAME:
      return checkName(value)
    case INPUT_TYPES.EMAIL:
      return checkEmail(value)
    case INPUT_TYPES.TEL:
      return checkPhone(value)
    case INPUT_TYPES.CELLPHONE:
      return checkCellphone(value)
    case INPUT_TYPES.NUMBER:
      return checkNumber(value)
    case INPUT_TYPES.CI:
      return checkCI(value)
    case INPUT_TYPES.DATE:
      return checkDate(value)
    case INPUT_TYPES.TEXT:
    case INPUT_TYPES.PASSWORD:
      return true
    case INPUT_TYPES.SELECT:
    case INPUT_TYPES.ASYNC_SELECT:
    case INPUT_TYPES.BOOLEAN:
      return true
    case INPUT_TYPES.TEXT_AREA:
      return true
    default:
      return true
  }
}
