import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useWindowDimensions } from 'Components/WindowDimensions'

import { Block, Icon, Container } from './style'

const Card = ({ icon, title, description }) => {
  const device = useWindowDimensions()

  return (
    <Block device={device}>
      <Icon device={device}>
        <FontAwesomeIcon icon={icon} />
      </Icon>

      <Container device={device}>
        <p className='title'>{title}</p>
        <p className='text'>{description}</p>
      </Container>

    </Block>
  )
}

Card.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string
}

export default Card
