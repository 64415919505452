import React from 'react'
import PropTypes from 'prop-types'
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledDisclaimer } from './style.js'

const Disclaimer = ({ textNotice }) => (
  <StyledDisclaimer>
      <div>
        <span className='info-icon'>
          <FontAwesomeIcon icon={faInfoCircle} />{" "}
        </span>
      </div>
      <div>
        <p>{textNotice}</p>
      </div>
  </StyledDisclaimer>
)

Disclaimer.propTypes = {
  textNotice: PropTypes.string,
}

export default Disclaimer
