import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #79b681;
  border-radius: 10px;
  width:100%;
  height: 150px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  justify-content: center;
  align-items: flex-start;
  line-height: 2px;
  padding-left: 10px;
  color: black;
`
export const Span = styled.span`
  color: #79b681;
`
