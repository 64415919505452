export const key = 'ratesForm'

export const FORM = {
  LOAD: 'RATES_FORM/DYNAMIC_FORM/LOAD',
  LOAD_SUCCESS: 'RATES_FORM/DYNAMIC_FORM/LOAD_SUCCESS',
  LOAD_ERROR: 'RATES_FORM/DYNAMIC_FORM/LOAD_ERROR',
  SAVE: 'RATES_FORM/DYNAMIC_FORM/SAVE',
  SAVE_SUCCESS: 'RATES_FORM/DYNAMIC_FORM/SAVE_SUCCESS',
  SAVE_ERROR: 'RATES_FORM/DYNAMIC_FORM/SAVE_ERROR'
}

export const PRODUCT = {
  SET: 'RATES_FORM/PRODUCT/SET_P_ID'
}

export default key
