import React from 'react'
import PropTypes from 'prop-types'

import { MyCard } from './style'

const Card = ({ width, height, color, block, onClick, children }) => (
  <MyCard
    width={width}
    height={height}
    block={block}
    color={color}
    onClick={onClick}
  >
    {children}
  </MyCard>
)

Card.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
  block: PropTypes.bool,
  children: PropTypes.any
}

Card.defaultProps = {
  color: 'primary'
}

export default Card
