import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import GLOBAL_SELECTOR from 'Redux/Global/selectors'

import { BREAKPOINTS, BAISC_INPUT_TYPES } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Checkbox from 'Components/Checkbox'
import Button from 'Components/Button'
import { Input } from 'Components/Input'

import { FIELD_ACTIONS, CANCEL_POLICY_ACTIONS } from '../../Redux/actions'
import SELECTORS from '../../Redux/selectors'

import { Body, Inputs, Column } from './style'

const Form = () => {
  const device = useWindowDimensions()

  const dispatch = useDispatch()

  const products = useSelector(GLOBAL_SELECTOR.PRODUCTS)

  const productType = useSelector(SELECTORS.PRODUCT_TYPE)
  const email = useSelector(SELECTORS.EMAIL)
  const tel = useSelector(SELECTORS.TEL)
  const documentId = useSelector(SELECTORS.DOCUMENT_ID)
  const policyId = useSelector(SELECTORS.POLICY_ID)

  const handleSubmit = () => {
    dispatch(CANCEL_POLICY_ACTIONS.SEND())
  }

  const handleInputChange = ({ name, value }) => {
    dispatch(FIELD_ACTIONS.CHANGE({
      value,
      name
    }))
  }

  return (
    <Body device={device}>
      <Inputs>
        <Column device={device}>
          <Input
            name='email'
            label='Email'
            value={email}
            type={BAISC_INPUT_TYPES.EMAIL}
            onChange={handleInputChange}
            required
          />

          <Input
            name='tel'
            label='Teléfono'
            value={tel}
            type={BAISC_INPUT_TYPES.TEL}
            onChange={handleInputChange}
            required
          />

          <Checkbox
            name='productType'
            label='Ramo'
            value={productType}
            options={products}
            onChange={handleInputChange}
            required
          />
        </Column>

        <Column device={device}>
          <Input
            name='policyId'
            label='Número de póliza'
            value={policyId}
            type={BAISC_INPUT_TYPES.NUMBER}
            onChange={handleInputChange}
            required
          />
          <Input
            name='documentId'
            label='Documento'
            value={documentId}
            type={BAISC_INPUT_TYPES.CI}
            onChange={handleInputChange}
            required
          />
        </Column>
      </Inputs>

      <Button className='button' onClick={handleSubmit} block={device !== BREAKPOINTS.DESKTOP}>
        Enviar
      </Button>
    </Body>
  )
}

export default Form
