import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const LoaderContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`
