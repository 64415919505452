/* eslint-disable */
export default [
  {
    question: '¿Qué es SegurosOnline?',
    answer: 'SegurosOnline es el 1er broker de seguros online líder en Uruguay. En forma simple y rápida, te brindamos una solución integral, ofreciendo una multi-cotización de seguros online, brindando asesoramiento en la contratación, y ocupándonos de toda la gestión de siniestroso cualquier trámite mientras la póliza esté vigente.'
  },

  {
    question: '¿Desde cuándo cuento con la cobertura que elegí?',
    answer: 'A partir que finalices el ingreso y confirmes toda la información que se te ha solicitado.'
  },

  {
    question: '¿Cuándo me llega la póliza del seguro?',
    answer: 'Recibirás un certificado de cobertura dentro de las 24-48 horas posteriores a la solicitud de la póliza. La póliza de seguro te llegará a tu correo electrónico luego de las 48 hs.'
  },

  {
    question: '¿Qué es una póliza?',
    answer: 'La póliza de seguro es el documento que acredita la formalización de un contrato de seguro, y fija los términos del producto que adquiriste. Como asegurado, tu obligación es pagar la factura acorde a la forma de pago que elegiste.'
  },

  {
    question: 'Si tengo un siniestro, ¿estoy cubierto, aunque la cuota de mi seguro se debite a mes vencido?',
    answer: `Si la compañía de tu seguro presenta el débito en la entidad de tu banco a mes vencido, la cobertura financiera se mantiene, siempre y cuando el pago sea concretado.
    La cobertura se pierde una vez intentado el débito y rechazado por la entidad de tu banco. Es decir, si por algún motivo no se pudo efectuar el pago, ya sea porque se excedió el límite de la tarjeta, porque la misma está vencida, o cualquier otro motivo estarás circulando sin cobertura.`
  },

  {
    question: '¿Qué es un deducible?',
    answer: 'El deducible es la suma de dinero que siempre estará a tu cargo en caso de que tu auto sufra algún daño.'
  },

  {
    question: '¿La póliza del seguro se renueva automáticamente?',
    answer: 'Sí. En caso de que no lo desees, comunícate por email o teléfono para gestionar la baja de la cobertura.'
  },

  {
    question: '¿Qué es la Cobertura Todo Riesgo?',
    answer: 'Al asegurar tu automóvil, se considera que el Seguro Todo Riesgo es la póliza que, además de protegerte por los daños o lesiones a terceros, cubre los daños propios, es decir, los que sufra el propio coche en un siniestro. Adicionalmente estás cubierto con la Cobertura Seguro Obligatorio establecida por ley.'
  },

  {
    question: '¿Qué es la Cobertura Triple?',
    answer: 'Esta cobertura cubre los daños o lesiones que cause tu vehículo asegurado a terceros ya sean en sus bienes o personas y también está amparado el incendio y/o robo del vehículo. Adicionalmente estás cubierto con la Cobertura Seguro Obligatorio establecida por ley. En esta cobertura no quedan amparados los daños materiales del vehículo asegurado.'
  },

  {
    question: '¿Qué es la Cobertura Responsabilidad Civil?',
    answer: 'Esta cobertura solo cubre los daños o lesiones que cause tu vehículo que está asegurado a terceros ya sean en sus bienes o personas, quedan excluidos el incendio y/o robo del vehículo y sus daños. Adicionalmente estás cubierto con la Cobertura Seguro Obligatorio establecida por ley.'
  },

  {
    question: '¿Qué es un siniestro?',
    answer: 'Un siniestro es un elemento clave en el contrato de seguro. Se dice que se ha producido un siniestro cuando se ha producido alguno de los hechos asegurados que se contemplan en tu póliza de seguros.'
  },

  {
    question: '¿Cómo solicitar la cancelación, baja o renovación de mi póliza?',
    answer: 'Solicita la cancelación al whatsapp 099072440 o si prefieres a nuestro correo info@segurosonline.com.uy y nos contactaremos contigo para coordinarlo.'
  },

  {
    question: ' ¿Qué necesito si viajo a los Países del Mercosur?',
    answer: `Si viajas por algún país del Mercosur en tu automóvil, toma precauciones para dejar el vehículo a punto y así poder disfrutar del viaje sin mayores contratiempos.
    Cuando viajas fuera de Uruguay con el vehículo asegurado, no es necesario dar aviso previo a la aseguradora, ya que la cobertura es automática.
    Sí las aseguradoras amplían la cobertura a países limítrofes sin costo adicional. Las sumas aseguradas y los límites máximos estarán estipuladas en tu póliza contratada.
    No olvides llevar toda la documentación requerida para circular y, sobre todas las cosas, tener el seguro al día.
    Será necesario llevar la tarjeta verde del Mercosur, la cual se entrega al asegurado junto con la póliza y la póliza del seguro.
    Asimismo, ten a mano los teléfonos de los representantes o compañías asociadas en cada país, donde deberás llamar en caso de accidente.`
  },

  {
    question: '¿Qué sucede con el seguro si estoy por vender o ya vendí mi auto?',
    answer: `Si estás por vender tu auto o ya lo vendiste, ten en cuenta lo siguiente:
    Si ya lo vendiste y no nos avisaste, llámanos cuanto antes para solicitar la baja correspondiente de tu seguro.
    Si compraste un auto nuevo, ingresá a SegurosOnline para cotizar tu nuevo seguro o llámanos y te ayudaremos a elegir tu nueva mejor opción.`
  }
]
