import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { spacing } from 'Global/theme'

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: ${({ device }) => device === BREAKPOINTS.MOBILE ? 'column' : 'row'};
  justify-content: space-evenly;
  align-items: center;

  padding: 0 0 ${spacing.five};

  > :last-child {
    margin-left: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return 0
        case BREAKPOINTS.TABLET: return spacing.three
        default: return spacing.five
      }
    }};

    margin-top: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 0 : spacing.four}
  }
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return spacing.four
        case BREAKPOINTS.TABLET: return spacing.fiveAndAHalf
        default: return spacing.seven
      }
    }};
  }
`
