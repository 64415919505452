import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import covImg1 from 'Assets/Icono-SEGURO-RESP-CIVIL-2.svg'
import covImg2 from 'Assets/Icono-SEGURO-TRIPLE-2.svg'
import covImg3 from 'Assets/Icono-SEGURO-TODORIESGO-2.svg'

import { COVERAGE } from 'Global/constants'
import { COVERAGE_MAPPING } from 'Global/mapping'

import { useWindowDimensions } from 'Components/WindowDimensions'

import Tag from 'Components/Tag'

import { Wrapper, Select, Option } from './style'

const CoverageDropdown = ({ onClick, margin }) => {
  const device = useWindowDimensions()

  const ref = useRef(null)
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => setExpanded(!expanded)
  const closeExpand = () => setExpanded(false)

  const handleSelect = val => {
    closeExpand()
    onClick(val)
  }

  const handleClickOutside = ({ target }) => {
    if (ref.current && !ref.current.contains(target)) {
      closeExpand()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const basicText = COVERAGE_MAPPING[COVERAGE.BASIC.label]
  const mediumText = COVERAGE_MAPPING[COVERAGE.MEDIUM.label]
  const totalText = COVERAGE_MAPPING[COVERAGE.TOTAL.label]

  return (
    <Wrapper margin={margin} ref={ref}>
      <Select expanded={expanded}>
        <Option onClick={() => handleSelect(COVERAGE.BASIC)} device={device}>
          <img src={covImg1} />
          <div className='text'>
            <p>{basicText}</p>
          </div>
        </Option>

        <Option onClick={() => handleSelect(COVERAGE.MEDIUM)} device={device}>
          <img src={covImg2} />
          <div className='text'>
            <p>{mediumText}</p>
          </div>
        </Option>

        <Option onClick={() => handleSelect(COVERAGE.TOTAL)} device={device}>
          <img src={covImg3} />
          <div className='text'>
            <p>{totalText}</p>
          </div>
        </Option>
      </Select>

      <Tag handleClick={toggleExpand}>
        <p>Cambiar cobertura</p>
        <FontAwesomeIcon icon={faSyncAlt} />
      </Tag>
    </Wrapper>
  )
}

CoverageDropdown.propTypes = {
  onClick: PropTypes.func
}

export default CoverageDropdown
