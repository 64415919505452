import React from 'react'
import { Container, Item } from './styles'
import { faWhatsapp, faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SocialNetworks = () => {

  const onClickWhatsapp = () => {
    window.open('https://api.whatsapp.com/send?phone=59899072440')
  }
  const onClickInstagram = () => {
    window.open('https://www.instagram.com/segurosonline.uy')
  }
  const onClickFacebook = () => {
    window.open('https://www.facebook.com/segurosonline.uy')
  }
  const onClickLinkedin = () => {
    window.open('https://www.linkedin.com/company/seguros-online-uy/')

  }
  return (
    <Container>
      <Item onClick={onClickWhatsapp}><FontAwesomeIcon icon={faWhatsapp} /></Item>
      <Item onClick={onClickInstagram}><FontAwesomeIcon icon={faInstagram} /></Item>
      <Item onClick={onClickFacebook}><FontAwesomeIcon icon={faFacebook} /></Item>
      <Item onClick={onClickLinkedin}><FontAwesomeIcon icon={faLinkedin} /></Item>
    </Container>
  )
}
export default SocialNetworks
