import { useInjectSaga } from 'Redux/Utils/injectSaga'
import { useInjectReducer } from 'Redux/Utils/injectReducer'

import key from './types'
import saga from './saga'
import reducer from './reducer'

export const useSaga = () => useInjectSaga({ key, saga })

export const useReducer = () => useInjectReducer({ key, reducer })

export default () => {
  useSaga()
  useReducer()
}
