import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { spacing, colors } from 'Global/theme'
export const Container = styled.div`
  text-align: center !important;
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return 'column'
        case BREAKPOINTS.TABLET: return 'row'
        default: return 'row'
      }
    }};

`
export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    margin-bottom: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return spacing.one
        case BREAKPOINTS.TABLET: return spacing.oneAndAHalf
        default: return spacing.two
      }
    }};
  }

`
export const FormContainer = styled.div`
  width: 100%;
  padding: 0 ${spacing.three};
  display: flex;
  flex-direction: column;
  align-items: center;

  > :last-child {
    margin-top: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return spacing.one
        case BREAKPOINTS.TABLET: return spacing.oneAndAHalf
        default: return spacing.two
      }
    }};
  }

`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  background-color: ${colors.background.gray};
  opacity: 0.5;
  border-radius: 20px;
`
