import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ItemList from './Components/ItemList'
import { RATES_FORM } from 'Global/paths'
import Carousel from 'Components/Carousel'
import homeImageB from 'Assets/header_2.jpg'

import GLOBAL_SELECTOR from 'Redux/Global/selectors'

import Page from 'Components/Page'

import Contact from 'Components/Contact'
import Advice from 'Components/Advice'
import Associated from 'Components/Associated'

import Coverage from './Components/Coverage'
import {
  Spacing,
  FixedImage,
  ImageContainer,
  ImageWrapper,
  Title,
  Subtitle,
  TextContainer,
  Container
} from './style'
import { useWindowDimensions } from 'Components/WindowDimensions'
import Info from 'Components/Header/Desktop/Components/Info'
import { BREAKPOINTS } from 'Global/constants'
import Breadcrumbs from 'Components/Breadcrumbs'
const Start = () => {
  const history = useHistory()
  const products = useSelector(GLOBAL_SELECTOR.PRODUCTS)
  const window = useWindowDimensions()
  const [windowWidth, setWidth] = useState(1920)
  useEffect(() => {
    setWidth(window)
  }, [window])
  const goToQuote = id => {
    history.push({
      pathname: RATES_FORM,
      search: `?productId=${id}`
    })
  }

  return (
    <Page>
      {(window < BREAKPOINTS.DESKTOP && window > BREAKPOINTS.TABLET) && <Info line={2} />}
      <Breadcrumbs action={goToQuote} />
      <Advice />
      <Associated />
      <Contact />
    </Page>
  )
}

export default Start
// <ImageWrapper width={windowWidth}>
// <Carousel action={goToQuote} />
// </ImageWrapper>
