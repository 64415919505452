import produce from 'immer'

import { PRODUCTS, PROVIDERS, MODAL } from './types'

export const initialState = {
  products: {
    loading: true,
    error: false,
    data: [],
    selected: null
  },
  providers: {
    loading: true,
    error: false,
    data: []
  },
  modal: {
    isShowing: false
  }
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const { products, providers, modal } = draft
    switch (type) {
      // PRODUCTS
      case PRODUCTS.LOAD:
        products.loading = true
        products.error = false
        break

      case PRODUCTS.SUCCESS:
        products.loading = false
        products.error = false
        products.data = payload
        products.selected = null
        break

      case PRODUCTS.ERROR:
        products.loading = false
        products.error = payload
        products.data = []
        products.selected = null
        break

      case PRODUCTS.CLEAR:
        products.data = []
        products.selected = null
        break

      case PRODUCTS.SELECT:
        products.selected = payload
        break

      // PROVIDERS
      case PROVIDERS.LOAD:
        providers.loading = true
        providers.error = false
        break

      case PROVIDERS.SUCCESS:
        providers.loading = false
        providers.error = false
        providers.data = payload
        break

      case PROVIDERS.ERROR:
        providers.loading = false
        providers.error = payload
        providers.data = []
        break

      case PROVIDERS.CLEAR:
        providers.data = []
        break

      // MODAL
      case MODAL.OPEN:
        modal.isShowing = true
        break

      case MODAL.CLOSE:
        modal.isShowing = false
        break

      case MODAL.TOGGLE:
        modal.isShowing = !modal.isShowing
        break
    }
  })

export default reducer
