export const BREAKPOINTS = {
  MOBILE: 576,
  TABLET: 875,
  DESKTOP: 992
}

export const PROVIDERS = {
  BSE: 2
}

export const BAISC_INPUT_TYPES = {
  TEXT: 'text',
  NAME: 'name',
  PASSWORD: 'password',
  EMAIL: 'email',
  TEL: 'tel',
  NUMBER: 'number',
  CI: 'ci',
  ALPHANUMERIC: 'alphanumeric',
  DATE: 'date',
  CELLPHONE: 'cellphone'
}

export const INPUT_TYPES = {
  ...BAISC_INPUT_TYPES,
  SELECT: 'select',
  ASYNC_SELECT: 'asyncSelect',
  BOOLEAN: 'boolean',
  TEXT_AREA: 'textArea'
}

export const DYNAMIC_FIELD_TYPES = {
  GROUP: 1,
  NUMERIC: 2,
  TEXT: 3,
  DATE: 4,
  BOOLEAN: 5,
  EMAIL: 7,
  SELECT: 10,
  ALPHANUMERIC: 12
}

export const DYNAMIC_FORM_SECTIONS = {
  COMMON_FIELDS: 'commonFields',
  AGENT_FIELDS: 'agentFields',
  SPECIFIC_FIELDS: 'specificFields'
}

export const DIR = {
  TOP: 'TOP',
  BOT: 'BOT',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

export const RATE_APP_JOBS_STATUS = {
  PENDING: 1,
  PROCESSED: 2,
  COMPLETED: 3,
  ERROR: 4
}

export const COVERAGE = {
  BASIC: { label: 'Cobertura Básica', internalName: 'basic' },
  MEDIUM: { label: 'Cobertura Media', internalName: 'medium' },
  TOTAL: { label: 'Cobertura Total', internalName: 'total' }
}

export const POLICY_STATUS = {
  REQUEST_IN_PROCESS: 2,
  PROCESSED_REQUEST: 3,
  CANCELED: 4,
  IN_PROCESS: 6,
  VALID: 7,
  VOID: 8,
  EXPIRED: 9,
  OBSOLETE: 10,
  WAITING: 11
}

export const MESSAGE_TYPE = {
  SUCCESS: 'Success',
  INFO: 'Info',
  WARNING: 'Warning',
  ERROR: 'Error'
}

export const DELAY = 2500
export const MAX_WAIT_TIME = 300000
