import { FORM, RATE } from './types'

export const getForm = payload => ({
  type: FORM.LOAD,
  payload
})

export const getFormSuccess = payload => ({
  type: FORM.LOAD_SUCCESS,
  payload
})

export const getFormError = payload => ({
  type: FORM.LOAD_ERROR,
  payload
})

export const submitRate = payload => ({
  type: FORM.SUBMIT,
  payload
})

export const toStart = payload => ({
  type: FORM.TO_START,
  payload
})

export const FORM_ACTIONS = {
  GET: getForm,
  GET_SUCCESS: getFormSuccess,
  GET_ERROR: getFormError,
  SUBMIT: submitRate,
  TO_START: toStart
}

export const getRate = () => ({
  type: RATE.GET
})

export const getRateSuccess = payload => ({
  type: RATE.SUCCESS,
  payload
})

export const getRateError = payload => ({
  type: RATE.ERROR,
  payload
})

export const RATE_ACTIONS = {
  GET: getRate,
  SUCCESS: getRateSuccess,
  ERROR: getRateError
}
