export const STEPS = {
  CI: 'ci',
  INFO: 'info',
  ADDRESS: 'address',
  CHECK: 'check'
}

export const PLACES = {
  COUNTRY: 'coutry',
  DEPARTMENT: 'department',
  CITY: 'city',
  STREET: 'street'
}

export const NEXT_PLACE = place => {
  switch (place) {
    case PLACES.COUNTRY: return PLACES.DEPARTMENT
    case PLACES.DEPARTMENT: return PLACES.CITY
    case PLACES.CITY: return PLACES.STREET
    case PLACES.STREET: return PLACES.COUNTRY
    default: return null
  }
}
