import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'
import { spacing, colors } from 'Global/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ device }) => device === BREAKPOINTS.DESKTOP ? 'row' : 'column'};

  width: 100%;
  margin-top: ${spacing.seven};
  padding: 0 ${({ device }) => {
    switch (device) {
        case BREAKPOINTS.MOBILE: return '0'
        case BREAKPOINTS.TABLET: return spacing.one
        default: return spacing.two
      }
  }}

`

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  margin-bottom: ${spacing.four};


  &.info {
    justify-content: flex-start;
    align-items: flex-start;

    > :not(:first-child) {
      margin-top: ${spacing.three};
    }
  }

  &.status {
    justify-content: center;
    align-items: center;
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 'row' : 'column'};
  align-items: ${({ device }) => device !== BREAKPOINTS.MOBILE ? 'flex-end' : 'flex-start'};
  justify-content: flex-start;

  &.certs {
    align-items: flex-start;
  }

  p {
    margin: 0;
    font-size: 15pt;
  }

  > :first-child {
    font-size: 18pt;
    margin-right: ${spacing.fourAndAHalf};
    font-weight: 600;
  }

  .loading {
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;

    > img {
      position: absolute;
    }
  }

  .download {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-top: ${spacing.one};

    > p {
      &:not(:first-child) {
        margin: ${spacing.two} 0 0 0;
      }

      width: max-content;
      cursor: pointer;
      border-bottom: 1px solid ${colors.primary.dark};

      &:hover {
        color: ${colors.primary.regular};
        border-bottom: 1px solid ${colors.primary.regular};
      }


    }
  }
`
