import styled from 'styled-components'
import { colors, spacing } from 'Global/theme'

export const Bar = styled.div`
  height: ${spacing.nineAndAHalf};
  padding: 0 ${spacing.ten} 0 ${spacing.five};
  padding-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;

  background-color: ${colors.primary.dark};

  p {
      margin: 0;
  }
`

export const Zone = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  color: ${colors.primary.regular};

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  > * {
    height: ${spacing.three};
    width: auto
  }

  &.social {
    > :not(:last-child) {
      margin-right: ${spacing.twoAndAHalf} !important;
    }
  }

  &.whatsapp {
    > a {
      > :first-child {
        margin-right: ${spacing.twoAndAHalf} !important;
      }
    }
  }

`

export const Back = styled.div`
  height: ${spacing.five};
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;
  z-index: -9;

  background-color: ${colors.primary.dark};
`

export const Image = styled.img`
  height: 80%;
  color: ${colors.secondary.regular};
  cursor: pointer;
`
