import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectForm = state => get(state, key, initialState)

const selectcFormLoading = createSelector(
  selectForm, state => get(state, 'loading', true)
)

const selectFormError = createSelector(
  selectForm, state => get(state, 'error', false)
)

const selectFormData = createSelector(
  selectForm, state => get(state, 'forms', {})
)

const selectActiveSections = createSelector(
  selectForm, state => get(state, 'forms.actives', [])
)

const selectPayments = createSelector(
  selectForm, state => get(state, 'payments', [])
)

const selectFormId = createSelector(
  selectForm, state => get(state, 'formId', -1)
)

const selectRate = createSelector(
  selectForm, state => get(state, 'selectedRate', undefined)
)

const selectDone = createSelector(
  selectForm, state => get(state, 'done', false)
)

const selectStartStep = createSelector(
  selectForm, state => get(state, 'startStep', undefined)
)

export default {
  LOADING: selectcFormLoading,
  ERROR: selectFormError,
  DATA: selectFormData,
  ACTIVE_SECTIONS: selectActiveSections,
  PAYMENTS: selectPayments,
  FORM_ID: selectFormId,
  RATE: selectRate,
  DONE: selectDone,
  START_STEP: selectStartStep
}
