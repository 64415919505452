export const key = 'messages'

export const MESSAGES = {
  CREATE: 'MESSAGES/CREATE',
  ADD: 'MESSAGES/ADD',
  SHOW: 'MESSAGES/SHOW',
  HIDE: 'MESSAGES/HIDE',
  REMOVE: 'MESSAGE/REMOVE',
  CLEAR: 'MESSAGES/CLREAR',
  ERROR: 'MESSAGES/ERROR',
  REMOVE_BY_USER: 'MESSAGES/REMOVE_BY_USER'
}

export default key
