import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Wrapper = styled.div`
  height: ${({ type }) => type === 'textarea' ? '196px' : spacing.sevenAndAHalf};
  width: 100%;

  margin: ${spacing.one} 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  position: relative;
`

export const StyledLabel = styled.label`
  color: ${({ valid }) => valid ? colors.primary.dark : colors.danger.regular} ;

  z-index: 1;
  pointer-events: none;

  opacity: ${({ disabled }) => disabled ? '0.5' : '1'};

  font-size: 13pt;
`

export const StyledInput = styled.input`
  width: 100%;
  height: ${spacing.four};

  border: none;
  border-radius: 0px;
  border-bottom: solid thin ${({ valid }) => valid ? colors.grays[50] : colors.danger.regular};

  color: ${colors.primary.dark} !important;
  ${family.openSans};
  ${weight.bold};
  font-size: 12pt;

  appearance: none;
  -webkit-appearance: none;

  :invalid {
    box-shadow: none;
    border: none;
    border-bottom: solid thin ${colors.danger.regular};
  }

  &:hover, &:focus {
    outline: none;
  }
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 168px;
  resize: none;

  border: none;
  border-radius: 0px;
  border-bottom: solid thin ${({ valid }) => valid ? colors.grays[50] : colors.danger.regular};

  font-family: 'Open Sans', sans-serif;
  font-size: ${spacing.two};
  color: ${colors.primary.dark};

  appearance: none;
  -webkit-appearance: none;

  &:hover, &:focus {
    outline: none;
  }
`
