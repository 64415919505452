import {
  GENERAL,
  FIELDS,
  ADDRESS,
  FLOW
} from './types'

/**
 *
 * GENERAL ACTIONS
 */
export const setProviderId = payload => ({
  type: GENERAL.SET_PROVIDER,
  payload
})

export const GENERAL_ACTIONS = {
  SET_PROVIDERID: setProviderId
}

/**
 *
 * FIELD ACTIONS
 */
export const changeField = payload => ({
  type: FIELDS.CHANGE,
  payload
})

export const clearFields = () => ({
  type: FIELDS.CLEAR
})

export const FIELD_ACTIONS = {
  CHANGE: changeField,
  CLEAR: clearFields
}

/**
 *
 * SELECTOR ACTIONS
 */
export const getAddressOptions = payload => ({
  type: ADDRESS.GET,
  payload
})

export const getAddressOptionsSuccess = payload => ({
  type: ADDRESS.SUCCESS,
  payload
})

export const getAddressOptionsError = payload => ({
  type: ADDRESS.ERROR,
  payload
})

export const selectAddressValue = payload => ({
  type: ADDRESS.SELECT,
  payload
})

export const selectStreetValue = payload => ({
  type: ADDRESS.STREET_SELECT,
  payload
})

export const clearAddres = payload => ({
  type: ADDRESS.CLEAR,
  payload
})

export const ADDRESS_ACTIONS = {
  GET: getAddressOptions,
  SUCCESS: getAddressOptionsSuccess,
  ERROR: getAddressOptionsError,
  SELECT: selectAddressValue,
  STREET_SELECT: selectStreetValue,
  CLEAR: clearAddres
}

/**
 *
 * FLOW ACTIONS
 */
export const nextStep = payload => ({
  type: FLOW.NEXT,
  payload
})

export const setStep = payload => ({
  type: FLOW.STEP,
  payload
})

export const setUserId = payload => ({
  type: FLOW.USER,
  payload
})

export const setAddress = payload => ({
  type: FLOW.ADDRESS,
  payload
})

export const setComplete = payload => ({
  type: FLOW.COMPLETE
})

export const flowError = payload => ({
  type: FLOW.ERROR,
  payload
})

export const cleraAll = () => ({
  type: FLOW.CLEAR
})

export const setLoading = payload => ({
  type: FLOW.LOADING,
  payload
})

export const FLOW_ACTIONS = {
  NEXT: nextStep,
  STEP: setStep,
  USER: setUserId,
  ADDRESS: setAddress,
  COMPLETE: setComplete,
  ERROR: flowError,
  CLEAR: cleraAll,
  LOADING: setLoading
}
