import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { Card } from './style'

const ErrorCard = ({ device }) => (
  <Card device={device}>
    <FontAwesomeIcon icon={faExclamationTriangle} size='4x' />
    <p className='title'>¡Lo sentimos!</p>
    <p className='subtitle'>Ha ocurrido un error durante la emisión de la póliza.</p>
    <p className='text'>Por favor contáctese con Seguros Online utilizando el código de transacción como referencia.</p>
  </Card>
)

ErrorCard.propTypes = {
  device: PropTypes.number
}

export default ErrorCard
