import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


import { BREAKPOINTS } from 'Global/constants'
import { useWindowDimensions } from 'Components/WindowDimensions'

import Page from 'Components/Page'

import Advice from 'Components/Advice'
import Contact from 'Components/Contact'
import HeroImage from 'Components/HeroImage'
import Associated from 'Components/Associated'
import Information from 'Components/Information'

import data from './data'

import { Block, Header, Title, Option, Info, Separation, Description, BlockContainer } from './styles'

const Insurances = () => {
  const device = useWindowDimensions()

  return (
    <Page>

      <Information
        title='SEGUROS'
        info='Encontrá el seguro que más se adapta a lo que necesitás.'
      />

      {data.map((insurance, i) => (
        <BlockContainer>
          <div>
          <Block key={i}>
            <Separation>
              <img src={insurance.icon} />
            </Separation>
            <Separation>
              <Header>
                <Title>{insurance.name}</Title>
                {insurance.options.map((o, n) => (
                  <Option key={`option_${n}`}>
                    <FontAwesomeIcon icon={faCheck} />
                    <p>{o}</p>
                  </Option>
                ))}
              </Header>
            </Separation>
          </Block>
          </div>
          <Description>

          {insurance.info.map((info, n) => <Info key={`info_${n}`}>{info}</Info>)}
          </Description>
      
        </BlockContainer>

      ))}

      <Advice />
      <Associated />
      <Contact />

    </Page>
  )
}

export default Insurances
