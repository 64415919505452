import styled from 'styled-components'

import { BREAKPOINTS } from 'Global/constants'

import { colors, spacing } from 'Global/theme'
import { family, weight } from 'Global/font'

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ device }) => device === BREAKPOINTS.DESKTOP ? 'row' : 'column'};
  justify-content: center;
  align-items: center;

  color: ${({ color }) => {
    switch (color) {
      case 'secondary': return colors.secondary.regular
      default: return colors.primary.dark
    }
  }};

  * > p {
    ${family.barlow};
    text-align: center;
    margin: 0;
  }

  * > p.title {
    ${weight.bold};
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? '23pt' : '37pt'};
  }

  * > p.subtitle {
    font-size: ${({ device }) => device === BREAKPOINTS.MOBILE ? '13pt' : '15pt'};

  }
`

export const Primary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const MyTitle = styled.p`
  ${family.barlow};
  ${weight.bold};
  font-size: 37pt;
  text-align: center;
  margin: 0;
`

export const MySubtitle = styled.p`
  ${family.barlow};
  font-size: 15pt;
  text-align: center;
  margin: 0;
`

export const Separator = styled.div`
  width: ${spacing.one};
  min-width: ${spacing.one};
  height: ${spacing.six};
  margin: 0 ${spacing.oneAndAHalf};
  background-color: ${({ color }) => {
    switch (color) {
      case 'secondary': return colors.secondary.regular
      default: return colors.primary.dark
    }
  }};
`

export const Line = styled.div`
  width: 227px;
  height: 3px;
  background-color: ${({ color }) => {
    switch (color) {
      case 'secondary': return colors.secondary.regular
      default: return colors.primary.dark
    }
  }};
  margin: ${spacing.two} 0
`
