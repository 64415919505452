/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import history from 'Redux/Utils/history'
import globalReducer from './Global/reducer'
import messages from 'Messages/Redux/reducer'
// import languageProviderReducer from 'containers/LanguageProvider/reducer'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer (injectedReducers = {}) {
  const rootReducer = combineReducers({
    // language: languageProviderReducer,
    global: globalReducer,
    router: connectRouter(history),
    messages,
    ...injectedReducers
  })

  return rootReducer
}
