import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'

import axios from 'Axios'

import GLOBAL_SELECT from 'Redux/Global/selectors'
import { INPUT_TYPES, PROVIDERS } from 'Global/constants'
import { message } from 'Messages/factory'
import { checkField } from 'Utils/checks'

import { Input, Select } from 'Components/Input'
import Button from 'Components/Button'

import SELECT from './Redux/selectors'
import { FIELD_ACTIONS, ADDRESS_ACTIONS } from './Redux/actions'

import { isMontevideo } from './utils'
import { PLACES } from './constants'

const Addresses = ({ next }) => {
  const dispatch = useDispatch()

  const providerId = useSelector(SELECT.PROVIDER_ID)
  const country = useSelector(SELECT.COUNTRY)
  const department = useSelector(SELECT.DEPARTMENT)
  const city = useSelector(SELECT.CITY)
  const street = useSelector(SELECT.STREET)
  const number = useSelector(SELECT.NUMBER)
  const aptNumber = useSelector(SELECT.APT_NUMBER)

  const hasAddressHandler = useSelector(GLOBAL_SELECT.PROVIDER_ADDRESS_HANDLER(providerId))

  const isBSE = providerId === PROVIDERS.BSE

  useEffect(() => {
    dispatch(ADDRESS_ACTIONS.GET({ place: PLACES.COUNTRY }))
  }, [])

  const validate = () => {
    const err = []
    let params

    params = { type: PLACES.COUNTRY, value: country.value, required: true }
    if (!checkField(params)) err.push('País')

    params = { type: PLACES.DEPARTMENT, value: department.value, required: true }
    if (!checkField(params)) err.push('Departamento')

    params = { type: PLACES.CITY, value: city.value, required: true }
    if (!checkField(params)) err.push('Barrio')

    params = { type: PLACES.STREET, value: street.main, required: true }
    if (!checkField(params)) err.push('Calle')

    if (!isBSE) {
      params = { type: PLACES.STREET, value: street.corner, required: true }
      if (!checkField(params)) err.push('Esquina')
    }

    params = { type: INPUT_TYPES.TEXT, value: number, required: true }
    if (!checkField(params)) err.push('Número de calle')

    params = { type: INPUT_TYPES.TEXT, value: aptNumber, required: false }
    if (!checkField(params)) err.push('Número de apartamento (si corresponde)')

    const text = err.join(', ')

    if (!text) return true

    message.warning({
      message: `Los siguientes campos son inválidos: ${text}`,
      time: 8000
    })

    return false
  }

  const handleNext = () => {
    if (validate()) next()
  }

  const changeNumber = ({ value, name }) => {
    dispatch(FIELD_ACTIONS.CHANGE({ value, name }))
  }

  const handleChange = ({ name, value }) => {
    dispatch(ADDRESS_ACTIONS.SELECT({ place: name, value }))
  }

  const handleStreetSelect = (name, { value }) => {
    dispatch(ADDRESS_ACTIONS.STREET_SELECT({ name, value }))
  }

  /**
   *
   * Needed for the async loading of streets.
   * Didn't found a way of making this through Redux or Saga
   */
  const debounced = debounce((value, callback) => {
    const empty = []

    if (!value) {
      callback(empty)
      return
    }

    const departmentId = department.value.id
    const cityId = city.value.id

    const url = hasAddressHandler
      ? `/ws/all_provider_city_streets/${providerId}/${departmentId}/${cityId}/${value}/`
      : `/ws/city_streets/${departmentId}/${cityId}/${value}/`

    const results = axios.get(`/options?opt_url=${url}`)

    results
      .then(res => {
        const options = res.map(r => ({
          ...r,
          label: r.description,
          value: r.description
        }))
        callback(options)
      })
      .catch(err => {
        dispatch(ADDRESS_ACTIONS.ERROR(err))
        callback(empty)
      })
  }, 300)

  const cityLabel = `Ciudad${isBSE && !isMontevideo(department.value) ? '/Localidad' : ''}`

  return (
    <>
      <Select
        name={PLACES.COUNTRY}
        label='País'
        value={country.value}
        options={country.options}
        isLoading={country.loading}
        disabled={!country.options}
        onChange={handleChange}
        required
        formValid
      />

      <Select
        name={PLACES.DEPARTMENT}
        label='Departamento'
        value={department.value}
        options={department.options}
        isLoading={department.loading}
        disabled={!department.options}
        onChange={handleChange}
        required
        formValid
      />

      <Select
        name={PLACES.CITY}
        label={cityLabel}
        value={city.value}
        options={city.options}
        isLoading={city.loading}
        disabled={!city.options}
        onChange={handleChange}
        required
        formValid
      />

      {!isBSE || isMontevideo(department.value) ? (
        <Select
          name={PLACES.STREET}
          label='Calle'
          value={street.main}
          isLoading={street.loading}
          disabled={!city.value}
          asyncLoad={debounced}
          onChange={v => handleStreetSelect('main', v)}
          required
          formValid
        />
      ) : (
        <Input
          type={INPUT_TYPES.TEXT}
          name={PLACES.STREET}
          label='Calle'
          value={street.mail}
          required
          onChange={v => handleStreetSelect('main', v)}
        />
      )}

      {!isBSE && (
        <Select
          name={PLACES.STREET}
          label='Esquina'
          value={street.corner}
          isLoading={street.loading}
          disabled={!city.value}
          asyncLoad={debounced}
          onChange={v => handleStreetSelect('corner', v)}
          required
          formValid
        />
      )}

      <Input
        type={isBSE ? INPUT_TYPES.NUMBER : INPUT_TYPES.TEXT}
        name='number'
        label='Número de calle'
        value={number}
        required
        onChange={changeNumber}
      />

      <Input
        type={INPUT_TYPES.TEXT}
        name='aptNumber'
        label='Número de apartamento (si corresponde)'
        value={aptNumber}
        onChange={changeNumber}
      />

      <Button
        size='large'
        onClick={handleNext}
      >
        Terminar
      </Button>
    </>
  )
}

Addresses.propTypes = {
  next: PropTypes.func
}

export default Addresses
