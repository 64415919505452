import styled, { css } from 'styled-components'

import { coverBackground } from 'Utils/style'
import { colors, spacing } from 'Global/theme'
import { BREAKPOINTS } from 'Global/constants'

const Desktop = css`
  padding: ${spacing.eight} ${spacing.nineAndAHalf};

  flex-wrap: nowrap;
  justify-content: space-evenly;

  .card {
    max-width: 440px;

    > :last-child {
      margin-left: auto;
    }
  }
`

const Tablet = css`
  padding: ${spacing.six} ${spacing.three};

  .card {
    width: 100%;
  }

  > :last-child {
    margin-top: ${spacing.six};
  }
`

const Mobile = css`
  padding: ${spacing.four} 0;

  .card {
    width: 100%;
  }

  > :last-child {
    margin-top: ${spacing.four};
  }
`

export const Container = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  background-color: ${colors.primary.dark};
  ${coverBackground(colors.primary.dark)};

  > .card {
    width: 440px;
    height: 544px;

    background-color: ${colors.background.white};

     &--contact {
      padding: ${spacing.one} ${spacing.fourAndAHalf};
    }
  }

  ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return Mobile
      case BREAKPOINTS.TABLET: return Tablet
      default: return Desktop
    }
  }};
`
