import produce from 'immer'

import { FORM, RATE } from './types'

export const initialState = {
  loading: true,
  done: false,
  error: '',
  forms: [],
  payments: [],
  formId: -1,
  selectedRate: undefined,
  startStep: undefined
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      // FORM
      case FORM.LOAD:
        draft.loading = true
        draft.error = ''
        draft.forms = []
        draft.done = false
        break

      case FORM.LOAD_SUCCESS:
        draft.loading = false
        draft.error = ''
        draft.forms = payload.form
        draft.payments = payload.payments
        draft.formId = payload.formId
        break

      case FORM.LOAD_ERROR:
        draft.loading = false
        draft.error = payload
        draft.payments = []
        draft.forms = []
        draft.formId = -1
        draft.done = false
        break

      case FORM.SUBMIT:
        draft.done = true
        break

      case FORM.TO_START:
        draft.startStep = 0
        draft.done = false
        break

      // RATE
      case RATE.SUCCESS:
        draft.selectedRate = payload
        break

      case RATE.ERROR:
        draft.selectedRate = undefined
        draft.error = payload
        draft.done = false
        break
    }
  })

export default reducer
