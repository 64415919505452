import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { WHATSAPP } from 'Global/data'

import {
  SOA,
  HOME,
  HIRE,
  CANCEL,
  CONTACT,
  ABOUT_US,
  GLOSSARY,
  FAQ,
  INSURANCES
} from 'Global/paths'

import {
  Wrapper,
  Section,
  WhatsApp,
  PrimaryLink,
  SecondaryLink
} from './style'

const Menu = ({ handleClose }) => (
  <Wrapper>
    <Section>
      <PrimaryLink to={HOME} onClick={handleClose}>
        INICIO
      </PrimaryLink>

      <PrimaryLink to={INSURANCES} onClick={handleClose}>
        SEGUROS
      </PrimaryLink>

      <PrimaryLink to={ABOUT_US} onClick={handleClose}>
        NOSOTROS
      </PrimaryLink>

      <PrimaryLink to={FAQ} onClick={handleClose}>
        PREGUNTAS
      </PrimaryLink>

      <PrimaryLink to={CONTACT} onClick={handleClose}>
        CONTACTO
      </PrimaryLink>

    </Section>

    <WhatsApp href={WHATSAPP.LINK} target='_blank' rel='noopener noreferrer'>
      <FontAwesomeIcon icon={faWhatsapp} size='2x' />
      <span>{WHATSAPP.NUMBER}</span>
    </WhatsApp>
  </Wrapper>
)

Menu.propTypes = {
  handleClose: PropTypes.func
}

export default Menu
