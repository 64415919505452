import styled from 'styled-components'
import { colors } from 'Global/theme'
export const Container = styled.div`
  width: 150px;
  height: 100px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`
export const Title = styled.div`
  width: 80%;
  align-self: center;
  font-weight: bold;
  font-size: 30px;
  color: white;
  padding: 0;
  margin: 0;
  background-color: ${colors.primary.regular};
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background-color: ${colors.primary.light};
  }
`

export const Subtitle = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  text-align: center;
  font-weight: bold;
  font-size: 21px;
  border-radius: 10px;
  margin-top: 10px;
  justify-content: center;
  p {
    text-align: center;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    padding-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
  }

  color: ${colors.primary.regular}
`
