import { RATES, PROVIDER_RATE, CATEGORY } from './types'

/**
 *  CATEGORY
 */
export const getCoverageCategory = () => ({
  type: CATEGORY.LOAD
})

export const getCoverageCategorySuccess = payload => ({
  type: CATEGORY.SUCCESS,
  payload
})

export const getCoverageCategoryError = payload => ({
  type: CATEGORY.ERROR,
  payload
})

export const CATEGORY_ACTIONS = {
  LOAD: getCoverageCategory,
  SUCCESS: getCoverageCategorySuccess,
  ERROR: getCoverageCategoryError
}

/**
 * INSURANCE RATES
 */
export const getRates = payload => ({
  type: RATES.LOAD,
  payload
})

export const getRatesSuccess = payload => ({
  type: RATES.SUCCESS,
  payload
})

export const getRatesError = payload => ({
  type: RATES.ERROR,
  payload
})

export const selectRate = payload => ({
  type: RATES.SELECT,
  payload
})

export const createRate = payload => ({
  type: RATES.CREATE,
  payload
})

export const clearRates = () => ({
  type: RATES.CLEAR
})

export const waitForTakeOn = payload => ({
  type: RATES.WAIT,
  payload
})

export const RATE_ACTIONS = {
  LOAD: getRates,
  SUCCESS: getRatesSuccess,
  ERROR: getRatesError,
  SELECT: selectRate,
  CREATE: createRate,
  CLEAR: clearRates,
  WAIT: waitForTakeOn
}
/**
 *
 * PROVIDER RATES
 */

export const getProviderQuotes = () => ({
  type: PROVIDER_RATE.LOAD
})

export const getProviderQuotesSuccess = payload => ({
  type: PROVIDER_RATE.SUCCESS,
  payload
})

export const getProviderQuotesError = payload => ({
  type: PROVIDER_RATE.ERROR,
  payload
})

export const stopGettingRates = () => ({
  type: PROVIDER_RATE.STOP
})

export const noRatesProvider = payload => ({
  type: PROVIDER_RATE.NO_RATES,
  payload
})

export const removeNoRatesProvider = payload => ({
  type: PROVIDER_RATE.NO_RATES,
  payload
})

export const PROVIDER_RATE_ACTIONS = {
  LOAD: getProviderQuotes,
  SUCCESS: getProviderQuotesSuccess,
  ERROR: getProviderQuotesError,
  STOP: stopGettingRates,
  NO_RATES: noRatesProvider,
  REMOVE_NO_RATES: removeNoRatesProvider
}
