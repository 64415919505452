import styled from 'styled-components'
import { Link as RLink } from 'react-router-dom'

import { BREAKPOINTS } from 'Global/constants'

import { coverBackground } from 'Utils/style'
import { colors, spacing } from 'Global/theme'
import { family } from 'Global/font'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  padding: ${({ device }) => {
    switch (device) {
      case BREAKPOINTS.MOBILE: return `${spacing.one} ${spacing.two}`
      case BREAKPOINTS.TABLET: return `${spacing.two} ${spacing.four}`
      default: return `${spacing.three} ${spacing.six}`
    }
  }};

  background-color: ${colors.primary.regular};
  ${coverBackground(colors.primary.regular)};

  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;

  > :not(:first-child) {
    margin: ${({ device }) => {
      switch (device) {
        case BREAKPOINTS.MOBILE: return `${spacing.three} 0 0`
        default: return 0
      }
    }};
  }
`

export const Title = styled.p`
  ${family.barlow};
  font-weight: bold;
  font-size: 18pt;
  margin: 0;
`

export const Column = styled.div`
  height: 100%;
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: ${colors.secondary.regular};
  font-size: 10pt;
`

export const LinkContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: ${spacing.oneAndAHalf};
`

export const Link = styled(RLink)`
  color: ${colors.secondary.regular};
  text-decoration: underline;
  font-size: 10pt;
`
