import styled from 'styled-components'

import { colors, spacing } from 'Global/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Dot = styled.div`
  width: 10px;
  height: 10px;

  border-radius: 50%;

  margin: 0 ${spacing.two};

  background-color: ${({ selected }) => selected ? colors.primary.dark : colors.primary.dark70};
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
`
